.mockup-icon {
  align-self: stretch;
  position: relative;
  overflow: hidden;
  max-width: 80vw;
  margin: 0 auto;

  height: auto;
  max-height: 500px;
  flex-shrink: 0;
  object-fit: cover;
  margin: 0 auto;

  /* Add transition */
  transition: all 0.3s ease-in;
}
