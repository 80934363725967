.drag-drop-edit {
  padding: 18px;
  margin: 15px;
  background-color: white;

  min-width: 200px;
  padding: 20px;
  max-width: 300px;
  min-height: 150px;
}

.dnd-edit-definition-container {
  min-width: 200px;
  padding: 20px;
}

.ql-editor > p > img {
  max-width: 50px;
}
