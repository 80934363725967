.buy-button > button {
  margin: 0px;
  border-radius: 40px;
  background-color: #3e3e3e;
  color: white;
  border: none;
}

.buy-button > button:hover {
  /* background-color: black; */
}

.buying-button > button {
  margin: 0px;
  border-radius: 40px;
  font-size: 12px;
  font-weight: bold;
  background-color: #86f5a0;
  min-height: 100%;
  min-width: 72px;
}

.buying-button {
  min-height: 32px;
  z-index: 10;
}

.buying-button > button > i.fa {
  /* display: inline-block; */
  border-radius: none;
  box-shadow: none;
  padding: 0 0;
  margin: 0;
}

.trade-button-pulse {
  animation-name: buy-button-pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes buy-button-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
