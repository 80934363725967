.levelnumber {
  flex: 1;
  position: relative;
  font-weight: 500;
}
.levelnumbertag {
  position: relative;
  border-radius: 100px;
  background-color: rgba(217, 70, 69, 0.1);
  display: flex;
  flex-direction: row;
  padding: 4px 12px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  max-width: 160px;
  text-align: left;
  font-size: 16px;
  color: #d94645;
  font-family: Poppins;
  align-self: flex-start;
  margin-bottom: 10px;
}
