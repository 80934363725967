.studentname__container {
  height: 64px;
  width: 300px;
  padding: 4px;
  padding-left: 16px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.messagepreview__datesent {
  font-size: 13px;
  opacity: 0.6;
}

.studentname__title {
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 6px;
}

.chatloadingorerror__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 20px;
}

.studentname__container__selected {
  height: 64px;
  width: 320px;
  padding: 4px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  background-color: #f6d0cf;
}

.studentname__container:hover {
  background-color: #f6d0cf;
  cursor: pointer;
}

.classroomchatscol__container {
  border: 1px solid #ffebeb;
  display: flex;
  flex-direction: column;
  width: 320px;
}

.classroomchatsselected__title {
  font-size: 16px;
  font-weight: bold;
  padding: 16px;
  border-bottom: 1px solid #ffebeb;
  text-align: center;
}

.classroomchat__students {
  height: 100%;
  overflow-y: auto;
  max-height: 95vh;
}

.message__preview {
  font-size: 10px;
  max-width: 200px;
}

.studentname__container__selected,
.studentname__container {
  height: 64px;
  width: 300px;
  padding: 4px;
  padding-left: 16px;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* justify-content: center; */
  align-items: flex-start;
}
