.you-lost-you {
  align-self: stretch;
  position: relative;
  line-height: 18px;
}
.budgetgameresult-notification {
  align-self: stretch;
  border-radius: 8px;
  background-color: rgba(242, 82, 82, 0.8);
  border: 1px solid rgba(242, 82, 82, 0.1);
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  color: white;
  font-family: Poppins;
  font-weight: bold;
  font-size: 16px;
  
}
