.game-situation {
  position: relative;
  height: 100px;
  margin-bottom: 30px;
  animation-name: executeTrade;
  animation-duration: 1s;
  color: white;
}

@keyframes executeTrade {
  0% {
    top: 200px;
  }
  100% {
    top: 0px;
  }
}

.choice-container2 {
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: left;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: #3e3e3e;
  color: white;
  box-shadow: 0 0 6px 0px #716b6b;
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  height: 100px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.choice-container2 > h4 {
  color: white;
  padding: 10px;
  padding-bottom: 0px;
}

.choice-container2:hover {
  background-color: black;
  box-shadow: 1px 1px 4px 0px lightgrey inset;
  color: white;
}

.game-situation-leave {
  animation-name: leave;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes leave {
  0% {
    margin-top: 0px;
  }
  100% {
    margin-top: -400px;
  }
}
