.shared-input {
  width: 100%;
  height: 52px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 16px;
  margin: 4px 0px;
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
}
