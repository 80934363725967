.you-lost {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.vector-icon {
  position: relative;
  width: 14.77px;
  height: 14.77px;
  object-fit: cover;
}

.bottom-unlock-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.budget-happy-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

@media screen and (max-width: 720px) {
  .budget-happy-balance {
    flex-direction: column;
    /* align-items: flex-start; */
    gap: 16px; /* Add some space between stacked items */
  }
}

.title {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.recommendation-title {
  align-self: stretch;
  position: relative;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}
.try-spending-on {
  align-self: stretch;
  position: relative;
  line-height: 22px;
}
.container {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
}
.resultsoverview {
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  font-size: 16px;
  color: #222224;
  font-family: Poppins;
  padding: 40px 120px 0px;
}

@media screen and (max-width: 720px) {
  .resultsoverview {
    padding: 10px;
  }
}
