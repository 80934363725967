.learn-more-button {
  width: 130px;
  height: 33px;
  border: 2px solid #d94645;
  border-radius: 5px;
  background-color: white;
  color: #d94645;
  font-family: Poppins;
  font-weight: 500;
  margin-top: 24px;

  font-size: 12px;
  border: 2px solid #d94645;
  border-radius: 5px;
  opacity: 1;
}

.learn-more-button:hover {
  background-color: #d94645;
  color: white;
}

.learnmore__text {
  padding-right: 8px;
}
