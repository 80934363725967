.decision-tree-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation-name: decisiontree-appear;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  padding-bottom: 30px;
  font-family: Poppins;
}

@keyframes decisiontree-appear {
  0% {
    margin-bottom: -30px;
    opacity: 0;
  }
  100% {
    margin-bottom: -30px;
    opacity: 1;
  }
}

.game-variables {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* padding-left: 40px;
  padding-right: 40px; */
  font-family: Poppins;
  font-weight: 400;
  margin-bottom: 24px;
}

.game-variables > p {
  /* font-size: 1.5em; */
  padding-left: 25px;
  padding-right: 25px;
  background-color: #ffbb25;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 4px;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
}

.situation-description {
  /* padding: 40px; */
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
  line-height: 28px;
  font-family: Poppins;
  font-weight: 400;
  margin: 10px;
  max-width: 700px;
  align-self: center;
  border-radius: 15px;
  min-height: 200px;
  /* border: 1px solid rgb(233, 230, 230); */
}

.carousel-decision-tree > .carousel .slide {
  background-color: #625772;
  padding-top: 30px;
}

.decision-tree-action {
  border: none;
  background: none;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}

.decision-tree-action-button {
  margin-top: 10px;
  padding: 10px 20px 10px 20px;
  border-radius: 15px;
  border: none;
  background-color: #f05555;
  color: white;
  font-weight: bold;
  box-shadow: 0px 0px 6px 3px rgb(70, 66, 66);
  margin-bottom: 30px;
}

.decision-tree-action-button:disabled {
  background-color: #9b9b98;
}

.situations__container {
  display: flex;
  flex-direction: row;
  max-width: 80vw;
}

.situation__container {
  width: 230px;
  min-height: 180px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
}

.situation__container:hover {
  background-color: rgb(232, 232, 232);
  cursor: pointer;
}

.resetgame__button {
  width: 200px;
  height: 52px;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 16px;
  background-color: white;
  border: 2px solid #d94645;
  color: #d94645;
  border-radius: 40px;
}

.descisiontreecost__text {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
}
