.chatopen__modal {
  position: fixed;
  width: 60vw;
  height: 80vh;
  display: flex;
  background-color: white;
  z-index: 100;
  margin: 0 auto;
  top: 50px;
  left: 100px;
  box-shadow: 0px 0px 6px 3px rgb(75 75 75 / 20%);
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  border-radius: 10px;
  font-family: Poppins;
}

.sendingpush__loader {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatopen__containermain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.groupchatuser__sender {
  font-weight: 600;
  font-size: 12px;
  padding-left: 5px;
}

.chatopen__headercontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
}

.chatclose__button {
  font-weight: bold;
}

.chatclose__button:hover {
  cursor: pointer;
}

.chatmessage__message {
  text-align: right;
  width: 100%;
  background-color: #d94645;
  max-width: 250px;
  color: white;
  font-weight: 400;
  border-radius: 15px;
  border-bottom-right-radius: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.chatmessage_sender {
  text-align: left;
  width: 100%;
  background-color: #ffebeb;
  max-width: 250px;
  color: #0f1828;
  font-weight: 400;
  border-radius: 15px;
  border-bottom-left-radius: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.chatmessage_wrapper {
  width: 100%;
}

.chatmessage__unread {
  font-weight: 700;
  color: black;
}

.chatmessage__wrapperuser {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 16px;
  margin-left: 16px;
}

.chatmessage_wrappersender {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 16px;
  margin-left: 16px;
}

.unread__ball {
  width: 15px;
  height: 15px;
  background-color: #d94645;
  border-radius: 50%;
}

.chatopen__chatcontainer {
  height: 100%;
  border: 1px solid lightgray;
  flex-grow: 1;
  overflow-y: auto;
  overflow: auto;
  padding-top: 12px;
  padding-bottom: 12px;
}

.chatopen__username {
  font-family: Poppins;
  font-weight: bold;
  font-size: 16px;
}

.chatopen__inputcontainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-left: 10px;

  padding-top: 20px;
  padding-right: 10px;
}

.chatopen__inputcontainer > input {
  width: 100%;
  border: none;
  font-weight: 400;
  font-family: Poppins;
  font-size: 16px;
}

.chatopen__submitbutton {
  color: #d94645;
  font-weight: bold;
  border: 2px solid #d94645;
  padding: 10px 30px;
  border-radius: 20px;
}

@media only screen and (max-width: 786px) {
  .chatopen__modal {
    left: 20px;
    width: 90vw;
  }
}
