.coursetasklist__container {
  font-family: Poppins;
}

.tasklist__container {
  display: flex;
  flex-wrap: wrap;
}

.coursetasks__info {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.coursetaskinfo__icon {
  margin-right: 32px;
}

.task__container {
  width: 262px;
  height: 94px;
  border-bottom: 2px solid #00ba88;
  font-family: Poppins;
  background-color: rgba(0, 186, 136, 0.04);
  display: flex;
  justify-content: flex-start;
  padding: 16px 8px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(34, 34, 36, 1);
  border-radius: 10px;
  margin-bottom: 20px;
  margin-right: 24px;
}

.taskinfo__container {
  padding-left: 8px;
}

.taskimage__container > img {
  border-radius: 50%;
}

.taskduedate {
  font-size: 14px;
  color: rgba(34, 34, 36, 0.49);
}
