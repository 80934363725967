.your-ghyst-ei {
  align-self: stretch;
  position: relative;
  font-size: 48px;
  line-height: 50px;
  font-family: Montserrat;
  text-align: center;
}
.congratulations-on-completing {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 24px;
}
.interpreting-your-scores {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.each-profile-in {
  margin-bottom: 0;
}
.each-profile-in-the-graph-repr {
  margin: 0;
  padding-left: 21px;
}
.each-profile-in-container {
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 24px;
  font-family: inherit;
}
.infointerpreting {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.button-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 40px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.returnbuttoneq {
  position: absolute;
  height: 42.86%;
  width: 90.4%;
  top: 27.14%;
  left: 4.8%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Poppins;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button11 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 200px;
  height: 52px;
}
.resultscontainer {
  /* width: 841px; */
  width: 100%;
  padding-left: 120px;
  padding-right: 32px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-self: center;

  margin: 0 auto;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  text-align: left;
  font-size: 18px;
  color: #222224;
  font-family: Poppins;
}
@media screen and (max-width: 900px) {
  .resultscontainer {
    align-self: stretch;
    width: auto;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 768px) {
  .your-ghyst-ei {
    font-size: 32px;
    line-height: 36px;
  }

  .resultscontainer {
    padding: 16px;
    gap: 16px;
  }

  .infointerpreting {
    padding: 0 8px;
  }
}
@media screen and (max-width: 480px) {
  .your-ghyst-ei {
    font-size: 28px;
    line-height: 32px;
  }
}
