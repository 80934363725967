.width-warning-container {
  display: none;
}

@media only screen and (max-width: 787px) {
  .width-warning-container {
    display: block;
    z-index: 100;
    position: fixed;
    /* margin-top: 60px; */
    background-color: white;
    width: 100vw;
    height: 100vh;
    top: 0px;
    margin-top: 60px;
    padding: 20px;
    text-align: center;
  }
  .width-warning-title {
    /* margin-top: 100px; */
    padding: 20px;
    text-align: center;
  }
}
