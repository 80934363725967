.quest-container {
  padding: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
}

.quest-container > h1 {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 32px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.questquestion__text {
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 18px;
}

.quest-questions-container {
  display: flex;
  flex-direction: column;
}

.quest-likert-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.quest-likert-container > p {
  max-width: 50px;
}

.quest-likert-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid red;
  border-width: 6px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  color: black;
  margin-bottom: 0px;
}

.quest-likert-not-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 0.5px solid red;
  margin-bottom: 0px;
}

.quest-likert-not-selected:hover {
  cursor: default;
}

.quest-likert-main-container,
.quest-freeanswer-container {
  margin-top: 10px;
  margin-bottom: 30px;
}

.quest-option-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.quest-option-container > p {
  margin-bottom: 0;
  margin-left: 5px;
  font-size: 16px;
}

.questanswer__textarea {  
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 16px;
  font-size: 16px;
  height: 128px;
  padding: 20px 24px;
}

.questanswer__question {
  font-family: Poppins;
  font-weight: 400;
  margin-bottom: 8px;
  font-size: 18px;
}

.submit__questbutton {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  padding: 13px 10px;
  border-radius: 40px;
  width: 200px;
  border: none;
  background-color: #d94645;
  color: white;
  font-size: 16px;
}

.submit__questbutton:disabled:hover {
  opacity: 0.7;
  cursor: default;
}
.submit__questbutton:disabled {
  opacity: 0.7;
  cursor: default;
}

.submit__questbutton:hover {
  cursor: pointer;
  opacity: 0.8;
}

.missingfields__text {
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 8px;
  color: #ff6565;
}

.introscreen__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-weight: 600;
  height: 400px;
}
.eq__title {
  font-family: Poppins;
  font-size: 48px;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
  margin-bottom: 15px;
}
.eq__description {
  font-family: Poppins;
  margin-bottom: 20px;
}
.introscreen__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-weight: 400;
  width: 70%;
}

.introscreen__container > h3 {
  font-size: 64px;
  text-align: center;
  max-width: 70vw;
}


@media only screen and (max-width: 995px) {

  .quest-likert-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
}