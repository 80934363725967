.thank-you {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  width: 490px;
  flex-shrink: 0;
}
.close-icon {
  position: relative;
  width: 24px;
  height: 24px;
}
.thank-you-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;
}
.check-svgrepo-com-1-icon {
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  flex-shrink: 0;
}
.you-have-confirmed {
  margin: 0;
}
.on-august-18 {
  margin: 0;
  font-weight: 600;
}
.you-have-confirmed-container {
  position: relative;
  line-height: 24px;
  display: inline-block;
  width: 532px;
}
.buttonsecondaryinitial-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 40px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.button1 {
  position: absolute;
  width: 90.4%;
  top: 25.77%;
  left: 4.8%;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonsecondaryinitial {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.button {
  position: relative;
  background-color: #fff;
  width: 200px;
  height: 52px;
  display: none;
}
.button3 {
  position: absolute;
  height: 42.86%;
  width: 90.4%;
  top: 27.14%;
  left: 4.8%;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button2 {
  position: relative;
  width: 200px;
  height: 52px;
  color: #fff;
}
.button-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 132px;
  color: #d94645;
}
.you-have-confirmed-the-eventit-parent {
  align-items: center;
  gap: 24px;
  text-align: center;
  font-size: 16px;
}
.finance-browser-warning,
.frame-parent,
.you-have-confirmed-the-eventit-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent {
  align-items: center;
  gap: 24px;
}
.finance-browser-warning {
  position: relative;
  border-radius: 16px;
  background-color: #fff;
  overflow: hidden;
  padding: 24px;
  align-items: flex-start;
  text-align: left;
  font-size: 18px;
  color: #000;
  width: 100%;
  font-family: Poppins;
}
