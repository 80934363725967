.span1 {
  font-weight: 500;
}
.b4 {
  font-size: 40px;
}

.poweredby__stripe {
  color: gray;
}

.diversionpaymentcard {
  margin-left: 16px;
  max-width: 87vw;
}

.div11 {
  position: relative;
  line-height: 52px;
  font-size: 28px;
}
.get-your-record {
  margin: 0;
}
.get-your-record-container {
  position: relative;
  line-height: 22px;
}
.button12,
.buy-now {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buy-now {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Poppins;
  color: #f8f9ff;
  text-align: center;
  width: 80px;
  flex-shrink: 0;
}
.button12 {
  cursor: pointer;
  border: 0;
  padding: 12px 32px;
  background-color: #d94645;
  border-radius: 40px;
  height: 48px;
  flex-direction: row;
  box-sizing: border-box;
  min-width: 200px;
}
.vector-icon,
.vector-icon1 {
  position: absolute;
  height: 83.33%;
  width: 83.34%;
  top: 8.33%;
  right: 8.32%;
  bottom: 8.34%;
  left: 8.34%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon1 {
  height: 41.67%;
  width: 8.34%;
  top: 29.17%;
  right: 45.82%;
  bottom: 29.16%;
  left: 45.84%;
}
.arrow-position-icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 12px;
  flex-shrink: 0;
}
.tooltip-message-will {
  align-self: stretch;
  position: relative;
  line-height: 22px;
}
.body,
.tooltip {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.body {
  align-self: stretch;
  border-radius: 8px;
  background-color: #000;
  display: flex;
  padding: 12px 16px;
  opacity: 0.8;
}
.tooltip {
  position: absolute;
  top: 29px;
  left: -14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  width: 294px;
  display: none;
}
.info-outline {
  position: relative;
  width: 20px;
  height: 20px;
}
.info-outline:hover .tooltip {
  display: flex;
}
.apply {
  text-decoration: underline;
  color: #d94645;
}
.cant-afford-it-container {
  position: relative;
  line-height: 20px;
  color: #5a5a5a;
}
.afford {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.button-parent-payment,
.diversionpaymentcard {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  text-align: left;
  color: #eee;
}

.diversionpaymentcard {
  flex: 1;
  border-radius: 24px;
  background-color: #f8f9ff;
  border: 1px solid #9f9e9e;
  padding: 16px 24px;
  gap: 16px;
  text-align: center;
  font-size: 14px;
  color: #000;
  font-family: Poppins;
  width: 500px;
  margin-right: 20px;
}
@media screen and (max-width: 1200px) {
  .diversionpaymentcard {
    flex: unset;
    align-self: stretch;
  }
}
@media screen and (max-width: 428px) {
  .buy-now {
    font-size: 16px;
  }
  .button12 {
    align-self: stretch;
    width: auto;
    padding: 8px 100px;
    box-sizing: border-box;
  }
  .cant-afford-it-container {
    font-size: 14px;
    line-height: 20px;
  }
  .afford,
  .diversionpaymentcard {
    align-items: center;
    justify-content: center;
  }
  .diversionpaymentcard {
    align-self: stretch;
    width: auto;
    flex: 1;
    gap: 50px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 350px) {
  .buy-now {
    font-size: 16px;
    line-height: 20px;
  }
  .button12 {
    align-self: stretch;
    width: auto;
    flex: 1;
    gap: 44px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
  }
  .diversionpaymentcard {
    width: 300px;
    gap: 24px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 720px) {
  .cant-afford-it-container {
    font-size: 14px;
    line-height: 20px;
  }
}
