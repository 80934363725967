.coursecontent__container {
  /* margin-top: 20px; */
  min-width: 800px;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.preassessmentlinks__container {
  display: flex;
  gap: 15px;
  margin-bottom: 32px;
  margin-top: 24px;
}

@media only screen and (max-width: 1290px) {
  .coursecontent__container {
    min-width: 100px;
    /* max-width: 90vw; */
  }
}

@media only screen and (max-width: 787px) {
  .preassessmentlinks__container {
    flex-direction: column;
  }
}
