.video-responsive {
  /* background-color: blue; */
  height: 100%;
  border-radius: 10px;
}

.video-iframe {
  height: 100%;
  border-radius: 10px;
}

.video-title-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  justify-content: flex-end;
}

.videocontainer__info {
  opacity: 0.6;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
}

.video-title-container > p {
  margin-right: 20px;
  font-size: 14px;
  color: #222224;
  font-family: Poppins;
  font-weight: 300;
}

.video-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.video-header-container > h3 {
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  color: #222224;
  margin-bottom: 0px;
  font-family: Montserrat;
  font-weight: 600;
}

.video-header-container > p {
  font-size: 14px;
  color: #222224;
  text-align: right;
  margin-top: 20px;
}

.landing-box-right-video {
  padding: 50px;
  /* border: 1px solid black; */
  width: 50%;
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

@media only screen and (max-width: 787px) {
  .landing-box-right-video {
    width: 100%;
  }
}
