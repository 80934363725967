.newaction__container {
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  /* padding: 40px; */
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
}

.tm__span {
  position: absolute;
  font-size: 18px;
  top: -20px;
}

.classId__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  /* margin-top: 40px; */
  margin-bottom: 10px;
  background-color: #fbe4e1;
  color: #ff6250;
  font-weight: bold;
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  border-radius: 8px;
  font-size: 16px;
  width: 70%;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}

.landing__button {
  background-color: #ff6250;
  color: white;
  font-weight: bold;
  /* padding: 10px 20px; */
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 70%;
}

.newaction__title1 {
  font-family: Poppins;
  font-weight: 400;
  font-weight: bold;
  font-size: 64px;
  /* color: rgba(217, 70, 69, 1); */
  color: #2d2d2d;

  line-height: 70px;
}
.newaction__title2 {
  font-family: Poppins;
  font-weight: 400;
  font-weight: bold;
  font-size: 64px;
  /* color: rgba(217, 70, 69, 1); */
  color: #2d2d2d;
  line-height: 70px;
  margin-bottom: 24px;
  position: relative;
}
.titleandimage__container {
  width: 100%;
  /* background-color: red; */
  font-family: Poppins;
  font-weight: 400;
  margin-bottom: 24px;
  padding: 0px 40px;
}

.newactionbuttons__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.newactionimage__container {
  margin-left: 32px;
  margin-top: 20px;
  vertical-align: top;
  float: right;
  width: 45%;
}

.newactionimage__image {
  width: 100%;
}
.newactioninfo__container {
  text-align: left;
  float: left;
  width: 45%;
  margin-top: 100px;
}

.actiondescription__container {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 24px;
  color: #2d2d2d;
}

.newactionstats__container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 24px;
}

.newactionstatsbox {
  margin-bottom: 24px;
  width: 170px;
  margin-right: 16px;
  margin-left: 16px;
}

.statnumber {
  font-family: Poppins;
  font-size: 36px;
  font-weight: bold;
}
.statnumberred {
  font-family: Poppins;
  font-size: 36px;
  font-weight: bold;
  color: rgba(217, 70, 69, 1);
}
.statnumberllight {
  font-size: 14px;
  text-align: right;
  font-weight: 300;
  margin-top: -15px;
  padding-right: 30px;
}

.statnumberllight:nth-child(2) {
  padding-right: 10px;
}

@media only screen and (max-width: 1080px) {
  .newactionimage__container {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .classId__container,
  .landing__button {
    width: 100%;
  }

  .actiondescription__container {
    font-size: 2vw;
    text-align: start;
  }

  .newaction__title1 {
    font-size: 7vw;
    line-height: 60px;
    color: black;
  }
  .newaction__title2 {
    font-size: 7vw;
    line-height: 60px;
  }

  .newactionimage__image {
    width: 50%;
  }

  .newactioninfo__container {
    text-align: center;
    margin: auto;
    width: 60%;
    float: none;
  }

  .newactionbuttons__container {
    flex-direction: column;
    gap: 20px;
  }
}

@media only screen and (max-width: 760px) {
  .actiondescription__container {
    font-size: 2.5vw;
  }

  .newactionstatsbox {
    width: 120px;
    margin-right: 20px;
    margin-left: 20px;
    text-align: left;
  }

  .newactioninfo__container {
    text-align: center;
    margin: auto;
    width: 80%;
    float: none;
  }

  .newaction__title1 {
    line-height: 45px;
    color: #2d2d2d;
  }
  .newaction__title2 {
    line-height: 45px;
    color: #2d2d2d;
  }

  .newactionimage__image {
    width: 70%;
  }
}

@media only screen and (max-width: 560px) {
  .actiondescription__container {
    font-size: 3.5vw;
  }

  .newactioninfo__container {
    width: 100%;
  }

  .newactionstatsbox {
    width: 120px;
    margin-right: 20px;
    margin-left: 20px;
  }

  .newaction__title1 {
    line-height: 30px;
  }
  .newaction__title2 {
    line-height: 45px;
  }
  .titleandimage__container {
    padding: 0px;
  }

  .newactionimage__image {
    width: 100%;
  }

  .statnumber {
    font-size: 28px;
  }
  .statnumberred {
    font-size: 28px;
  }
}
