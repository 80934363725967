.modules__container {
  max-width: 400px;
  min-width: 800px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* background-color: lightgrey; */
  margin-bottom: 20px;
}

.modules__title {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 24px;
}

.qrcodes__contentcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qrcodes__container {
  justify-content: space-evenly;
  width: 100%;
}

@media only screen and (max-width: 1290px) {
  .modules__container {
    margin-right: 0px;
    padding-right: 0px;
    min-width: 300px;
    max-width: 800px;
  }

  .qrcodes__container {
    flex-direction: column;
    /* background-color: red; */
    max-width: 200px;
  }
}
