.termsandconditionsmodal__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;

  font-family: Poppins;
  font-weight: 400;
}

.header__title {
  font-family: Poppins;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
}

.termsandconditionsmodal__signaturecanvas {
  position: relative;
  margin-bottom: 24px;
  width: 454px;
}

.signorclear__container {
  display: flex;
  font-weight: bold;
}

.clicktoread__button {
  /* background-color: #d94645; */
  /* color: white; */
  border: 1px solid #d94645;
  padding: 2px 12px;
  border-radius: 40px;
  font-weight: 500;
  font-size: 12px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  white-space: nowrap;
}

.alreadyread__button {
  background-color: #d94645;
  color: white;
  padding: 2px 12px;
  border-radius: 40px;
  font-weight: 500;
  font-size: 12px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  white-space: nowrap;
}

.clicktoread__button,
.alreadyread__button:hover {
  cursor: pointer;
}

.termsandconditionsmodal__content {
  font-family: Poppins;
  font-weight: 400;
  background-color: white;
  padding: 20px;
  width: 932px;
  border-radius: 15px;
  max-width: 95vw;
  max-height: 95vh;
  overflow: auto;
}

.legalnameandphone__container {
  display: flex;
  gap: 32px;
  margin-top: 24px;
}

.termsandconditionsmodal__termsbox {
  height: 180px;
  background-color: #f5f5f5;
  padding-top: 5px;
  font-size: 12px;
  overflow: scroll;
  padding-left: 7px;
  padding-bottom: 8px;
  margin-bottom: 10px;
  max-height: 95vh;
  overflow-x: hidden;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.clearsignature__text {
  position: absolute;
  right: 0px;
}

.signhere__text {
  position: absolute;
}

.signature__container__terms {
  width: 454px;
  margin-top: 10px;
  background-color: #f5f5f5;
  margin-bottom: 0px;
  max-width: 100%;
  border-bottom: 1px solid black;
}

.signature__container > canvas {
  max-width: 90vw;
}

.checkboxterms__container {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
}
.checkbox__terms {
  font-weight: bold;
  text-decoration: underline;
}

.termsandconditionsacceptterms__container {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  width: 400px;
}

.termsandconditionsmodalterms__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.currentterm__title {
  font-size: 18px;
  font-weight: bold;
}

.termsandconditionsmodal__title {
  font-size: 12px;
}
.termsandconditionsmodal__header {
  font-size: 24px;
  line-height: 34px;
  font-family: Poppins;
  font-weight: 700;
  margin-bottom: 2.5px;
  display: flex;
}

.legalinputs__title {
  font-size: 18px;
}

.legalinputs__container {
  flex: 1;
  /* margin-bottom: 20px; */
  max-width: 400px;
}

.legalinfo__warning {
  font-weight: bold;
  color: #d94645;
  margin-left: 4px;
  margin-bottom: 24px;
  max-width: 400px;
}

.legalinfo__warning > span {
  text-decoration: underline;
}

.legalinputs__input {
  width: 100%;
  height: 52px;
  flex: 1;
  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 16px;
  margin: 4px 0px;
  padding-left: 24px;
}

.termsandconditionsterms__text {
  /* margin-left: 10px; */
  margin-bottom: 5px;
  margin-top: 2.5px;
  text-decoration: underline;
  max-width: 230px;
}

.termsandconditionsmodalsubmit__button {
  width: 300px;
  height: 52px;
  border-radius: 30px;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: none;
  background-color: #d94645;
  color: white;
  font-weight: bold;
  font-family: Poppins;
  font-weight: 400;
}
.termsandconditionsmodalsubmit__button:hover {
  cursor: pointer;
  opacity: 0.8;
}
.termsandconditionsmodalsubmit__button:disabled {
  opacity: 0.6;
}

.termsandconditionsmodalbutton__container {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .termsandconditionsmodal__header {
    font-size: 18px;
    line-height: 24px;
  }

  .termsandconditionsacceptterms__container {
    width: 100%;
    gap: 8px;
  }

  .clicktoread__button,
  .alreadyread__button {
    min-width: 90px;
    white-space: nowrap;
  }

  .termsandconditionsmodal__signaturecanvas {
    width: 100%;
  }

  .signature__container__terms {
    width: 100%;
  }

  .signature__container__terms canvas {
    width: 100% !important;
  }
}
