.progress {
  /* margin-bottom: 10px; */

  width: 90%;
  max-width: 400px;
}

.md-progress {
  height: 20px;
}

.event-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-left: 20px;
  margin-bottom: 0px;
  margin: 10px;
}

.choice-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  max-width: 50vw;
}

.choice-container2 > h4 {
  font-size: 1em;
}

.choices {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: left;
  margin-bottom: 20px;
}

.choice-button {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: space-around;
  align-items: center;
  color: #535458;
  margin: 0px 20px;
  background-color: white;
  border: none;
  height: 120px;
  width: 200px;
  border-radius: 10%;
  box-shadow: 0 3px 6px lightgrey;
  font-size: 1em;
  padding: 7px;
}

.happy-amount {
  margin-bottom: 0px;
}

.decision-comparison {
  padding: 10px;
  background: antiquewhite;
  font-weight: bold;
  text-align: center;
}

.choice-category {
  margin-bottom: 0px;
  display: flex;
  justify-items: center;
  align-items: center;
  font-weight: bold;
  justify-content: center;
}

.left-to-spend-amount {
}

.decision-comparison > p {
  margin-bottom: 0px;
}

.choice-action > p {
  margin-bottom: 0px;
}

.choice-button:hover {
  box-shadow: 0 8px 20px lightgrey;
  transform: scale(1.1);
  transition: all 0.2s;
}

.choice-amount {
  font-size: 1.5em;
  font-weight: bold;
  color: black;
  margin: 0;
}

.animated-smile {
  position: absolute;
  top: 20%;
  left: 20%;
  z-index: 20;
  animation-name: float-smile-right;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.animated-smile-hidden {
  display: none;
}

@keyframes float-smile-right {
  0% {
    top: 20%;
  }
  100% {
    top: 5%;
    left: 80%;
    display: none;
  }
}

/* Animating Choices */

/* appear - on page load */
.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear 1000ms;
}

/* enter */
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms linear 300ms;
}

/* exit */
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 5000ms linear;
}
.fade-exit-done {
  opacity: 0;
}
