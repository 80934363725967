.upcomingevents__container {
  padding-left: 50px;
  margin-top: 40px;
}

.upcomingevents__container > h3 {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 20px;
  padding-right: 200px;
  padding-bottom: 28px;
  margin-bottom: 0;
}

.noevents__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 335px;
  height: 121px;
  background: #f8f7f7 0 0 no-repeat padding-box;
  border-radius: 10px;
  margin-right: 50px;
  margin-top: 20px;
}

.noevents__container > p {
  font-family: Poppins;
  font-weight: 300;
  font-size: 10px;
  padding-top: 12px;
}

.upcomingevents__card__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  border: 1px solid #d94645;
  border-radius: 10px;
  padding: 10px;
  width: 80%;
}

.upcomingevents__content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.upcomingevents__button-container {
  margin-top: 30px;
  width: 100%;
}

.upcomingevents__button-container button {
  width: 100%;
}

.upcomingevents__name {
  font-family: Poppins;
  font-weight: 600;
  color: #222224;
  font-size: 16px;
  padding-bottom: 4px;
}

.upcomingevents__day {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 16px;
  color: black;
  margin: auto;
}

.upcomingevents__month {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 16px;
  color: black;
  margin: auto;
}

.upcomingevents__description {
  font-family: Poppins;
  font-weight: 300;
  font-size: 12px;
}

.upcomingevents__datecontainer {
  margin-right: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 6px;
  padding-top: 4px;
  padding-bottom: 5px;
}
.upcomingevents__infocontainer {
  width: 200px;
  padding-left: 5px;
  border-left: 1px solid gray;
}
.upcomingevents__duedatecontainer {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
}

@media only screen and (max-width: 700px) {
  .upcomingevents__container {
    padding-left: 0px;
  }
}

@media only screen and(max-width: 1290px) {
  .upcomingevents__container {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 720px) {
  .upcomingevents__container {
    padding-left: 20px;
  }
}
