.adminclassrooms {
  padding-left: 20px;
  padding-bottom: 20px;
}
.adminclassrooms__container {
  display: flex;
  flex-wrap: wrap;
  /* padding: 20px; */
}
.adminclassrooms__contanier {
  display: flex;
  flex-direction: row;
  max-width: 90vw;
  flex-wrap: wrap;
  font-family: Poppins;
}
.adminclassroom__container {
  font-family: Poppins;
  width: 308px;
  height: 56px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 30px #00000008;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.adminclassrooms {
  margin-top: 20px;
  font-family: Poppins;
}
