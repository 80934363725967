.imageinput__text {
  font-family: Poppins;
  font-weight: 400;
  width: 610px;
  height: 52px;
  left: 0px;
  top: 0px;

  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 16px;
  padding-left: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.imageinput__uploadbutton > input {
  background-color: blue;
}
