.situation-container {
}

.situation-text:first-child {
  font-family: Poppins;
  padding: 20px;
  padding-bottom: 0;
  background-color: white;
  /* box-shadow: 0 0 3px #6c6969; */
  font-weight: 600;
  opacity: 0;
  animation-name: slideIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.situation-text {
  font-family: Poppins;
  padding: 20px;
  background-color: white;
  font-size: 18px;
  /* box-shadow: 0 0 3px #6c6969; */
  font-weight: 600;
  opacity: 0;
  animation-name: slideIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.responses-container:first-child {
  padding: 20px;
  background-color: white;
  /* box-shadow: 0 0 3px #6c6969; */
  font-weight: bold;
  margin-top: 10px;

  opacity: 0;
  animation-name: slideIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

.answered-action-button {
  background-color: #d94645;
  color: red;
  min-width: 200px;
  color: white;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 40px;
  display: inline-block;
  text-align: center;
  padding-left: 14px;
  padding-right: 14px;
  justify-content: center;
  align-items: center;
}

.responses-container {
  padding: 20px;
  padding-top: 16px;
  background-color: white;
  /* box-shadow: 0 0 3px #6c6969; */
  font-weight: bold;
  margin-top: 10px;
  padding-top: 0px;
  opacity: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  animation-name: slideIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
  max-width: 50vw;
}
.gameanddanger__container {
}

.action-button {
  font-family: Poppin;
  padding: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: white;
  border: 1px solid #d94645;
  color: #d94645;
  border: none;
  border-radius: 5px;
}

.answered-text {
  padding: 20px;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 400;
  color: black;
  opacity: 0;
  font-size: 16px;
  animation-name: slideIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

.danger-level-container {
  /* position: absolute; */
  top: 50px;
  right: 50px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.chatgamelost__modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.gamelostimage {
  max-width: 90vw;
  margin-bottom: 32px;
}
.gamelostext {
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 600;
  color: rgba(217, 70, 69, 1);
}

@keyframes slideIn {
  0% {
    margin-left: -60px;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}
