.landing-box-curriculum-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 80vw; */
  margin: 30px;
  min-height: 400px;
  margin-left: 50px;
  margin-right: 50px;
  font-weight: bold;
  margin-top: 60px;
  padding: 50px;
  /* padding-top: 150px; */
  /* max-width: 800px; */
}

.landing-curriculum-header {
  font-family: Montserrat;
  font-weight: bold;
  margin-bottom: 24px;
  font-size: 32px;
}

.curriculum-cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding: 20px; */
  max-width: 800px;
  margin-bottom: 100px;
}

.curriculum-subheader-text {
  text-align: center;
  /* letter-spacing: 0px; */
  color: #2D2D2D;
  /* opacity: 0.6; */
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  max-width: 600px;
  padding-bottom: 30px;
  justify-content: center;
  max-width: 70vw;
  position: relative;
  font-size: 16px;
  line-height: 150%;
  /* font-family: Mulish; */
  max-width: 900px;
  text-align: center;
  align-self: center;
  max-width: 80vw;
}

.curriculum-card {
  width: 183px;
  height: 193px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 20px 20px 30px #00000008;
  border-radius: 10px;
  opacity: 1;
  margin: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px;
}

.curriculum-card > p {
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 20px;
}

.curriculum-card-name {
  font-family: Montserrat;
  font-weight: 600;
}

.curriculumcard__month {
  font-family: Montserrat;
  font-weight: 500;
  color: #222224;
  opacity: 0.6;
  font-size: 14px;
}

@media only screen and (max-width: 787px) {
  .curriculum-cards-container {
    justify-content: center;
    align-items: center;
  }

  .curriculum-card {
    margin: 10px;
  }
}

@media only screen and (max-width: 1290px) {
  .landing-box-curriculum-container {
    /* padding-top: 150px; */
    padding-left: 20px;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0px;
    width: 100%;
  }

  .curriculum-subheader-text {
    /* width: 200px; */
    font-size: 16px;
  }
}
