.asset {
  /* box-shadow: 2px 2px solid #ffffff; */
  display: flex;
  flex-direction: column;
  /* min-height: 50px; */
  max-width: 120px;
}

.asset-description {
  min-height: 20%;
}

p {
  margin-bottom: 0px;
}

h5,
h6 {
  margin-bottom: 0px;
}

h6 {
  min-width: 150px;
}

/* .asset>h5{
    min-width: 65px;
    text-align: right;
} */

.asset-price {
  /* width: 100px;
  text-align: center;
  z-index: 0;
  margin-bottom: 0px; */
  color: #65b76b;
}

.asset-price-negative {
  color: red;
}

.asset-name {
  margin-bottom: 0px;
}

.positive {
  color: lightgreen;
}

.negative {
  color: lightcoral;
}

strong {
  min-width: 100px;
}
