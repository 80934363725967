/* modal.css */
.confirmmodal__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  z-index: 1000; /* make sure the modal is on top of other content */
}

.confirmodal__modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  text-align: center;
  position: relative;
}

.closelegalname__x {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.legalname__name {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.legalname__warning {
  margin-top: 10px;
  color: red;
  font-size: 14px;
  margin-bottom: 12px;
}

.legalnamebuttons__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.editlegalname__container {
  margin-bottom: 12px;
}
