.emergencytitle {
  align-self: stretch;
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.image-92-icon {
  position: relative;
  width: 16px;
  height: 16px;
  object-fit: cover;
}
.x3 {
  position: relative;
  font-weight: 500;
  opacity: 0.5;
}
.emergencyhappiness {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.emergency-div,
.emergency-div1 {
  position: relative;
  font-weight: 600;
}
.emergency-div {
  line-height: 42px;
}
.emergency-div1 {
  font-size: 32px;
}
.emergencyinfocontainer,
.emergency-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.emergency-parent {
  align-self: stretch;
  flex-direction: row;
  font-size: 24px;
}
.emergencyinfocontainer {
  width: 100%;
  border-radius: 15px;
  background-color: #ffebeb;
  border: 2px solid #bb4141;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  padding: 16px 32px;
  gap: 4px;
  max-width: 500px;
  font-size: 10px;
  color: #141414;
}
.buttonsecondaryinitial-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 40px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.button2 {
  position: absolute;
  width: 90.4%;
  top: 25.77%;
  left: 4.8%;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonsecondaryinitial1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.emergencybutton {
  width: 100%;
  position: relative;
  background-color: #fff;
  height: 52px;
  max-width: 500px;
  text-align: center;
  color: #d94645;
}
.emergencyexpense {
  border-radius: 8px;
  background-color: #fff;
  width: 378px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  box-sizing: border-box;
  grid-gap: 24px;
  gap: 24px;
  text-align: left;
  font-size: 16px;
  color: #222224;
  margin: 0 auto;
  font-family: Poppins;
  text-align: center;
  border: 1px solid red;
  margin-bottom: 20px;
}
