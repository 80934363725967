.editing {
  display: flex;
  justify-content: space-between;
}
.navigation-container {
  font-family: Poppins;
}

.title {
}

.edit-options {
  display: flex;
}

.add-section-button {
  /* font-size: 0.5em; */
}

.unselected-section {
  font-family: Poppins;
  font-weight: 300;
}

.sectioniconactions__container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.animate-nav-item {
  animation-name: navitemappear;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes navitemappear {
  0% {
    margin-left: 30px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}
