.text-container {
  margin: 0px;
  padding-bottom: 45px;
  width: 50%;
  font-size: 1.12em;
}

.text-container > p {
  margin-bottom: 0px;
}

.text-container > p > a {
  background-color: white;
  color: #a42639;
  font-weight: bolder;
  text-decoration: underline;
  letter-spacing: 2px;
}

.word-tag-container {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.word-tag-container .tooltiptext {
  visibility: hidden;
  padding: 15px;
  background-color: #a42639;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  min-width: 170px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* left: 50%; */
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.word-tag-container .tooltiptext::after {
  /* content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent; */
}

.word-tag-container:hover .tooltiptext {
  visibility: visible;
  font-size: 14px;
  opacity: 1;
}

@media only screen and (max-width: 1290px) {
  .text-container {
    width: 90%;
  }
}
