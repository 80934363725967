.openchartmodal {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Poppins;
  color: #000;
  text-align: center;
}
.opendebtmodalchart {
  cursor: pointer;
  border: none;
  padding: 21px 0px;
  background-color: #fff;
  position: relative;
  width: 271px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
