.what-is-red {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 130%;
  font-weight: 700;
}
.red-ptd-is {
  align-self: stretch;
  position: relative;
  font-size: 21px;
  line-height: 150%;
  font-family: Poppins;
}
.headline-subhead {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.icon2 {
  border-radius: 20px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.prevent-crime {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 130%;
  font-weight: 600;
}
.data-driven-approaches {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 150%;
  font-family: Poppins;
}
.text {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.card-info {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
.arrowright-icon3 {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label4 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.arrowright-icon4 {
  position: relative;
  width: 20px;
  height: 20px;
}
.button2 {
  border-radius: 20px;
  height: 30px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  font-size: 16px;
  color: #009379;
}
.feature-card {
  align-self: stretch;
  flex: 1;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 34.854148864746094px 29.626028060913086px 48.34px
    rgba(51, 102, 255, 0.05);
  border: 1px solid #e5f4f2;
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
.feature-card1 {
  align-self: stretch;
  flex: 1;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 34.854148864746094px 29.626028060913086px 48.34px
    rgba(51, 102, 255, 0.05);
  border: 1px solid #e5f4f2;
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
.cards-row {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  font-size: 28px;
}
.features {
  align-self: stretch;
  background-color: #f8f9ff;
  display: flex;
  flex-direction: column;
  padding: 120px 55px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  font-size: 38px;
  color: #2d2d2d;
  font-family: Poppins;
}

@media screen and (max-width: 960px) {
  .feature-card {
    flex: unset;
    align-self: stretch;
  }

  .feature-card1 {
    padding-left: 30px;
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }

  .cards-row {
    flex-direction: column;
  }

  .features {
    padding: 60px 16px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .what-is-red {
    font-size: 36px;
  }

  .red-ptd-is {
    font-size: 18px;
  }

  .prevent-crime {
    font-size: 24px;
  }

  .feature-card {
    flex: unset;
    align-self: stretch;
  }

  .feature-card1 {
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }

  .cards-row {
    flex-direction: column;
  }

  .features {
    padding: 60px 24px;
    box-sizing: border-box;
  }
}
