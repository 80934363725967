.modulescomplete__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 64px;
  padding: 16px;
  background-color: #fff5f5;
  height: 78px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #eca3a2;
  max-width: 1200px;
  align-self: center;
}

.modulescomplete__left {
  display: flex;
  flex-direction: row;
}

.modulescomplete__title {
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  margin-right: 16px;
}

.modulescomplete__badges {
  font-family: Poppins;
  display: flex;
  flex-direction: row;
}

.downloadmodulescomplete__button {
  font-family: Poppins;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  background-color: #d94645;
  border-radius: 100px;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.downloadmodulescomplete__button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {

  .modulescomplete__title{
    text-align: center;
  }
  .modulescomplete__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  .modulescomplete__container {
    gap: 12px;
    flex-direction: column;
    height: auto;
    /* margin-right: 32px; */
  }

  .badgesandcertificatescontainer{
    padding-left: 12px;
  }
}
