.eqtitle {
  align-self: stretch;
  position: relative;
  font-size: 48px;
  line-height: 50px;
  font-weight: 700;
  font-family: Montserrat;
}
.ghyst-ei-test {
  font-weight: 600;
}
.eqdescription {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.eqinstructionstitle {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 28px;
  font-weight: 600;
  font-family: inherit;
}
.press-the {
  font-family: Poppins;
}
.start {
  font-weight: 600;
  font-family: Poppins;
}
.read-each-statement-carefully {
  margin-bottom: 0px;
}
.press-the-start-button-to-be {
  margin: 0;
  padding-left: 21px;
}
.press-the-start-container {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 24px;
}
.eqinstructions {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 18px;
}
.remember-the-ghyst-container {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.modal-header {
  margin: 0;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.eqwarning {
  align-self: stretch;
  border-radius: 8px;
  background-color: rgba(242, 82, 82, 0.1);
  border: 1px solid rgba(242, 82, 82, 0.1);
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
}
.eqinfoadditional {
  margin: 0;
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.eqbuttontext {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180.79px;
  height: 22.29px;
  flex-shrink: 0;
}
.eqbutton {
  border-radius: 40px;
  background-color: #d94645;
  display: flex;
  flex-direction: row;
  padding: 14px 10px;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  color: #fff;
}

.eqbutton:hover {
  cursor: pointer;
}

.eqcontainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 50px 120px;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  text-align: left;
  font-size: 16px;
  color: #222224;
  font-family: Poppins;
  max-width: 1000px;
  margin: 0 auto;
}
.eqstart {
  position: relative;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 0px !important;
}

@media screen and (max-width: 960px) {
  .eqcontainer {
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
    margin: 0 auto;
  }
  .eqtitle{
    font-size: 32px;
    line-height: 40px;
  }
}
@media screen and (max-width: 420px) {
  .eqtitle {
    font-size: 32px;
    line-height: 40px;
  }

  .eqdescription {
    font-size: 16px;
    line-height: 24px;
  }

  .eqcontainer {
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 350px) {
  .eqtitle {
    font-size: 32px;
    line-height: 40px;
  }

  .eqdescription {
    font-size: 16px;
    line-height: 24px;
  }

  .eqcontainer {
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }
}
