.topnavigation__container {
  background: #b72928;
  display: inline-block;
  width: 100%;
  height: 60px;
  display: none;
  z-index: 1000;
}

.arrow__toggle {
  position: fixed;
  right: 20px;
  top: 15px;
}

@media only screen and (max-width: 1290px) {
  .topnavigation__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: Poppins;
    font-weight: 700;
    font-size: 16px;
    color: white;
    height: 60x;
    max-width: 100vw;
  }

  .topnav__areabottom {
    padding-top: 30px;
  }

  .topnav__areabottom > button {
    color: black;
  }

  .topnav__wrapper {
    position: fixed;
    background: #b72928;
    top: 60px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation-name: topNav;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  @keyframes topNav {
    0% {
      margin-top: -50px;
      opacity: 0;
    }
    100% {
      margin-top: 0px;
      opacity: 1;
    }
  }
  .topnavigation__title {
    font-size: 16px;
    font-family: Poppins;
    font-weight: 400;
    color: white;
    display: flex;
  }

  .topnavigation__title > strong {
    font-size: 20px;
    font-family: Poppins;
    font-weight: 400;
    font-weight: 600;
  }
}
