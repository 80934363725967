.assets-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  justify-content: flex-start;
  margin-bottom: 20px;
  overflow: auto;
  min-height: 50vh;
  max-height: 50vh;
  padding: 20px;
}

.category-container-assets {
  width: 55vw;
  display: flex;
  overflow-x: scroll;
  margin-bottom: 10px;
}

.category-container-name{
  margin-bottom: 0px;
}

.asset {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  margin-top: 3px;
  margin-left: 1px;
  box-shadow: 0 0 3px 0px #7b7878;
}

/* .asset:nth-child(even) {
  background-color: aliceblue;
} */

.asset-name {
  font-size: 13px;
  max-width: 150px;
}

.current-date {
  margin: 20px;
  position: fixed;
  width: 80%;
  z-index: -1;
}

.current-date > h4 {
  float: right;
}
