.wrapper {
  padding: 50px;
}

.loginModal-toggle-component {
  font-size: 2rem;
  width: 88px;
  height: 40px;
  background: #d94645;
  position: relative;
  border-radius: 100px;
  margin: 20px;
}

.loginModal-toggle-component .loginModal-toggle-button {
  position: absolute;
  top: 4px;
  left: 4px;
  height: 32px;
  width: 80px;
  background: white;
  border-radius: 100px;
  font-size: 14px;
  color: #d94645;
  line-height: 22px;
}

.loginModal-toggle-button .loginModal-toggle-text-button {
  text-align: center;
  margin-top: 5px;
}

.loginModal-text-outside-button {
  font-size: 14px;
  color: white;
  line-height: 22px;
  position: absolute;
  right: 14px;
  top: 8.5px;
}

.loginModal-toggle-component.active {
  background: #fb7c23;
  width: 88px;
}

.loginModal-toggle-component.active .loginModal-toggle-button {
  left: 4px;
}
