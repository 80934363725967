.intro-modal-investing {
  position: absolute;
  background-color: white;
  left: 30%;
  display: flex;
  justify-content: center;
  /* margin-top: 10vh; */
  padding-top: 40px;
  width: 400px;
  height: 500px;
  z-index: 10;
  box-shadow: 1px 1px 10px lightgrey;
  align-items: center;
  animation-name: investingModal;
  animation-duration: 1s;
}

.blur-background {
  position: fixed;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: white;
}

.investing-continue-button {
  width: 100%;
  padding: 10px;
  color: white;
  margin-top: 50px;
  background-color: #3e3e3e;

  font-weight: bold;
  font-size: 1.2em;
  border: none;
  box-shadow: 0 0 5px 0px black;
}

.investing-modal-contents {
  text-align: left;
  padding: 40px;
  padding-top: 0px;

  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: flex-start;
  /* margin: 10px; */
}

.warning-message {
  color: red;
  font-weight: bold;
}

@keyframes investingModal {
  0% {
    top: 100px;
  }
  100% {
    top: 0px;
  }
}
