.intro-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 10px;
  padding-top: 20px;
  font-family: Poppins;
}

.instructions {
  width: 300px;
  height: 50vh;
  position: relative;
  animation-name: text;
  animation-duration: 0.5s;
}

.instructions > h5 {
  margin-top: 25px;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  margin-bottom: 10px;
}

.intro-instructions-text {
  animation-name: slide-in-text;
  margin-top: 20px;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  margin-left: 100px;
  padding: 20px;
  padding-left: 0px;
  padding-top: 0px;
  /* background-color: beige; */
  color: #343434;
}

.intro-instructions-title {
  font-size: 0.7em;
  padding: 0px;
  margin: 0px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.intro-instructions-action {
  color: #343434;
}

/* .intro-container-image:hover {
  transform: scale(3);
  margin-left: 250px;
  margin-top: 100px;
  z-index: 100;
  box-shadow: 0px 0px 6px 3px rgb(75 75 75 / 20%);
} */

@keyframes slide-in-text {
  0% {
    /* margin-left: 10px; */
  }
  100% {
    margin-left: 0px;
  }
}

.instructions > p {
  font-size: 1.2em;
}

@keyframes text {
  0% {
    left: 200px;
    top: 0px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}

.spreadsheet {
  width: 700px;
  display: flex;
  flex-direction: column;
}

iframe {
  width: 100%;
}

.highlight-answer {
  background-color: aquamarine;
  margin: 10px;
  padding: 5px;
  font-size: 0.8em;
  margin-left: 0px;
}

.cell {
  background-color: blue;
  color: white;
  margin: 10px;
  padding: 5px;
  font-size: 0.8em;
  margin-left: 0px;
}
