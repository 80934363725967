.info-card-title-container-right {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: flex-end;
}
.info-card-title-container-left {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.infocard__title {
  font-family: Poppins;
  font-weight: 300;
}

.infocard__info {
  font-family: Montserrat;
  font-weight: 500;
  opacity: 0.6;
  font-size: 14px;
}

.info-card-title-container-right > p {
  margin-right: 12px;
}
.info-card-title-container-left > p {
  margin-left: 12px;
}

.infocard__headerTitleright {
  text-align: right;
}
.infocard__headerTitleleft {
  text-align: left;
}

.info-card-header-container-right > h3 {
  font-family: Montserrat;
  font-weight: 600;
  /* font-weight: bold; */
}
.info-card-header-container-left > h3 {
  font-family: Montserrat;
  font-weight: 600;
  /* font-weight: bold; */
}

.info-card-header-container-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.info-card-header-container-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-card-header-container-right > p {
  text-align: right;
}
.info-card-header-container-left > p {
  /* max-width: 250px; */
}

.info-card-container-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.info-card-container-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.info-card-header-container {
  display: flex;
  align-items: flex-end;
}

@media only screen and (max-width: 1290px) {
  .info-card-header-container-left {
    padding-bottom: 30px;
  }
}
