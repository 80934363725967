.signin-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  /* margin-top: 100px; */
  /* box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4); */
  padding: 50px;
  animation-name: slideTop;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
  margin-top: 30px;
}

@keyframes slideTop {
  0% {
    opacity: 0;
  }
  100% {
    margin-top: 130px;
    opacity: 1;
  }
}

.signin-form-container > h1 {
  font-family: Montserrat;
}

.signin-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signin-form-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signin-form-2 > input {
  margin: 10px;
  border: none;
  box-shadow: 0px 2px 5px 0.5px #c5c5c5;
  padding: 10px;
  border-radius: 10px;
}

.single-input {
  margin: 5px;
  border: none;
  width: 50%;
  margin-left: 20px;
  box-shadow: 0px 2px 5px 0.5px #c5c5c5;
  padding: 10px;
  border-radius: 5px;
}

.signin-button {
  background-color: #a42639;
  color: white;
}

.spinner-class {
  margin: 10px;
}

.google-img {
  width: 50%;
  height: auto;
}

.google-form {
  display: flex;
  margin: 10px;
  justify-content: center;
  align-items: center;
}

.google-img:hover {
  cursor: pointer;
}
