.info-container {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.info-col-1 {
  padding: 10px;
  padding-left: 0px;
}

.info-col-1 > h6 {
  color: black;
}

.portfolio-cash-investments {
  display: flex;
}

.net-worth-goal-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 50px;
}

.progress-div {
  width: 80%;
}

.equal-bar {
  max-width: 30%;
}

.chart-pie {
  position: absolute;
  bottom: 15vh;
  right: 10px;
  min-width: 300px;
  height: auto;
}
.chart-pie > canvas {
  height: auto;
}

.timer-start {
  margin: 0px;
  margin-top: 0px;
  /* padding: 15px; */
}

.timer-start-button {
  background-color: #3e3e3e;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 10px;
  font-size: 1.5em;
}

.timer-stop {
  background-color: red;
}

.timer-pulse {
  animation-name: start-button-pulse;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@keyframes start-button-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.timer-start > button {
  padding: 10px;
}
