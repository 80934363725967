.info-title {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 500;
  opacity: 0.5;
}
.info-amount {
  position: relative;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 20px;
  font-weight: 600;
}
.totaldebt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 14px;
  color: #161616;
  font-family: Poppins;
}
