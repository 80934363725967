.feedbackmodal__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  border-radius: 10px;

  font-family: Poppins;
  font-weight: 400;
}

.feedbackmodal__content {
  font-family: Poppins;
  font-weight: 400;
  background-color: white;
  padding: 20px;
  width: 500px;
  /* height: 274px; */
  border-radius: 15px;
  max-height: 90vh;
  overflow-y: auto;
  margin: 20px;
}
.feedbackmodal__top {
  font-size: 16px;
  /* font-family: Poppins; font-weight: 400; font-weight: 600; */
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.feedbackmodal__info {
  margin-bottom: 20px;
  /* margin-top: 20px; */
  font-family: Poppins;
  font-weight: 500;
  font-weight: medium;
}

.feedbackmodal__scalecontainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 24px;
}

.feedbackmodalscale__number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgba(187, 65, 65, 1);
  padding: 18px;
  width: 18px;
  height: 18px;
  border: red;
  border-radius: 18px;
  border: 1px solid red;
}
.feedbackmodalscale__number:hover {
  cursor: pointer;
}

.feedbackmodal__save {
  display: flex;
  justify-content: right;
  /* margin-bottom: 24px; */
}
.feedbackinput__container {
  margin-bottom: 24px;
}
.feedbackinput__text {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  margin-bottom: 10px;
}

.feedbackinput__input {
  width: 100%;
  font-family: Poppins;
  font-weight: 400;
  border-radius: 20px;
  height: 100px;
  max-width: 450px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}
