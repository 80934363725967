.initialassessments_maincontainer {
  display: flex;
  margin-top: 16px;
}

.initialassessments__container {
  display: flex;
  justify-content: space-between;

  flex-direction: column;

  width: 236px;
  height: 272px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d94645;
  border-radius: 10px;
  opacity: 1;
  margin-right: 32px;
  padding-top: 15px;
  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 40px;
}

.initialassessment__cardtop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.initialassessment__cardmid {
  display: flex;
  flex-direction: column;
}
.initialassessment__cardbottom {
  display: flex;
  flex-direction: column;
}

.initialassessment__topleftcontainer {
  display: flex;
}

.initialassessment__toprightcontainer {
  display: flex;
}

.initialassessment__midtitle {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: ;
}

.initialassessment__midsubtitle {
  font-family: Poppins;
  font-weight: 300;
  font-size: 10px;
  text-align: center;
  margin-top: 12px;
}

.initialassessments__joinclassinput {
  width: 203px;
  height: 32px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 12px;
  font-family: Poppins;
  font-weight: 400;
  font-style: 10px;
  opacity: 0.5;
}

.initialassessment__button {
  width: 203px;
  height: 31px;
  background: #d94645 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: white;
  border: none;
  margin-top: 21px;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.initialassessment__button:disabled {
  background: #ededed 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #8d8d8d;
}

.initialassessment__disabled {
  background: #ededed 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #8d8d8d;
}

.initialassessment__toprightcontainer > p {
  margin-bottom: 0px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 10px;
  opacity: 0.6;
  padding-left: 9px;
}

.topleftcontainer__time {
  font-family: Poppins;
  font-weight: 400;
  font-size: 10px;
  margin-bottom: 0px;
  padding-left: 8px;
  color: #222224;
  opacity: 0.6;
}

.initialassessment__complete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.initialassessment__complete > p {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  color: #ff6565;
  font-size: 12px;
  padding-top: 14px;
  margin-bottom: 0px;
}

.initialassessment__tooltip {
  position: absolute;
  right: -120%;
  bottom: -30px;
  width: 200px;
  height: 100px;
  background-color: #d94645;
  border-radius: 20px;
  animation: pulse 2s linear infinite;
  color: white;
  padding: 15px;
  font-weight: 500;
}

.introvideomodal__contentcontainer {
  width: 772px;
  height: 508px;
  margin: 0 auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 20px 20px 30px #00000008;
  border-radius: 10px;
}

.introvideomodal__title {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  padding-top: 40px;
  max-width: 468px;
  margin: 0 auto;
  padding-bottom: 33px;
}

.introvideomodal__video {
  display: flex;
  justify-content: center;
  align-items: center;
}

.introvideomodal__button {
  width: 139px;
  height: 39px;
  background: #ededed 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 12px;
  color: #8d8d8d;
}

.introvideomodal__buttondone {
  width: 139px;
  height: 39px;
  background: #d94645 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 12px;
  color: white;
}

.introvideomodal__buttondone:hover {
  cursor: pointer;
}

@media only screen and (max-width: 710px) {
  .initialassessments_maincontainer {
    display: flex;
    flex-direction: column;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}
