.notifications__notificationcontainer {
  width: 773px;
  min-height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 30px 50px #00000007;
  border-radius: 10px;
  opacity: 1;
  /* padding: 20px 17px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
  /* border: 0.5px solid lightgrey; */
  color: #222224;
  font-family: Poppins;
  font-weight: 300;
  font-size: 12px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
}

.notifications__notificationcontainer > p {
  margin-bottom: 0px;
}
.notification__close {
  display: none;
}

@media only screen and (max-width: 1290px) {
  .notifications__notificationcontainer {
    width: 90%;
  }
}

@media only screen and (max-width: 699px) {
  .notifications__notificationcontainer {
    margin-bottom: 60px;
  }
}

