.multiclassroom__container {
  padding: 18px;
}

.categories__title {
  font-weight: bold;
  font-size: 14px;
}

.categories__container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  /* margin-top: 16px; */
}

.category__category {
  /* this is like a tag design */
  background-color: lightcoral;
  padding: 4px;
  color: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.multiclassroom__categoryoptions {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
  /* margin-top: 16px; */
}

.multiclassroom__button {
  background-color: lightcoral;
  color: white;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.multiclassroom__button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.category__category:hover {
  opacity: 0.8;
  cursor: pointer;
}

.toast__container {
  position: absolute;
  left: 100px;
  width: 20px;
  height: 20px;
  background-color: white;
  top: 0;
  right: 0;
  width: 418px;
  padding: 16px;
  height: 418px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* z-index: 100; */
}

.risk__container {
  display: flex;
  flex-direction: row;
}
.risk__col {
}

.multiclassroom__row {
  height: 44px;
  border-bottom: 1px solid #ffebeb;
}

.multiclassroom__row > td {
  padding-left: 12px;
}

.multiclassroom__headerrow {
  background-color: #fff8f8;
  height: 44px;
  border-bottom: 1px solid lightgray;
}
.multiclassroom__header {
  /* background-color: red; */
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 140px;
}

.classroom__datapoints {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
}

.data__content {
  display: flex;
  gap: 12px;
}

.toast__question {
  background-color: #ffebeb;
  padding: 4px;
  border-radius: 12px;
  margin-bottom: 8px;
  padding-left: 8px;
  padding-top: 4px;
  white-space: nowrap;
  width: auto;
  max-width: 250px;
  text-wrap: auto;
}

.data__number {
  color: rgba(217, 70, 69, 1);
  font-weight: 500;
}

.dropout__text {
  width: 357px;
}

.dropout__question {
  width: 550px;
  background: #fff8f8;
  margin-bottom: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid rgba(236, 163, 162, 0.4);
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.dropout__category {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
}

.questions__frequency {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 24px;
  margin-top: 24px;

  margin-bottom: 24px;
}

.toggle__box {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  align-items: center;
  width: 25px;
  height: 25px;

  border-radius: 50%;
  margin-top: 4px;
}

.risk__level {
  align-self: center;
  color: white;
}

.toast__question__safe {
  background-color: #e8f5e9;
  padding: 4px;
  border-radius: 12px;
  margin-bottom: 8px;
  padding-left: 8px;
  padding-top: 4px;
  white-space: nowrap;
  width: auto;
  max-width: 250px;
  text-wrap: auto;
}

.toast__trigger {
  /* display: none; */
  display: flex;
  justify-content: center;
}

.toast__name {
  font-weight: bold;
  padding-bottom: 12px;
  font-size: 18px;
}
