.gedplatform__container {
  /* modal  */
  font-family: Poppins;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gedplatform__container > iframe {
  height: 90vh;
}

.portal__button {
  border: 2px solid #d94645;
  box-sizing: border-box;
  border-radius: 40px;
  background-color: white;
  color: #d94645;
  width: 150px;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Poppins;
  font-weight: 500;
  padding: 10px 20px;
}

.gedportallink__container {
  margin-bottom: 20px;
}
