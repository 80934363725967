.complete {
  background-color: green;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
}

.userfixed__index {
  width: 30px;
  text-align: center;
}

.username__title {
  width: 160px;
}

.fixedtable__container {
  margin-top: 10px;
}

.tabledata__cellvalue {
  max-height: 80px !important;
}

.download__transcript__icon {
  text-align: center;
}

.download__transcript__icon:hover {
  cursor: pointer;
}

.tabledata__rowdata {
  max-height: 80px !important;
}

.incomplete {
  color: red;
}

.user-link {
  min-width: 120px;
  max-height: 60px;
}

.user-link:hover {
  cursor: pointer;
}

.columns__selector {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px;
}

.tables__container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.complete-styling {
  padding: 10px;
  background-color: green;
  color: white;
  font-weight: bold;
}

.columnsview__container {
  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;
  display: "flex";
  align-items: "center";
}

.columnsview__container:hover {
  cursor: pointer;
}

.choosementorselect__container {
  font-family: Poppins;
  font-weight: 400;
  border: none;
  max-width: 150px;
  padding: 5px;
  border-radius: 5px;
}
.choosementorselecthasmentor__container {
  background-color: lightgreen;
}

.columns-editor {
  position: absolute;
  left: 0;
  width: 200;
  background-color: white;
  box-shadow: "0px 0px 10px rgba(45, 45, 45, 0.15)";
  border: 2px solid lightgray;

  max-height: 400px;
  overflow: scroll;
  z-index: 100;
}

.fixedtable__cell {
  color: pink;
  max-height: 80 !important;
  height: 80 !important;
  overflow: hidden;
  line-height: initial;
  box-sizing: border-box;
}

.fixedtable__container td.fixedtable__cell {
  height: 80px !important;
  max-height: 80px !important;
  box-sizing: border-box;
  /* Add other styles here */
}

.fixedtable__container tbody tr td.fixedtable__cell {
  /* same styles as above */
  height: 80px !important;
  max-height: 80px !important;
  box-sizing: border-box;
}

.simple-table-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.search-container {
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  max-width: 300px;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
}

.search-input:focus {
  outline: none;
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.simple-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.simple-table th,
.simple-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
}

.simple-table th {
  background-color: #fafafa;
  font-weight: 500;
}

.simple-table tr:hover {
  background-color: #fafafa;
}

.delete-confirmation-input {
  width: 100%;
  padding: 8px 12px;
  margin-top: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
}

.delete-confirmation-input:focus {
  outline: none;
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
