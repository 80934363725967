.budget-title {
  align-self: stretch;
  position: relative;
  line-height: 36px;
  font-weight: 600;
}
.budget-money {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  opacity: 0.5;
}
.money {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #00ba88;
  width: 99px;
  height: 52px;
  overflow: hidden;
  opacity: 0.5;
}
.budget-money-type {
  position: relative;
  line-height: 36px;
  font-weight: 600;
}
.budget-available-money {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.budget-money-type-parent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 0 auto;
}
.budget-money-container {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.happiness {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #d94645;
  width: 80px;
  height: 52px;
  overflow: hidden;
  opacity: 0.5;
  transition: width 0.3s ease-in-out;
}
.budget-sad-emoji-icon {
  position: relative;

  object-fit: cover;
}
.budget-percent-score {
  position: relative;
  font-weight: 600;
}
.budget-happiness-score {
  position: absolute;
  top: 4px;
  left: calc(50% - 39.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.budget-happiness-indicator {
  align-self: stretch;
  position: relative;
  border-radius: 15px;
  background-color: rgba(217, 70, 69, 0.3);
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: 24px;
}
.budget-money-scores {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  gap: 5px;
}
.budget-indicators-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.your-friends-are {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.choicescontainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 30px;
  padding: 10px;
}
.choicescontainermain {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 16px;
}
.categorytitle {
  align-self: stretch;
  position: relative;
  line-height: 22px;
  font-weight: 600;
}
.money2 {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #f27117;
  width: 216px;
  height: 32px;
  overflow: hidden;
  opacity: 0.8;
}
.div {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 20.5px);
  line-height: 24px;
  font-weight: 500;
}
.money1 {
  align-self: stretch;
  position: relative;
  border-radius: 15px;
  background-color: rgba(242, 113, 23, 0.3);
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.frame-child {
  position: relative;
  width: 1.5px;
  height: 41.5px;
  object-fit: cover;
  opacity: 0.5;
}
.div1 {
  position: relative;
  line-height: 14px;
  font-weight: 500;
  display: inline-block;
  width: 105.9px;
  opacity: 0.5;
}
.vector-parent {
  margin: 0 !important;
  position: absolute;
  top: -3px;
  left: 365.05px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  z-index: 1;
  color: #000;
}
.money-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.categoryindicator {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
}

.budgetedcategoryindicator {
  align-self: stretch;
  border-radius: 15px;
  background-color: rgba(242, 113, 23, 0.08);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 8px 32px 32px;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #f27117;
  margin-top: 30px;
  width: 100%;
}

.budget-simulation {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  max-width: 800px;
}

.simulationcontainer {
  align-self: stretch;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 40px 0px 0px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
}

.budgetingmonthsimulation {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: 24px;
  color: #222224;
  font-family: Poppins;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  padding: 40px 120px 0px;
}

@media screen and (max-width: 720px) {
  .budgetingmonthsimulation {
    padding: 10px;
  }
}

.budgetingmonthsimulation .budget-simulation {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.budget-money-title {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  opacity: 0.5;
}

.totalamount {
  background-color: #00ba88;
  overflow: hidden;
  opacity: 0.5;
  z-index: 0;
}

.budget-money-type {
  position: relative;
  line-height: 36px;
  font-weight: 600;
}

.budget-available-money {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.budget-money-type-parent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 0 auto;
}

.budget-money-indicator,
.budgetmoneyindicator {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.budget-money-indicator {
  align-self: stretch;
  border-radius: 15px;
  background-color: rgba(0, 186, 136, 0.25);
  overflow: hidden;
  flex-direction: row;
  position: relative;
  text-align: left;
  font-size: 16px;
  height: 52px;
}

.budgetmoneyindicator {
  width: 200px;
  flex-direction: column;
  gap: 4px;
  text-align: center;
  font-size: 14px;
  color: #222224;
  font-family: Poppins;
}

.budget-happiness-title {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  opacity: 0.5;
}

.happiness {
  position: relative;
  background-color: #d94645;
  height: 52px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.5;
  z-index: 0;
}

.budget-sad-emoji-icon {
  position: relative;
  object-fit: cover;
}

.budget-percent-score {
  position: relative;
  font-weight: 600;
}

.totalamount1 {
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 40px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  z-index: 1;
}

.budget-money-indicator1,
.budgethappinessindicator {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.budget-money-indicator1 {
  align-self: stretch;
  border-radius: 15px;
  overflow: hidden;
  flex-direction: row;
  position: relative;
  font-size: 24px;
}

.budgethappinessindicator {
  width: 200px;
  flex-direction: column;
  gap: 4px;
  text-align: center;
  font-size: 14px;
  color: #222224;
  font-family: Poppins;
}

@media only screen and (max-width: 700px) {
  .budget-money-scores {
    flex-direction: column;
  }
}

@media screen and (max-width: 420px) {
  .budget-money-scores {
    flex-direction: column;
  }

  .simulationcontainer {
    padding-top: 20px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 720px) {
  .budgetingmonthsimulation {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
