.debt-win {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background-color: white;
  box-shadow: 1px 1px 3px #bfbebe;
  flex-direction: column;
  top: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
