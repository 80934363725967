.highscores-container {
  position: absolute;
  top: 10px;
  right: 5px;
  background-color: #3e3e3e;
  width: 450px;
  height: 400px;
  overflow: auto;
  z-index: 11;
  animation-name: highscoreAnim;
  animation-duration: 0.5s;
  padding: 20px;
  color: white;
  box-shadow: -3px 2px 8px 0px #5eb4ef;
}

.highscores-container > h1 {
  color: white;
  font-weight: bold;
  padding-left: 20px;
}

.highscores-hidden {
  margin-top: 0px;
  position: fixed;
  right: 20px;
  top: 45vh;
  border: none;
  background-color: #3e3e3e;
  padding: 10px;
  color: white;
  border-radius: 15px;
  box-shadow: 0 0 5px 0px black;
}

.score {
  font-size: 2em;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

@keyframes highscoreAnim {
  0% {
    top: 40vh;
  }
  100% {
    top: 10px;
  }
}
