.sidenav__container {
  /* position: fixed;
  left: 0px; */
  height: 100%;
  background: #b72928;
  width: 80px;
  display: inline-block;
}

.sidenav__wrapper {
  position: fixed;   /* Make the wrapper fixed */
  top: 0;            /* Start from the top of the viewport */
  left: 0;           /* Start from the left of the viewport */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 80px;       /* Make sure to set the width for fixed positioning */
}

.sidenav__areatop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidenav__areatop > h5 {
  padding-bottom: 56px;
  margin-bottom: 0px;
  padding-top: 20px;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 700;
  text-align: center;
  color: white;
}

@media only screen and (max-width: 1290px) {
  .sidenav__container {
    display: none;
  }
}
