.video-watch > iframe {
  max-width: 600px;
  border-radius: 8px;
}
.video-watch {
  max-width: 800px;
  border-radius: 8px;
}

iframe {
  border-radius: 8px;
}

@media only screen and (max-width: 1290px) {
  .video-watch {
    width: 100%;
    max-width: 100%;
  }
}
