.budgetsaved-accounttype {
  position: relative;
  line-height: 24px;
}
.currency-title {
  position: relative;
  line-height: 36px;
  font-weight: 600;
}
.total-budget-decision {
  position: relative;
  font-size: 24px;
  font-weight: 600;
}
.account-total {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.budget-saved-info {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.budgetsaved-account {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-align: left;
  font-size: 16px;
}
.budgetsavedcard-decision {
  flex: 1;
  border-radius: 15px;
  background-color: #fff;
  border: 1.5px solid #eca3a2;
  box-sizing: border-box;
  height: 152px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-align: center;
  font-size: 14px;
  color: #222224;
  font-family: Poppins;
}

.budgetsavedcard-decision:hover {
  cursor: pointer;
  background-color: lightgray;
}
