.next__button {
  width: 200px;
  height: 52px;
  background-color: rgba(217, 70, 69, 1);
  border-radius: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
}

.next__button:hover {
  cursor: pointer;
}

.nextbutton__disabled {
  width: 200px;
  height: 52px;
  background-color: rgba(217, 70, 69, 1);
  border-radius: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  opacity: 0.6;
}
