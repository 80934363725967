.self-awareness {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.frame-inner {
  position: relative;
  width: 272px;
  height: 144px;
  overflow: hidden;
  flex-shrink: 0;
}
.lorem-ipsum-dolor {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.modal-header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frame-parent1,
.notification {
  display: flex;
  justify-content: center;
}
.notification {
  flex: 1;
  border-radius: 8px;
  /* background-color: rgba(97, 200, 145, 0.1); */
  border: 1px solid rgba(97, 200, 145, 0.1);
  box-sizing: border-box;
  flex-direction: column;
  padding: 8px 16px;
  align-items: center;
  min-width: 300px;
}
.frame-parent1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 24px;
  font-size: 16px;
}
.resultareacontainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 0 24px;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 18px;
  color: #222224;
  font-family: Poppins;
}

.chart-container {
  width: 272px;
  height: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .frame-parent1 {
    flex-direction: column;
    align-items: center;
  }

  .chart-container {
    width: 100%;
    max-width: 272px;
  }

  .notification {
    width: 100%;
    min-width: unset;
  }
}
