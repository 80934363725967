.div-step {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 130%;
  font-weight: 700;
}
.register-to-the {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 130%;
  font-weight: 600;
}
.register-to-the-online-classro-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  color: #2d2d2d;
}
.steps7 {
  flex: 1;
  border-radius: 20px 20px 0px 0px;
  background-color: #fff;
  box-shadow: 30px 30px 48px rgba(51, 102, 255, 0.05);
  border-bottom: 4px solid #f2bfaf;
  box-sizing: border-box;
  height: 229px;
  width: 300px;
  max-width: 40vw;
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 67px;
  color: #f2bfaf;
  font-family: Poppins;
}

@media screen and (max-width: 420px) {
  .div-step {
    font-size: 40px;
  }

  .register-to-the {
    font-size: 20px;
  }
}
@media screen and (max-width: 576px) {
  .steps7 {
    flex: unset;
    align-self: stretch;
  }
}
