@font-face {
  font-family: Poppins;
  font-display: Arial;
}

h1,
h2,
h3,
h4,
h5 {
  /* font-family: Montserrat; */
}

p {
  font-family: Poppins;
  font-weight: 400;
}

.chartjs-render-monitor {
  max-height: 400px;
}
