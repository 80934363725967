.checkbox__checked {
  /* height: 24px; */
  /* width: 24px; */
  background-color: #d94645;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.checkbox__unchecked {
  /* height: 24px; */
  /* width: 24px; */
  background-color: white;
  border: 1px solid #d94645;
  border-radius: 5px;
  cursor: pointer;
}

.checkbox__checked,
.checkbox__unchecked {
  min-width: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
