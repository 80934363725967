.rangeinput-slider-warning::-webkit-slider-thumb {
  background: red;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.rangeinput-slider::-webkit-slider-thumb {
  background: green;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
