.table-view {
  font-family: Poppins;
  width: 100%;
  max-width: 500px;
}

.table-view > th {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
}

.table-view > tr {
}
