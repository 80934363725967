.our-program {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 130%;
  font-weight: 700;
}
.learn-more-about {
  align-self: stretch;
  position: relative;
  font-size: 21px;
  line-height: 150%;
}
.header2 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.cardloco {
  align-self: stretch;
  border-radius: 20px;
  box-shadow: 34.854148864746094px 29.626028060913086px 48.34px
    rgba(51, 102, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  font-size: 20px;
}
.header1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}
.graduation-ceremony-1-1-icon {
  flex: 1;
  position: relative;
  border-radius: 20px;
  max-width: 100%;
  overflow: hidden;
  height: 615px;
  object-fit: cover;
}
.features2 {
  align-self: stretch;
  background-color: #f8f9ff;
  display: flex;
  flex-direction: row;
  padding: 120px 55px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 60px;
  max-width: 1400px;
  margin: 0 auto;
  text-align: left;
  font-size: 38px;
  color: #2d2d2d;
  font-family: Poppins;
}

@media screen and (max-width: 960px) {
  .header1 {
    flex: unset;
    align-self: stretch;
  }

  .graduation-ceremony-1-1-icon {
    flex: unset;
    align-self: stretch;
  }

  .features2 {
    flex-direction: column;
    padding: 60px 32px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .our-program {
    font-size: 36px;
    text-align: center;
  }

  .learn-more-about {
    font-size: 18px;
    text-align: center;
  }

  .header2 {
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
  }

  .header1 {
    flex: unset;
    align-self: stretch;
  }

  .graduation-ceremony-1-1-icon {
    flex: unset;
    align-self: stretch;
  }

  .features2 {
    flex: 1;
    flex-direction: column;
    padding: 60px 24px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1300px) {
  .header1 {
    flex: unset;
    align-self: stretch;
  }

  .graduation-ceremony-1-1-icon {
    flex: unset;
    align-self: stretch;
  }

  .features2 {
    flex-direction: column;
  }
}
