.logo-word-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  font-family: Poppins;
}

.title-word {
  width: 60px;
  font-size: 88px;
}

.title-word-next {
  font-family: Poppins;
  font-weight: 500;
  font-size: 32px;
  /* padding-top: -20px; */
  /* position: absolute; */
  padding-top: 30px;
}

.action-container-navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
}

.user-type-title {
  font-family: Montserrat;
}

.action-arrow {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 25px;
  margin-left: 15px;
  background-color: white;
  border: 1px solid #d94645;
  box-shadow: none;
  transform: rotate(180deg);
  /* outline: none; */
}

.action-arrow:focus {
  outline: none;
}

.action-arrow-2 {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 25px;
  margin-left: 15px;
  background-color: #d94645;
  border-color: #d94645 !important;
  box-shadow: none;

  /* transform: rotate(180deg); */
}

.action-arrow-2:focus {
  outline: none;
}

.landing-circle-location-active {
  width: 17px;
  height: 6px;
  background: #ff6565 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-right: 3px;
  animation-name: active-location;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.landing-box-right > h3 {
  color: #222224;
  font-weight: bold;
}

.landingaction__infotext {
  font-family: Poppins;
  font-weight: 300;
  font-size: 12px;
  opacity: 0.8;
  margin-bottom: 0px;
}

.landingaction__scrolldowncontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.landingaction__scrolldowncontainer > p {
  margin-left: 20px;
  font-family: Montserrat;
  font-size: 12px;
  color: #d94645;
}

@keyframes active-location {
  0% {
    background: #d9d9d9 0% 0% no-repeat padding-box;
    width: 6px;
    /* opacity: 0; */
  }
  100% {
    background: #ff6565 0% 0% no-repeat padding-box;
    width: 17px;
    /* opacity: 1; */
  }
}

.action-landing-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 50px;
  border-radius: 7px;
  padding: 7px 14px;
  border: none;

  color: white;
  width: 200px;
  height: 40px;
  background: #d94645 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: 700;
  text-align: center;
}
.action-landing-button-container:hover {
  cursor: pointer;
}

.landing-circle-location {
  width: 6px;
  height: 6px;
  background: #d9d9d9 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-right: 5px;
}

.landing-circles-location-container {
  display: flex;
}
