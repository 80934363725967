.modal__container {
  position: absolute;
  width: 65vw;
  min-width: 830px;
  height: 500px;
  border-radius: 16px;
  margin: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 20%;
  animation: modalGrowEffect 0.5s;
  z-index: 1000;
}

.tm__container {
  position: absolute;
  font-size: 12px;
  top: -10px;
}

.modal__container__left {
  width: 50%;
  height: 500px;
  background: #d94645;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  display: inline-block;
  vertical-align: top;
}

.modal__container__right {
  width: 50%;
  height: 500px;
  background: #ffffff;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  display: inline-block;
  vertical-align: top;
}

.modal__text__container__right {
  align-items: center;
  padding: 0px;
  width: 80%;
  margin: auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.diversiontag__container {
  /* background-color: red; */
  display: flex;
  justify-content: center;
}

.lsi__selectoptioncontainer {
  width: 100%;
  height: 52px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 16px;
  margin: 4px 0px;
  padding-left: 24px;
  padding-right: 24px;
}

.text__container__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15% 10%;
}

.text__container__left__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 30px;
}

.listItems__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 0px 30px;
  gap: 12px;
}

.listItem__textGroup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
}

.text__container__right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text__container__right__title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  position: relative;
}

.text__container__right__subtitle {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin: 15px 0px;
}

.button__container__right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button__container__warning__sign {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-top: 10px;
  gap: 4px;
}

.button__container__google__button {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  background: #d94645;
  border-radius: 40px;
  align-items: center;
  border: none;
}

.google__button__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
}

.google__button__inner__text {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.button__container__warning__sign__inner {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #222224;
}

.close__icon__button {
  position: absolute;
  font-size: 30px;
  top: 0px;
  right: 10px;
  cursor: pointer;
}

.exclamation__sign__circle {
  width: 16px;
  height: 16px;
  padding: 1px 2px 2px 2px;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  border: 1px solid black;
}

.listItem__check__text:before {
  position: absolute;
  height: 15px;
  width: 7px;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
  content: "";
  transform: rotate(45deg);
  left: -25px;
  top: 8px;
}

.listItem__check__text {
  font-size: 16px;
  color: white;
  font-weight: bold;
  margin-top: 2px;
}

.listItem__text__description {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.85);
}

.modal__container__antd .ant-modal-header {
  display: none;
}

.modal__container__antd .ant-modal-close {
  display: none;
}

.modal__container__antd .ant-modal-content {
  background-color: transparent;
}

.modal__mask {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
}

.stop-scroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

@media only screen and (max-width: 890px) {
  .modal__container__left {
    display: none;
  }
  .modal__container__right {
    width: 100%;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .modal__container {
    width: 95vw;
    min-width: 0px;
  }
}

@keyframes modalGrowEffect {
  from {
    transform: scale(0.9) translateX(-50%);
    transform-origin: top left;
  }
  to {
    transform: scale(1) translateX(-50%);
    transform-origin: top left;
  }
}
