.notificationfeedback__container {
  width: 500px;
  height: 330px;

  margin: 0 auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 20px 20px 30px #00000008;
  border-radius: 10px;
  display: flex;

  align-items: center;
  flex-direction: column;
  animation-name: openNotification;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.notificationcontent__container {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.notificationcontent__container > h5 {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  margin-bottom: 5px;
}

.feedback__button {
  border-radius: 7px;
  padding: 7px 14px;
  border: none;

  color: white;
  width: 200px;
  height: 40px;
  background: #d94645 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: 600;
}

.feedback__toggler {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #d94645;
  color: white;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  box-shadow: 0px 0px 6px 3px rgb(75 75 75 / 20%);
  border-radius: 20px;
  z-index: 100;
}

.feedback__toggler:hover {
  cursor: pointer;
}

.notificationcontent__container > textarea {
  font-family: Montserrat;
  margin-top: 10px;
  min-width: 300px;
  min-height: 120px;
  font-size: 14px;
  margin-bottom: 15px;
}

.feedback__specifics > span {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
}

@keyframes openNotification {
  0% {
    margin-bottom: -30px;
    opacity: 0;
  }
  100% {
    margin-bottom: 0px;
    opacity: 1;
  }
}

.modal__icon {
  padding-bottom: 24px;
}

.modal__message {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 16px;
  color: #222224;
  max-width: 200px;
  text-align: center;
}

.modal__text {
  padding-top: 16px;
  font-size: 10px;
  opacity: 0.6;
  max-width: 200px;
  text-align: center;
}

.closebutton__modal {
  position: relative;
  width: 100%;
  text-align: right;
  padding-right: 18px;
  padding-top: 15px;
}

.closebutton__modal:hover {
  cursor: pointer;
}
