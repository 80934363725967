.assessmentlink__container {
  width: 377px;
  border: 1px solid lightgray;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 14px;
  font-family: Poppins;
  background-color: #fff5f5;
  margin-bottom: 20px;
  max-width: 80vw;
}

.assessmentlinkstart__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  width: 100%;
}

.assessmentlink__buttonactive {
  width: 196px;
  height: 40px;
  color: white;
  background-color: #d94645;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 500;
}

.assessmentlink__buttoninactive {
  width: 196px;
  height: 40px;
  color: white;
  background-color: #bfbdbd;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 500;
}

.assessmentlink__buttoninactive:hover {
  /* cursor: not-allowed; */
  color: white;
  /* pointer-events: none; */
}

.assessmentlink__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  height: 100%;
}

.titleanddescription {
}

.assessmentlink__description {
  color: #b3acac;
}

.assessmentlink__time {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.assessmentlink__title {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.assessmentlink__button {
  /* background-color: #eca3a2; */
  padding: 20px;
  color: white;
  border-radius: 10px;
  font-weight: 500;
  text-align: center;
  max-width: 165px;
  margin: 0 auto;
}
