.start-game {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.opencreditmodalbutton {
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 27px 98px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-family: Poppins;
}
