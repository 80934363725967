.income-heading {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.option-1-price5 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
.image-2-icon2 {
  position: relative;
  width: 16px;
  height: 16px;
  object-fit: cover;
}
.x32 {
  position: relative;
  font-weight: 500;
  opacity: 0.5;
}
.image-2-container {
  width: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 10px;
  color: #141414;
}
.lifestyleoption1 {
  border-radius: 15px;
  background-color: #fff;
  border: 1.5px solid #eca3a2;
  box-sizing: border-box;
  height: 152px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-width: 178px;
}
.option-2-heading {
  position: relative;
  line-height: 24px;
  text-align: center;
}
.option-2-currency {
  position: relative;
  line-height: 36px;
  font-weight: 600;
}
.option-2-price {
  position: relative;
  font-size: 24px;
  font-weight: 600;
}
.option-2-price-value-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.option-2-price-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 16px;
}
.option-2-container {
  border-radius: 15px;
  background-color: rgba(217, 70, 69, 0.2);
  border: 2px solid #d94645;
  box-sizing: border-box;
  height: 152px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-width: 178px;
}
.income-options-container {
  align-self: stretch;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  padding: 5px;
}
.lifestylechoicescontainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 16px;
  color: #222224;
  font-family: Poppins;
}
