.month-modal {
  /* position: relative; */
  width: 400px;
  height: auto;
  left: 80px;
  top: 12%;
  background-color: white;
  box-shadow: 1px 1px 3px #bfbebe;
  padding: 20px;
  align-content: center;
  animation-name: float-bottom;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.month-modal > button {
  background-color: #3e3e3e;
  color: white;
  padding: 10px;
  font-weight: bold;
  border: none;
  box-shadow: 0px 0px 6px 3px rgb(75 75 75 / 20%);
  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes float-bottom {
  0% {
    margin-bottom: -50px;
  }
  100% {
    margin-bottom: 0px;
  }
}
