.debt-pay-button {
  background-color: #3e3e3e;
  color: white;
  border: none;
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 5px;
  box-shadow: 0 0 4px 0px #605e5e;
  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.debt-pay-instructions {
}

.pay-button {
  animation-name: investingModal;
  animation-duration: 1s;
}

@keyframes payButton {
  0% {
    left: -100px;
  }
  100% {
    left: 0px;
  }
}

.debt-info-container {
  padding: 15px;
  box-shadow: 0 0 4px 0.5px #c9c9c9;

  position: absolute;
  top: 20%;
  right: 20%;
  bottom: 20%;
  left: 20%;
  background-color: white;
}

.not-enough-paid-warning {
  background-color: red;
  color: white;
  padding: 15px;
  font-weight: bold;
  margin-top: 20px;
  border-radius: 15px;
}
