.certificatecards__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
  width: 100%;
}

.completioncertificates__container {
  padding-top: 40px;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.completioncertificates__title {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 32px;
}

.completioncertificates__title > span {
  margin-left: 15px;
}

.nocertificates__container {
  font-family: Poppins;
  font-weight: 300;
  font-size: 10px;
  opacity: 0.6;
  margin-top: 23px;
  margin-left: 40px;
}

.certificate__container {
  width: 200px;
  /* height: 200px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px;
  padding-top: 4px;
  margin-left: 40px;
}
.certificate__lessonimage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}
.certificate__lessonname {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 16px;
  color: #222224;
  padding-left: 12px;
  margin-top: 14px;
}
.certificate__descriptionanddownload {
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  padding-right: 12px;
}
.certificate__lessondescription {
  font-family: Poppins;
  font-weight: 300;
  font-size: 8px;
  opacity: 0.6;
}
.certificate__download {
  font-family: Poppins;
  font-weight: 300;
  font-size: 10px;
  display: flex;
  padding-top: 10px;
  align-items: center;
  justify-content: flex-end;
}

.certificate__download > span {
  padding-right: 5px;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
}

.certificate__download:hover {
  color: blue;
  cursor: pointer;
}

.certificates__container {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  max-width: 600px;
}

@media only screen and (max-width: 1290px) {
  .completioncertificates__container {
    padding-top: 100px;
  }
}

.badge-certificate {
  background: #d94645;
  display: inline-block;
  height: 55px;
  position: relative;
  text-align: center;
  width: 100px;
}

.badge-certificate-incomplete {
  background: #d9464570;
  display: inline-block;
  height: 55px;
  position: relative;
  text-align: center;
  width: 100px;
}

.badge-certificate:before {
  border-top: 35px solid #d94645;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  bottom: -35px;
  width: 0;
}

.badge-certificate-incomplete:before {
  border-top: 35px solid #d9464570;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  bottom: -35px;
  width: 0;
}

/* Locofy */

.completion-certificates {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  font-weight: 600;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-top: 24px;
}
.vector-icon,
.vector-icon1 {
  position: absolute;
  height: 42.58%;
  width: 80.85%;
  top: 55.29%;
  right: 9.58%;
  bottom: 2.13%;
  left: 9.57%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon1 {
  height: 100%;
  width: 85.11%;
  top: 0;
  right: 7.44%;
  bottom: 0;
  left: 7.44%;
}
.completion-certificates-percentage,
.know-your-rights {
  position: relative;
  width: 100%;
  overflow: hidden;
  line-height: 22px;
  text-overflow: ellipsis;
}
.completion-certificates-percentage {
  font-size: var(--desktop-link-medium-size);
  line-height: 28px;
  font-weight: 600;
  color: var(--color-brown);
  text-align: center;
  margin-top: 10px;
}
.know-your-rights-parent {
  position: absolute;
  height: 34.74%;
  width: 61.05%;
  top: 37.92%;
  right: 19.47%;
  bottom: 27.35%;
  left: 19.47%;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frame {
  position: absolute;
  height: 95%;
  width: 95%;
  top: 2.5%;
  right: 2.5%;
  bottom: 2.5%;
  left: 2.5%;
  overflow: hidden;
}
.badge0,
.badge1 {
  position: relative;
  width: 200px;
  height: 200px;
  flex-shrink: 0;
  overflow: hidden;
  z-index: 4;
}
.badge1 {
  cursor: pointer;
  opacity: 0.5;
  z-index: 3;
}
.vector-icon2,
.vector-icon3 {
  position: absolute;
  height: 42.58%;
  width: 80.85%;
  top: 55.29%;
  right: 9.57%;
  bottom: 2.13%;
  left: 9.58%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon3 {
  height: 100%;
  width: 85.12%;
  top: 0;
  right: 7.44%;
  bottom: 0;
  left: 7.44%;
}
.badge2,
.badge3,
.badge4 {
  position: relative;
  width: 200px;
  height: 200px;
  flex-shrink: 0;
  overflow: hidden;
  opacity: 0.5;
  z-index: 2;
}
.badge3,
.badge4 {
  z-index: 1;
}
.badge4 {
  z-index: 0;
}
.badgescontainer {
  align-self: stretch;
  display: grid;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
}
.completion-of-program,
.download {
  position: relative;
  font-size: 20px;
  font-weight: bold;
}
.download {
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--desktop-link-medium);
  color: var(--color-white);
  text-align: center;
}
.downloadcertificate_button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 10px;
  border: none;
}
.completionprogram,
.content {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.completionprogram {
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--desktop-link-medium-size);
  color: var(--color-black);
  margin-top: 50px;
}
.content {
  border-radius: var(--br-5xl);
  overflow: hidden;
  padding: var(--padding-5xl) 0;
  gap: var(--gap-21xl);
}
.badgesandcertificatescontainer{
padding-left: 32px;
}

.badgesandcertificatescontainer,
.completioncertificatescontaine {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.badgesandcertificatescontainer {
  align-self: stretch;
  overflow: hidden;
  font-size: var(--desktop-text-extra-small-size);
  color: var(--error-light);
}
.completioncertificatescontaine {
  flex: 1;
  padding: var(--padding-5xl) var(--padding-13xl);
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--desktop-link-medium-size);
  color: var(--color-black);
  font-family: var(--desktop-link-medium);
}


@media only screen and (max-width: 1200px) {
  .completion-certificates{
    padding-left: 12px;
  }
}