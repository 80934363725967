.lsiAssessment__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Poppins;
}

.LSI-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 30px;
  padding-top: 30px;
  font-family: Poppins;
}

.container {
  font-family: "Montserrat";
  font-size: 13px;
}

.lsi__input {
  width: 100%;
  height: 52px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 16px;
  margin: 4px 0px;
  padding-left: 24px;
}

.select-ant-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-options {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ant-options > select {
  width: auto;
  border: 1px solid #d9d9d9;
  background-color: white;
  width: 100%;
  text-align: right;
  height: 30px;
  border-radius: 4px;
  font-size: 12px;
}
.select-ant-container > p {
  padding-right: 20px;
  max-width: 30px;
}

.lsi-container {
  padding-top: 70px;
}

.lsi-container > h1 {
  text-align: center;
  color: #5c5c5c;
  padding-top: 30px;
}

.custom-ant-input-container {
  width: 100%;
  display: flex;
}

.custom-ant-input-container > p {
  padding-right: 20px;
  max-width: 30px;
}

.checkboxes-container {
  display: flex;
  /* padding: 10px; */
  justify-content: space-between;
  align-items: flex-start;
  font-family: Poppins;
}

.checkboxes__container {
  width: 50%;
  padding: 10px;
}

.checkboxescontainer__text {
  font-size: 18px;
  font-family: Poppins;
  font-weight: 400;
}

.checkboxes-container > p {
  padding-top: 11px;
}

.checkbox-inputs-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #222224;
  box-sizing: border-box;
  /* border-radius: 0px 0px 16px 16px; */
  padding: 10px;
  margin-right: 0px;
  margin-top: 12px;
  border-radius: 10px;
  width: 100%;
  padding-top: 10px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 16px;
}

.checkbox-inputs-container > p {
  padding-bottom: 10px;
  /* font-weight: bold; */
}

.checkbox-inputs-container > p {
  /* padding-right: 20px;
  max-width: 30px; */
}

.checkbox-inputs-container > label {
  padding: 5px;
  text-align: left;
}

.lsitermscheckbox__container {
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lsitermscheckbox__container > p {
  font-family: Poppins;
  font-weight: 700;
  margin-bottom: 0;
  padding: 10px;
  padding-right: 10px;
}

.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LSI-button {
  background: #d94645;
  border-radius: 40px;
  color: white;
  /* margin: 30px; */
  margin-top: 10px;
  width: 50%;
}

.spinner-class {
  margin: 10px;
}

.signature__container {
  /* background-color: blue; */
  width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}

.lsi-intro-text {
  max-width: 500px;
  font-size: 1.2em;
}

.lsi-final-consent {
  min-width: 400px;
  width: 50%;
  font-size: 1.3em;
  margin-bottom: 20px;
}

.termsandconditions__container {
  background-color: #c5c5c5;
  padding: 10px;
  font-weight: bold;
  display: flex;
  margin-bottom: 20px;
}

.termsandconditions__container > p {
  margin-bottom: 0;
  margin-right: 10px;
}

.lsi__selectoptioncontainer {
  width: 100%;
  height: 52px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 16px;
  margin: 4px 0px;
  padding-left: 24px;
  padding-right: 24px;
}

.inputfield__label {
  margin-bottom: 0;
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 5px;
}

.lsititle__container {
  width: 50%;
  margin: 0 auto;
  padding: 10px;
  font-size: 32px;
  font-weight: bold;
}

.lsiinput__container {
  width: 50%;
  /* background-color: lightgrey; */
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputfields__container {
  width: 100%;
}

.inputfields__container > label {
  margin-bottom: 0;
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
}

.lsiinput__container > label {
  padding-bottom: 0;
  margin-bottom: 0;
  padding-left: 10px;
}

.inputnumber__container {
  /* font-family: Poppins; font-weight: 700; */
  font-size: 12px;
}

.lsioption {
  opacity: 0.6;
}

.termsAndConditionsAccepted {
  text-align: center;
  font-style: 28px;
  font-family: Poppins;
  font-weight: 700;
  font-size: 28px;
}

@media only screen and (max-width: 995px) {
  .LSI-form-container > input {
    margin: 10px;
    border: none;
    width: 60%;
    box-shadow: 0px 2px 5px 0.5px #c5c5c5;
    padding: 10px;
    border-radius: 10px;
  }
  .checkbox-inputs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    /* margin: 10px; */
    border-radius: 10px;
    border: 1px solid #d9d9d9;
  }
  .custom-ant-input-container {
    width: 100%;
  }

  .select-ant-container {
    width: 100%;
  }

  .checkboxes-container {
    width: 100%;
    max-width: 450px;
  }
}

@media only screen and (max-width: 995px) {
  .LSI-form-container > input {
    margin: 10px;
    border: none;
    width: 60%;
    box-shadow: 0px 2px 5px 0.5px #c5c5c5;
    padding: 10px;
    border-radius: 10px;
  }
  .checkbox-inputs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    /* margin: 10px; */
    border-radius: 10px;
    border: 1px solid #d9d9d9;
  }
  .custom-ant-input-container {
    width: 100%;
  }

  .checkboxes__container {
    width: 100%;
  }

  .select-ant-container {
    width: 100%;
  }

  .checkboxes-container {
    width: 100%;
    max-width: 450px;
  }

  .lsiinput__container {
    width: 100%;
  }
  .lsititle__container {
    width: 100%;
    font-size: 32px;
    font-weight: bold;
    
  }
}
