.finish-modal {
  position: absolute;
  display: flex;
  justify-content: center;
  margin-top: 10vh;
  width: 400px;
  height: 400px;
  background-color: white;
  z-index: 10;
  box-shadow: 1px 1px 15px lightgrey;
  align-items: center;
  animation-name: finishModal;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
}

.modal-content {
  margin: 10px;
  display: flex;
  border: none;
  padding: 20px;
}

.use-spreadsheet {
  text-align: center;
}

@keyframes finishModal {
  0% {
    top: 200px;
  }
  100% {
    top: 0px;
  }
}
