.mentortermsandconditions__container {
  /* modal */
  position: fixed;
  top: 30px;
  left: 80px;
  width: 90%;
  height: 90%;
  /* background: rgba(0, 0, 0, 0.5); */
  background: white;
  z-index: 9999;
  /* display: flex; */
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  /* modal */
  /* modal */
}

.mentortermsandconditionsmodal__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;

  font-family: Poppins;
  font-weight: 400;
}



.mentortermsandconditionsmodal__content {
  font-family: Poppins;
  font-weight: 400;
  background-color: white;
  padding: 20px;
  width: 932px;
  border-radius: 15px;
  max-width: 99vw;
  max-height: 95vh;
  overflow: scroll;
}

.mentortermsandconditionsacceptterms__container {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.mentortermsandconditionsmodalsubmit__button {
  width: 200px;
  height: 52px;
  border-radius: 30px;
  border: none;
  background-color: #d94645;
  color: white;
  font-weight: bold;
  font-family: Poppins;
  font-weight: 400;
}
.mentortermsandconditionsmodalsubmit__button:hover {
  cursor: pointer;
  opacity: 0.8;
}
.mentortermsandconditionsmodalsubmit__button:disabled {
  opacity: 0.6;
}

.mentortermsandconditionsmodal__termsbox {
  height: 150px;
  background-color: #f5f5f5;
  padding-top: 5px;
  font-size: 12px;
  overflow: scroll;
  padding-left: 7px;
  padding-bottom: 8px;
  margin-bottom: 10px;
  max-height: 95v;
}


.termsandconditions__container {
  background-color: #c5c5c5;
  padding: 10px;
  font-weight: bold;
  display: flex;
  margin-bottom: 20px;
}

.mentorcontent__container {
  padding-top: 20px;
}

.termsandconditions__container > p {
  margin-bottom: 0;
  margin-right: 10px;
}

.mentor-student-table {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mentortable__row {
  padding: 16px;
}

.mentortabletags__container {
  background-color: #fff8f8;
}

.mentortabletags__container > th {
  font-family: Poppins;
  font-weight: 400;
  color: #bb4141;
  padding-top: 23px;
  padding-bottom: 11px;
  padding-left: 16px;
}

.mentortable__row > td {
  padding-top: 17px;
  padding-bottom: 11px;
  padding-left: 16px;
  border-bottom: 0.5px solid rgb(233, 233, 233);
  font-family: Poppins;
  font-weight: 400;
}

.mentortableoptions__button {
  font-weight: bold;
  font-size: 18px;
}

.mentortableoptions__button:hover {
  cursor: pointer;
}

.mentorcreateevent__modal {
  position: absolute;
  margin-right: 40px;
  font-size: 12px;
  padding: 10px;
  border: 1px solid rgb(238, 238, 238);
  background-color: white;
}

.mentorcreateevent__modal:hover {
  background-color: rgb(237, 237, 237);
}

.mentorprivateeventinfo__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.mentorprivateevent__title {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  color: #222224;
}

.mentorprivateevent_input {
}


.createprivateevent__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.createprivateevent__modal {
  /* modal with grey background  */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
}
.mentorprivateeventinfo__header {
  margin-bottom: 16px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
}

.mentorprivateeventinfo__header > span {
  font-family: Poppins;
  font-weight: 700;
}

.createprivateevent__container {
  width: 600px;
  /* height: 500px; */
  background-color: white;
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  border-radius: 15px;
  padding: 16px;
}

.mentorprivateevent_input > input {
  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 10px 24px;
  min-width: 400px;
  font-family: Poppins;
  font-weight: 300;
}
.mentorprivateevent_inputdate > input {
  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 10px 24px;
  min-width: 50px;
  font-family: Poppins;
  font-weight: 300;
  margin-left: 10px;
}

.mentorprivateevent_input > select {
  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 10px 24px;
  min-width: 300px;
}

.mentorprivateeventbuttons__container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.mentorprivateeventbuttons__container > button {
  padding: 10px 14px;
  width: 200px;
  height: 52px;
  font-family: Poppins;
  font-weight: 400;
  border-radius: 40px;
  background-color: white;
  color: #d94645;
  font-size: 16px;
  border-color: #d94645;
  border: 2px solid #d94645;
}

.mentorprivateeventbuttons__container > button:nth-child(2) {
  background-color: #d94645;
  color: white;
}
.mentorprivateeventbuttons__container > button:nth-child(2):disabled {
  background-color: #d94645;
  opacity: 0.6;
  color: white;
}

.nostudents__container {
  padding: 16px;
  /* text-align: center; */
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  /* width: 100%; */
  padding-left: 20px;
}
