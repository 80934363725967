.lesson-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  max-height: 100%;
  /* position: absolute; */
  /* margin-left: 80px; */
}

.container-fluid {
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
}

.toggle-user-admin-view {
  position: fixed;
  z-index: 1001;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  top: 8px;
  font-size: 10px;
  padding: 10px 20px 10px 20px;
  background: #da2424;
  color: white;
  border: none;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.navigation-container {
  font-family: Poppins;
  background-color: #b72928;
  color: white;
  left: 80px;
  position: fixed;
  height: 100%;
  top: 0px;
  z-index: 99;
  overflow: auto;
  padding-bottom: 60px;
  margin-bottom: 20px;

  box-shadow: 2px 0px 4px #8d8a8a;
  animation-name: navigationShow;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes navigationShow {
  0% {
    left: 0px;
    opacity: 0;
  }
  100% {
    left: 80px;
    opacity: 1;
  }
}

.display-lesson-container-admin {
  margin-left: 380px;
  width: 75%;
  font-family: Poppins;
  /* margin-top: 10px; */
  padding-left: 80px;

  /* overflow: auto; */
}

.current-section-container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.animate {
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
    left: 480px;
  }
  to {
    opacity: 1;
    left: 20px;
  }
}

.current-section-container > p {
  width: 50%;
}

.display-lesson-container-user {
  width: 100%;
  /* margin-top: 40px; */
  /* padding-top: 40px; */
  font-family: Poppins;
}

.navigation-container > h4 {
  margin-top: 40px;

  color: white;
  padding: 10px;
}

.buttons-container {
  margin-top: 40px;
  animation-name: buttons;
  animation-duration: 1s;
  z-index: 20;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  flex-direction: row;
  justify-content: space-between;
  max-width: 600px;
  padding-bottom: 60px;
}

/* .buttons-container > button {
  color: white;
  background-color: #1990ff;
  border-radius: 20px;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: 10px;
  outline: none;
  border: none;
} */

* {
  outline: none;
}

.buttons-container > button:hover {
  background-color: rgb(218, 49, 74);
  color: white;
  outline: none;
  border: none;
}

.admin-options {
  margin: 20px;
  padding-top: 50px;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 12px;
}

.section-nav {
  font-family: Poppins;
  background-color: white;
  color: #222224;
  padding: 10px;
  padding-left: 12px;
  box-shadow: 0 0 0.5px 0.5px #bebebe;
  width: 100%;
  max-width: 374px;
  display: flex;
  /* animation-name: navitemappear;
  animation-duration: 0.5s;
  animation-fill-mode: forwards; */
}

@keyframes navitemappear {
  0% {
    margin-left: 30px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

.section-nav:hover {
  cursor: pointer;
}

.section-nav > span {
  display: flex;
  /* max-width: 200px; */
}

.selected-section,
.unselected-section {
  display: flex;
  font-weight: bold;
  font-family: Poppins;
  font-weight: 600;
  gap: 4px;
}
.sectionname__text {
  max-width: 220px;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 10000ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 10000ms;
}

@media only screen and (max-width: 1290px) {
  .navigation-container {
    left: 0px;
  }

  @keyframes navigationShow {
    0% {
      left: 0px;
      opacity: 0;
    }
    100% {
      left: 0px;
      opacity: 1;
    }
  }

  .display-lesson-container {
    width: 100%;

    margin-left: 0px;
  }

  .display-lesson-container-admin {
    width: 100%;
    /* margin-top: 40px; */
    padding-left: 0px;
  }

  .display-lesson-container-user {
    padding-left: 0px;
  }
  .current-section-container {
  }
}
