.gamestartbutton-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  border-radius: 40px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.picklifestyletitle {
  align-self: flex-start;
}

.button1 {
  position: absolute;
  height: 42.86%;
  width: 90.4%;
  top: 27.14%;
  left: 4.8%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Poppins;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gamestartbutton {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  position: absolute;
  margin: 0 !important;
  right: 82px;
  bottom: 50px;

  width: 200px;
  height: 52px;
  z-index: 0;
}
.lifestyleoptionstitle {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.option-1-price3 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
.image-2-icon1 {
  position: relative;
  /* width: 16px;
  height: 16px; */
  object-fit: cover;
}
.x31 {
  position: relative;
  font-weight: 500;
  opacity: 0.5;
}
.happy-points-container {
  width: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 16px;
  color: #141414;
}
.lifestyleoption {
  border-radius: 15px;
  background-color: #fff;
  border: 1.5px solid #eca3a2;
  box-sizing: border-box;
  height: 152px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-width: 178px;
  max-width: 100%;
}
.option-2-heading {
  position: relative;
  line-height: 24px;
  text-align: center;
}
.option-2-currency {
  position: relative;
  line-height: 36px;
  font-weight: 600;
}
.option-2-price {
  position: relative;
  font-size: 24px;
  font-weight: 600;
}
.option-2-price-value-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.option-2-price-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 16px;
}
.option-2-container {
  border-radius: 15px;
  background-color: rgba(217, 70, 69, 0.2);
  border: 2px solid #d94645;
  box-sizing: border-box;
  height: 152px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-width: 178px;
}
.income-options-container {
  align-self: stretch;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
}
.lifestylechoicescontainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.budgetinggame-optionscontainer {
  align-self: stretch;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  font-size: 16px;
}
.chooselifestylecol {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  z-index: 1;
  max-height: 60vh;
}
.welcome-back-talha {
  align-self: stretch;
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
}
.income {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  width: 263px;
}
.intern-in-startup {
  position: relative;
  line-height: 22px;
  max-width: 150px;
}
.salary-div {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #d94645;
}
.intern-in-startup-0-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 14px;
  color: rgba(34, 34, 36, 0.5);
}
.income-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.frame-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.lifestyleexpensestable {
  border-radius: 16px;
  background-color: #fff8f8;
  box-shadow: 0px 3px 5px rgba(175, 175, 175, 0.12);
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 52px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  z-index: 2;
  font-size: 16px;
}
.lifestylecontainer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  padding: 40px 120px 0px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  gap: 100px;
  z-index: 1;
}
.budgetinggamepicklifestyle {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 50px;

  max-width: 1377px;

  text-align: left;
  font-size: 32px;
  color: #222224;
  font-family: Poppins;
}

@media only screen and (max-width: 436px) {
  .budgetinggame-optionscontainer {
    /* overflow-y: none; */
  }
}

@media screen and (max-width: 120px) {
}

@media screen and (max-width: 960px) {
  .chooselifestylecol {
    flex: unset;
    align-self: stretch;
  }

  .lifestylecontainer {
    flex-direction: column;
    padding: 0px;
  }
}
