.button__container {
  font-family: Poppins;
  font-weight: 400;
  min-width: 200px;
  min-height: 52px;
  border-radius: 30px;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.button__container:hover {
  cursor: pointer;
  opacity: 0.8;
}
@keyframes subtleClick {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.button__animate {
  animation: subtleClick 0.2s;
}

.button__container:disabled:hover {
  opacity: 1;
}

.button__animate {
  /*  opacity: 0; /* Start with an opacity of 0 so it fades in */
  /*  animation: slideIn 0.3s forwards; Animation name and duration */
  /* animation-delay: 0.3s; Delay the animation half a second */
}

@keyframes slideIn {
  from {
    /* transform: translateX(-200px); Start off-screen to the left */
    top: -200px;
    opacity: 0;
  }
  to {
    /* transform: translateX(0); End at the original position */
    top: 0px;
    opacity: 1;
  }
}
