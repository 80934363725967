.devicesjoystick-icon1 {
  position: relative;
  width: 22px;
  height: 22px;
  object-fit: cover;
}
.fun1 {
  position: relative;
  line-height: 22px;
  font-weight: 500;
}
.devicesjoystick-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.money5 {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #f27117;
  width: 216px;
  height: 32px;
  overflow: hidden;
  opacity: 0.8;
}
.div25 {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 20px);
  line-height: 24px;
  font-weight: 500;
}
.money4 {
  position: absolute;
  top: 11.5px;
  left: calc(50% - 178.5px);
  border-radius: 15px;
  background-color: rgba(242, 113, 23, 0.3);
  width: 357px;
  height: 32px;
  overflow: hidden;
}
.group-child {
  position: absolute;
  top: -0.75px;
  left: 294.93px;
  width: 1.5px;
  height: 56.5px;
  object-fit: cover;
  opacity: 0.5;
}
.div26 {
  position: absolute;
  top: 55px;
  left: 267.7px;
  line-height: 24px;
  font-weight: 500;
  color: #000;
  display: inline-block;
  width: 53.48px;
  opacity: 0.5;
}
.money-group {
  align-self: stretch;
  position: relative;
  height: 79px;
  color: #fff;
}
.categoryspendingcontainer {
  align-self: stretch;
  border-radius: 15px;
  background-color: rgba(242, 113, 23, 0.08);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.interface-essentialhome-group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.money7 {
  position: absolute;
  top: 0.5px;
  left: 0.5px;
  background-color: #2258e3;
  width: 158px;
  height: 31px;
  overflow: hidden;
  opacity: 0.8;
}
.money6 {
  position: absolute;
  top: 11.5px;
  left: calc(50% - 178.5px);
  border-radius: 15px;
  background-color: rgba(34, 88, 227, 0.3);
  width: 357px;
  height: 32px;
  overflow: hidden;
}
.div28 {
  position: absolute;
  top: 55px;
  left: 265.13px;
  line-height: 24px;
  font-weight: 500;
  color: #000;
  display: inline-block;
  width: 60.17px;
  opacity: 0.5;
}
.categoryspendingcontainer1 {
  align-self: stretch;
  border-radius: 15px;
  background-color: rgba(34, 88, 227, 0.08);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #2258e3;
}
.bus-icon1 {
  position: relative;
  width: 22px;
  height: 22px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.money9 {
  position: absolute;
  top: 0.5px;
  left: 0.5px;
  background-color: #0db487;
  width: 99px;
  height: 31px;
  overflow: hidden;
  opacity: 0.8;
}
.div29 {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 19px);
  line-height: 24px;
  font-weight: 500;
}
.money8 {
  position: absolute;
  top: 11.5px;
  left: calc(50% - 178.5px);
  border-radius: 15px;
  background-color: rgba(13, 180, 135, 0.3);
  width: 357px;
  height: 32px;
  overflow: hidden;
}
.div30 {
  position: absolute;
  top: 55px;
  left: 270.27px;
  line-height: 24px;
  font-weight: 500;
  color: #000;
  display: inline-block;
  width: 48.13px;
  opacity: 0.5;
}
.categoryspendingcontainer2 {
  align-self: stretch;
  border-radius: 15px;
  background-color: rgba(0, 186, 136, 0.08);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #0db487;
}
.money11 {
  position: absolute;
  top: 0.5px;
  left: 0.5px;
  background-color: #ff6250;
  width: 99px;
  height: 31px;
  overflow: hidden;
  opacity: 0.8;
}
.money10 {
  position: absolute;
  top: 11.5px;
  left: calc(50% - 178.5px);
  border-radius: 15px;
  background-color: rgba(255, 98, 80, 0.3);
  width: 357px;
  height: 32px;
  overflow: hidden;
}
.categoryspendingcontainer3 {
  align-self: stretch;
  border-radius: 15px;
  background-color: rgba(255, 98, 80, 0.08);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #ff6250;
}
.total-budget-overview {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.money13 {
  position: absolute;
  top: 0.5px;
  left: 1px;
  background-color: #fff;
  width: 213px;
  height: 31px;
  overflow: hidden;
}
.div33 {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 22px);
  line-height: 24px;
  font-weight: 500;
}
.money12 {
  position: absolute;
  top: 11.5px;
  left: calc(50% - 178.5px);
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.3);
  width: 357px;
  height: 32px;
  overflow: hidden;
}
.group-child1 {
  position: absolute;
  top: -0.75px;
  left: 294.93px;
  width: 1.5px;
  height: 56.5px;
  object-fit: cover;
}
.div34 {
  position: absolute;
  top: 55px;
  left: 265.13px;
  line-height: 24px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  width: 60.17px;
}
.money-parent2 {
  align-self: stretch;
  position: relative;
  height: 79px;
  color: #3a3a3a;
}
.categoryspendingcontainer4 {
  align-self: stretch;
  border-radius: 15px;
  background-color: #3a3a3a;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #fff;
}
.spendingchartscontainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #fb7c23;
  font-family: Poppins;
}
