.task-add-the-container {
  align-self: stretch;
  position: relative;
}
.credittask {
  position: relative;
  border-radius: 8px;
  background-color: #f9f9fa;
  border: 1px solid rgba(84, 84, 84, 0.1);
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: 14px;
  color: #161616;
  font-family: Poppins;
}
