.module__container {
  width: 236px;
  height: 344px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-right: 30px;
  margin-bottom: 35px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  /* align-items: center; */
}

.module__image {
  width: 100%;
  height: 126px;

  margin-top: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  /* background-color: grey; */
}

.module__image > img {
  max-height: 135px;
}

.module__title {
  font-family: Poppins;
  /* font-weight: 400; */
  font-weight: bold;
  font-size: 12px;
  color: #222224;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 16px;
  height: 55px;
}

.module__description {
  font-family: Poppins;
  font-size: 9px;
  letter-spacing: 0px;
  color: #222224;
  opacity: 0.6;
  padding-left: 16px;
  /* padding-bottom: 12px; */
  min-height: 40px;
  max-width: 80%;
}

.module__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.moduleinfo__left {
  padding-left: 16px;
  font: normal normal normal 10px/17px Poppins;
  letter-spacing: 0px;
  color: #222224;
  opacity: 0.6;
  display: flex;
  align-items: center;
}

.moduleinfo__left > p {
  margin-bottom: 0px;
  padding-left: 8px;
}

.moduleinfo__right {
  font-style: 10px;
  font-family: Poppins;
  /* font-weight: 500; */
  font-size: 10px;
  opacity: 0.6;
  padding-right: 16px;
}

.moduleinforight__duedate {
  text-align: left;
  font: Poppins;
  letter-spacing: 0px;
  color: #ff6565;

  font-size: 10px;
}

.module__progress {
  padding: 16px;
}

.module__status {
  margin-left: 16px;
  margin-right: 16px;
}

.modulestatus__button {
  display: flex;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  background: #d94645 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  color: white;
  padding: 8px;
  padding-bottom: 8px;
  font-size: 10px;
}

.moduledone__button {
  display: flex;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  background: #4dde45 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  color: white;
  padding: 8px;
  padding-bottom: 8px;
  font-size: 10px;
}

.dashboardadmin__options {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.modulestatus__buttonadminstart {
  display: flex;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  background: #d94645 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  color: white;
  margin-top: 3px;
  padding: 2px;
  font-size: 8px;
  margin-bottom: 3px;
  width: 40%;
}

.modulestatus__buttoneditstart {
  display: flex;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  background: #44e649 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  color: black;
  margin-top: 3px;
  padding: 2px;
  font-size: 8px;
  margin-bottom: 3px;
  width: 40%;
  align-self: center;
}

.module__complete {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 12px;
  color: #ff6565;
  text-align: center;
}

.module__locked {
  width: 203px;
  height: 31px;
  background: #ededed 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.module__locked > span {
  margin-left: 7px;
  color: #8d8d8d;
}

@media only screen and (max-width: 1290px) {
  .module__container {
    margin-right: 15px;
  }
}
