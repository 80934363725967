.likert > textarea {
  min-width: 300px;
}

.likertedit__container {
}

.likertrangeinputs__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  color: #d94645;
  /* font-family: Poppins; font-weight: 400; font-weight: 600; */
}

.likertrangeinput {
  font-family: Poppins;
  font-weight: 400;
  width: 310px;
  height: 52px;
  font-size: 16px;

  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 16px;
  padding-left: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}
