.currentchatselected__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  /* height: 100%; */
  max-height: 93vh;
}

.chatmessage__user {
  background-color: #d94645;
  width: 200px;
  align-self: flex-end;
  margin-right: 20px;
  border-radius: 20px;
  padding: 10px;
  color: white;
  font-weight: 500;
}

.classchat__image {
  width: 300px;
  height: auto;
  background-color: lightgray;
  border-radius: 15px;
}

.chatopen__inputcontainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-left: 10px;

  padding-top: 20px;
  padding-right: 10px;
}

.chatopen__inputcontainer > input {
  width: 100%;
  border: none;
  font-weight: 400;
  font-family: Poppins;
  font-size: 16px;
}

.chatmessage__contact {
  background-color: #ffebeb;
  min-width: 200px;
  align-self: flex-start;
  margin-left: 20px;
  border-radius: 20px;
  padding: 10px;
  font-weight: 500;
}

.classroomsinglechat__title {
  background-color: white;
  position: sticky;
  font-size: 16px;
  font-weight: bold;
  padding: 16px;
  border-bottom: 1px solid #ffebeb;
  text-align: center;
}

.chatroomchatmessages__container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: scroll;
  flex: 1;
}
