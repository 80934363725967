.usernav__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff5f5;
  font-family: Poppins;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px;
  padding-right: 20px;
  position: sticky;
  z-index: 100;
  top: 0px;
  margin-bottom: 24px;
}

.usernav__profile {
  display: flex;
  align-items: center;
  gap: 8px;
}

.usernav__settings-icon {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.usernav__image-icon,
.usernav__settings-icon,
.usernav__notifications-icon {
  margin-right: 10px;
}

.userinfo__container {
  gap: 4px;
}

.usernav__name {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
}

.usernav__role {
  color: black;
  opacity: 0.4;
  font-size: 16px;
}

.usernav__class-id {
  color: #666;
  color: black;
  opacity: 0.4;
  font-size: 16px;
}
