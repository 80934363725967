.link__container {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  font-size: 8px;
  font-family: Poppins;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  text-align: center;
  margin-bottom: 16px;
}

.link__container > p {
  margin-bottom: 0px;
}

.link__container:hover {
  background: #d94645 0% 0% no-repeat padding-box;
}

@media only screen and (max-width: 1290px) {
  .link__container {
    margin-bottom: 0px;
    margin-right: 0px;
    padding-right: 0px;
    width: 100%;
  }
}
