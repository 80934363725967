.goal-modal {
  position: absolute;
  left: 30%;
  display: flex;
  justify-content: center;
  margin-top: 10vh;
  width: 400px;
  height: 400px;
  background-color: white;
  z-index: 10;
  box-shadow: 1px 1px 15px lightgrey;
  align-items: center;
  /* animation-name: introModal;
    animation-duration: 0.5s;
    animation-delay: 3s; */
}

.start-goal-modal {
  background-color: #3e3e3e;
  color: white;
  padding: 10px;
  border: none;
  box-shadow: 1px 1px 15px lightgrey;
  border-radius: 5px;
}

.start-goal-modal:hover{
    background-color: #7e7777;
}

.modal-contents {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  margin: 10px;
}

.warning-message {
  color: red;
  font-weight: bold;
}

/* @keyframes introModal {
    0%   { top:200px;}
    100%  { top:0px;}
  }
 */
