.time-modal {
  position: absolute;
  left: 30%;
  display: flex;
  justify-content: center;
  margin-top: 10vh;
  width: 500px;
  height: 500px;
  background-color: white;
  z-index: 10;
  box-shadow: 1px 1px 15px lightgrey;
  align-items: flex-start;
  padding: 40px;
  z-index: 1000;
  flex-direction: column;
  animation-name: slide-bottom;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes slide-bottom {
  0% {
    left: 20%;
  }
  100% {
    left: 30%;
  }
}

.time-modal > h3 {
  margin-bottom: 30px;
}

.time-option {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin: 1px;
  /* background: #5eb5ef; */
  width: 100%;
  height: 50px;
  margin-bottom: 5px;
}

.time-option > h4 {
  /* text-align: left; */
  width: 100%;
  color: black;
  margin-bottom: 0px;
  /* font-weight: bold; */
  font-size: 1.5em;
  text-align: left;
  /* padding-left: 10px; */
}

.time-option > button {
  /* text-align: left; */
  width: 40%;
  height: 100%;
  margin: 0px;
  border-radius: 0px;
  font-weight: bold;
}

.time-title {
  text-align: center;
  margin-top: 20px;
}

.floating {
  float: right;
}

.lock.fa {
  padding: 0px;
  border: none;
  box-shadow: none;
}

.win-investing-instructions {
  font-size: 1.2em;
  background-color: antiquewhite;
  padding: 20px;
  font-weight: bold;
}

.select-time-investing-button {
  border: none;
  background-color: #3e3e3e;
  padding: 10px;
  color: white;
  border-radius: 15px;
}

.select-time-investing-button:hover {
  background-color: black;
}
