.multi-classroom-select {
  font-family: Arial, sans-serif;
  font-size: 14px;
  position: relative;
  /* max-width: 300px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

label {
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
}

.dropdown {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 100%;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
}

.tag {
  background-color: #f1f1f1;
  padding: 4px 8px;
  border-radius: 12px;
  margin-right: 4px;
}

.arrow {
  margin-left: auto;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.classroom__label {
  text-align: left;
}

.dropdown-menu {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 10;
  padding: 8px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-input {
  width: calc(100% - 16px);
  padding: 8px;
  margin: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 8px;
}

input[type="checkbox"] {
  margin-right: 8px;
}

/* Floating Apply Button */
.apply-button {
  position: sticky;
  bottom: -10px;
  width: 100%;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 0 0 8px 8px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
}

.apply-button:hover {
  background-color: #0056b3;
}
