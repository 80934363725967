.wrapper {
  padding: 50px;
}

.toggle-component {
  font-size: 2rem;
  color: #171717;
  width: 50px;
  height: 20px;
  background: #777777;
  border: 1px solid #555;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  transition: border-color background-color 0.3s;
  margin: 10px;
}
.toggle-component.active {
  background: #D94645;
  border-color: #dfdfdf;
}
.toggle-component.active .toggle-button {
  right: -2px;
}
.toggle-component .toggle-button {
  position: absolute;
  top: -2px;
  right: 60%;
  height: 22px;
  width: 22px;
  background: #dfdfdf;
  box-shadow: 0 0 5px #555;
  border-radius: 50%;
  transition: right 0.3s;
}
