.finish-month-modal-buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.month-name{
  margin-bottom: 0px;
}

.use-spreadsheet {
  display: flex;
  justify-content: center;
  align-items: center;
}
