.eq-assessment {
  align-self: stretch;
  position: relative;
  font-size: 32px;
  line-height: 36px;
  font-family: Montserrat;
}
.explore-your-emotional {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 24px;
}

.pretest_score_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.pretest_score_title {
  margin-right: 10px;
  width: 40px;
}

.competency_score {
  margin-left: 10px;
  font-weight: bold;
  min-width: 40px;
}

.pre-test {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.frame-item {
  align-self: stretch;
  position: relative;
  border-top: 1px solid #222224;
  box-sizing: border-box;
  height: 1px;
}
.pre-test-parent {
  flex: 1;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.post-test {
  position: relative;
  line-height: 28px;
}
.frame-inner {
  align-self: stretch;
  position: relative;
  border-top: 1px solid #b7b7b7;
  box-sizing: border-box;
  height: 1px;
}
.frame-group,
.post-test-parent {
  display: flex;
  justify-content: flex-start;
}
.post-test-parent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  color: #b7b7b7;
}
.frame-group {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
}
.self-awareness {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.frame-icon {
  position: relative;
  width: 272px;
  height: 144px;
  overflow: hidden;
  flex-shrink: 0;
}
.lorem-ipsum-dolor1 {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.modal-header1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frame-container,
.notification1 {
  display: flex;
  justify-content: center;
}
.notification1 {
  flex: 1;
  border-radius: 8px;
  flex-direction: column;
  padding: 8px 16px;
  align-items: center;
}
.frame-container {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 24px;
  font-size: 16px;
}
.self-awareness-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.leaving-a-legacy {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  /* width: 200px; */
  width: 100%;
}
.frame-div {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-color: #00ba88;
  width: 256px;
  height: 20px;
  overflow: hidden;
}
.competency-inner {
  background-color: rgba(0, 186, 136, 0.2);
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.competency-inner,
.connecting-your-daily {
  align-self: stretch;
  position: relative;
  margin-right: 24px;
}
.competency {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  min-width: 312px;
  max-width: 360px;
}
.frame-child1,
.frame-child2 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00ba88;
  width: 193px;
  height: 20px;
  overflow: hidden;
}
.frame-child2 {
  background-color: #d94645;
  width: 164px;
}
.competency-inner1 {
  align-self: stretch;
  position: relative;
  background-color: rgba(217, 70, 69, 0.2);
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.competency-parent {
  align-self: stretch;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  font-size: 14px;
  color: #000;
}
.buttonsecondaryinitial-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 40px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.showmorebutton {
  position: absolute;
  width: 90.4%;
  top: 18.27%;
  left: 4.8%;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  font-family: Poppins;
  color: #000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonsecondaryinitial2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.button4 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 132px;
  height: 40px;
}
.resultareacontainer1,
.resultareacontainer2 {
  align-self: stretch;
  border-radius: 16px;
  background-color: rgba(0, 186, 136, 0.05);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  margin-bottom: 32px;
}
.resultareacontainer2 {
  background-color: rgba(217, 70, 69, 0.05);
  gap: 8px;
}
.interpreting-your-scores {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 28px;
  font-weight: 600;
  font-family: inherit;
}
.each-profile-in {
  margin-bottom: 0;
}
.each-profile-in-the-graph-repr {
  margin: 0;
  padding-left: 21px;
}
.each-profile-in-container,
.understanding-your-emotional {
  margin: 0;
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.each-profile-in-container {
  font-size: inherit;
  font-family: inherit;
}
.understanding-your-emotional {
  font-size: 16px;
}
.button9 {
  position: absolute;
  height: 42.86%;
  width: 90.4%;
  top: 27.14%;
  left: 4.8%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Poppins;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button8 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 200px;
  height: 52px;
  display: none;
}
.resultscontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  padding-top: 20px;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  text-align: left;
  font-size: 18px;
  color: #222224;
  font-family: Poppins;
}
@media screen and (max-width: 960px) {
  .resultscontainer {
    flex: 1;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }
}
