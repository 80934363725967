.financelesson__container {
  width: 760px;
  max-width: 760px;
  min-width: 760px;
  font-family: Poppins;
}

.step__content {
  overflow: auto;
  max-width: 90vw;
  padding-bottom: 10px;
}

.financecontent__container {
  max-width: 95vw;
  overflow: scroll;
}

.cellcorrectanswer__container {
  background-color: #04ca95 !important;
  color: white;
  font-family: Poppins;
  padding: 5px 10px;
  border-radius: 3px;

  animation-name: drop;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.financeincorrect__container {
  padding: 5px 10px;
  background-color: lightcoral;
  color: white;
  border-radius: 3px;
  width: 100%;
  display: flex;
  animation-name: drop;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.financeincorrect__container.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes drop {
  0% {
    margin-bottom: 30px;
    opacity: 0;
  }
  100% {
    margin-bottom: 0px;
    opacity: 1;
  }
}

.cell__style {
  /* border: 1px solid #ececec; */
  border: none;
  overflow: auto;
  padding-bottom: 10px;

  /* #e0e0e0 */
}

@media only screen and (max-width: 800px) {
  .financelesson__container {
    width: 100%;
    min-width: 100%;
  }
}
