.programclosed__container {
  /* width: 100%; */
  padding-top: 56px;
  padding-bottom: 56px;
  display: flex;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  text-align: center;
  flex-direction: column;
  border: 2px solid #d94645;
  border-radius: 30px;
}

.programclosed__title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 4px;
}

.programclosed__subtitle {
  font-size: 16px;
  text-align: center;
}
