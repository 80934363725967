.admin-tools-eq {
  border: 1px solid lightgray;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.eqsubmitbutton_container {
  padding-left: 120px;
  padding-top: 40px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.viewassessmentcontainer {
  margin: 0 auto;
}

@media only screen and (max-width: 960px) {
  .eqsubmitbutton_container {
    padding-left: 40px;
    margin: 0 auto;
  }
  .viewassessmentcontainer {
    margin: 0 auto;
  }
}
