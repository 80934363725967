.drag-drop-context-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
  /* max-width: 200px; */
  max-width: 100vw;
  margin-bottom: 30px;
  font-family: Poppins;
}

.correct-word-in-definition > p > img {
  width: 150px;
}

.styled-word-drag-drop > p > img {
  max-width: 150px;
}

.styled-word-drag-drop {
  background-color: red;
}

.correctdefinition__container > p {
}

.correctdefinitiontext__container {
  background-color: #fce3d1;
  border-radius: 10px;
  padding: 8px 10px;
  text-align: center;
  max-width: 200px;
  /* min-height: 100px; */
  border-radius: 20px;
  /* height: 300px; */
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  white-space: normal;
}

.correctdefinitiontext__container > p > img {
  max-width: 200px;
  border-radius: 20px;
}

.correctdefinitiontext__container > p > span {
  background-color: transparent !important;
}



.correctdefinition__container > p > img {
  background-color: blue;
  width: 150px;
  max-width: 200px;
  height: 150px;
  border-radius: 30px;
}

.correctdefinition__container {
  /* background-color: green; */
  min-width: 200px;
}

.correctdefinition__container > p {
  /* max-height: 200px; */
  background-color: #fce3d1;
  border-radius: 10px;
  padding: 8px 10px;
  text-align: center;
  max-width: 200px;
  min-height: 100px;
  /* height: 300px; */

  white-space: normal;
}

.correctdefinition__container {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.words__container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  overflow: auto;
  padding-bottom: 60px;
}

.definition__placeholder {
  /* width: 150px; */
  height: 150px;
  background-color: yellow;
  margin-top: 10px;
  border-radius: 10px;
  background-color: white;
  border: 1px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.correctwordindefinition__container {
  height: 150px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #d6ffc2;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  stroke: 2px #acf488;
}

.draggingover__definition {
  background-color: #4fa0ff;
  opacity: 10%;
}

.styledword__container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  max-width: 200px;
  max-height: 150px;
  text-align: center;

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
  margin-bottom: 30px;

  border-radius: 10px;
  padding: 10px 8px;
  margin-right: 10px;
}

.styledword__container > p > img {
  max-width: 200px;
  /* height: auto; */
  max-height: 100%;
  max-height: 150px;
}

.correctdefinition__container {
  max-width: 300px;
}

.definitions__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: auto;
}

.correct-word-in-definition-container > p > img {
  max-width: 200px;
}

.correctwordindefinition_content > p > img {
  width: auto;
  max-height: 140px;
  height: auto;
  max-width: 200px;
}

.correct-word-in-definition {
  background-color: green;
}

.correct-word-in-definition-container > div > p > img {
  max-width: 150px;
}

.correct-word-in-definition-container > div {
  /* width: 150px; */
}

/* .correct-word-in-definition > p  > span {
  color: white !important;
} */

@media only screen and (max-width: 800px) {
  .definitions__container {
    justify-content: flex-start;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .words__container {
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
  }

  .styledword__container {
    height: 100px;
  }
}

@media only screen and (max-width: 1290px) {
  .drag-drop-context-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
