.phonenumbermodal__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  font-family: Poppins;
}

.phonenumbermodal__info {
  font-weight: 500;
}

.phonenumbermodal__input {
  margin-bottom: 20px;
}

.phonenumbermodal__content {
  background-color: white;
  border-radius: 30px;
  width: 640px;
  padding: 32px;
}

.phonenumbermodal__title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 24px;
}

.phonenumbermodal__button {
  display: flex;
  justify-content: center;
  align-items: center;
}
