.trading-modal {
  position: absolute;
  width: 400px;
  height: auto;
  left: 0;
  opacity: 0;
  right: 0;
  margin-left: 0px;
  margin-top: 5vh;
  margin-right: auto;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 70px;
  flex-direction: column;
  text-align: center;
  box-shadow: 0 0 3px 3px lightgrey;
  z-index: 10;
  animation-name: trading-modal-appear;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

@keyframes trading-modal-appear {
  0% {
  }
  100% {
    opacity: 1;
    margin-left: 20%;
  }
}

.modal-asset-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal-asset-info > h3 {
  margin-bottom: 0px;
  text-align: left;
  font-size: 1.5em;
}

.modal-asset-info > h5 {
  font-weight: 100;
  font-size: 1em;
}

.buy-sell {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.buy-sell > button {
  margin: 5px;
  background: #3e3e3e;
  color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  border: none;
}

.buy-sell > button:first-child {
  background-color: #f93e3e;
  margin-left: 0px;
}
.buy-sell > button:last-child {
  background-color: #72b172;
}

.x-click {
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 10px;
}

.input-amount {
  text-align: center;
}

iframe {
}

.button-amounts-container {
  display: flex;
  justify-content: space-between;
  /* width: 50%; */
}

.amount-options-button {
  border: none;
  background: #3e3e3e;
  color: white;
  font-weight: bold;
  margin: 10px;
  padding: 10px;
}

.amount-options-button:hover {
  background-color: black;
}

.amount-options-button:first-child {
  margin-left: 0px;
}

.expensive {
  background: red;
  color: white;
  padding: 5px;
  margin: 5px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
