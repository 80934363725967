.investing-container {
  height: 100vh;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  z-index: 50;
  background-color: white;
}

.row-1 {
  height: 50vh;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}

.investing-choices {
  width: 60%;
}

.row-2 {
  display: flex;
  flex-direction: row;
  height: 50vh;
  max-width: 100%;
  flex-grow: 0;
}

.spreadsheet {
  width: 60%;
  max-width: 60%;
}

.investing-info {
  width: 40%;
  max-width: 40%;
}

.chart-info {
  width: 40%;
  max-width: 40%;
  display: flex;
  align-items: flex-start;
}

.chart {
  /* margin-top: 40px;
    height: 45vh !important;
    max-width: 45vw;  */
  /* max-width: 45vw; */
  width: 95%;
  max-width: 45vw;
  min-width: 95%;
  padding: 20px;
}

.time-until-end {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time-until-end > h5 {
  font-size: 1.2em;
}

.timer-start > button {
  margin: 5px;
  padding: 5px;
}

/* .timer-enter{
    animation-name: timer-enter;
    animation-duration: 1s;
} 

@keyframes timer-enter {
    0%   { top:100px;}
    100%  { top:0px;}
  }  */
