.add-interest-button {
  border: none;
  background: red;
  color: white;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 0 3px 0px #666464;
  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

.add-interest-button:hover {
  background: darkred;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}