.red-pre-trial {
  margin: 0;
  font-weight: 600;
}
.red-ptd {
  margin: 0;
  color: #ff6250;
  font-weight: 600;
}
.red-pre-trial-container {
  align-self: stretch;
  position: relative;
  line-height: 130%;
  font-weight: 700;
}
.diversion-program-for {
  align-self: stretch;
  position: relative;
  font-size: 24px;
  line-height: 140%;
  font-family: Poppins;
}
.rocketlaunch-icon {
  position: relative;
  width: 20px;
  height: 20px;
  object-fit: cover;
  display: none;
}
.label1 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Poppins;
  color: #ff6250;
  text-align: center;
}
.button {
  border-radius: 20px;
  background-color: #fbe4e1;
  height: 60px;
  display: flex;
  flex-direction: row;
  padding: 16px 50px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}
.label2 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Poppins;
  color: #009379;
  text-align: center;
}
.button1 {
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #009379;
  box-sizing: border-box;
  height: 60px;
  display: none;
  flex-direction: row;
  padding: 16px 50px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}
.ctas1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 18px;
}
.ctas {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.red-pre-trial-diversion-red-pt-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}
.image-14-icon {
  position: absolute;
  top: 351.21px;
  left: 372.31px;
  border-radius: 20px;
  width: 345.28px;
  height: 257.28px;
  object-fit: cover;
}
.image-13-icon {
  position: absolute;
  top: 0px;
  left: 372.31px;
  border-radius: 20px;
  width: 345.28px;
  height: 331.35px;
  object-fit: cover;
}
.image-10-icon {
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 20px;
  width: 351.97px;
  height: 257.28px;
  object-fit: cover;
}
.image-10-icon1 {
  position: absolute;
  top: 275.87px;
  left: 6.69px;
  border-radius: 20px;
  width: 345.28px;
  height: 332.61px;
  object-fit: cover;
}
.image-14-parent {
  position: relative;
  width: 717.59px;
  height: 608.49px;
}
.diversionheronew {
  position: relative;
  border-radius: 0px 0px 50px 50px;
  background-color: #f8f9ff;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 100px 36px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 32px;
  max-width: 1400px;
  text-align: left;
  font-size: 80px;
  color: #2d2d2d;
  font-family: Poppins;
}

@media screen and (max-width: 1200px) {
  .red-pre-trial-container {
    align-self: stretch;
    width: auto;
  }
}
@media screen and (max-width: 960px) {
  .red-pre-trial-container {
    font-size: 44px;
  }

  .diversion-program-for {
    font-size: 24px;
  }
}
@media screen and (max-width: 420px) {
  .red-pre-trial-container {
    font-size: 36px;
  }

  .diversion-program-for {
    font-size: 16px;
  }
}
@media screen and (max-width: 1300px) {
  .red-pre-trial-container {
    text-align: center;
  }

  .diversion-program-for {
    text-align: center;
  }

  .red-pre-trial-diversion-red-pt-parent {
    align-self: stretch;
    width: auto;
    align-items: center;
    justify-content: flex-start;
    flex: unset;
  }

  .diversionheronew {
    flex-direction: column;
    padding-top: 48px;
    /* font-size: 0.8em; */
  }
}
