.button-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  border-radius: 40px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.button4 {
  position: absolute;
  height: 42.88%;
  width: 90.38%;
  top: 27.12%;
  left: 4.79%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Poppins;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button3 {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 315px;
  height: 52px;
}
.openlostmodal {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 14px 0px;
  align-items: flex-start;
  justify-content: flex-start;
}
