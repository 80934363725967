.info-title {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 500;
  opacity: 0.5;
}
.info-amount {
  position: relative;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 20px;
  font-weight: 600;
}
.totaldebt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.month {
  font-weight: 600;
}
.info-amount2 {
  position: relative;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 20px;
}
.monthslefttopay {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
}
.creditgameinfoheader {
  position: relative;
  border-radius: 12px;
  background-color: rgba(251, 124, 35, 0.1);
  border: 1px solid rgba(251, 124, 35, 0.2);
  box-sizing: border-box;
  width: 676px;
  display: flex;
  flex-direction: row;
  padding: 16px 32px;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  font-size: 14px;
  color: #161616;
  font-family: Poppins;
  width: 100%;
}

@media screen and (max-width: 420px) {
  .creditgameinfoheader {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
  }
}
