.titlesaved {
  align-self: stretch;
  position: relative;
  line-height: 22px;
  font-weight: 600;
  font-size: 18px; /* Adjusted for readability on mobile */
  text-align: center;
}
.savedbudget-title {
  width: 100%; /* Use full width for better responsiveness */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Adjusted for smaller screens */
}
.subtitle-budget {
  position: relative;
  line-height: 22px;
  display: inline-block;
  width: 100%; /* Use full width for better responsiveness */
  font-size: 16px; /* Ensure readability */
  text-align: center; /* Center text on mobile */
}
.budgetsaved-subtitle {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align on mobile */
  justify-content: flex-start;
  font-size: 16px;
  width: 100%; /* Use full width for better responsiveness */
}
.budgetsavedcards-container {
  align-self: stretch;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center items on mobile */
  gap: 8px;
  width: 100%; /* Use full width for better responsiveness */
  padding: 0 16px; /* Add padding for better spacing on mobile */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}
.moneysaveddecisioncontainer {
  position: relative;
  border-radius: 16px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: center; /* Center align on mobile */
  justify-content: flex-start;
  gap: 24px;
  text-align: left;
  font-size: 27px;
  color: #000;
  font-family: Poppins;
}

@media screen and (max-width: 720px) {
  .moneysaveddecisioncontainer {
    padding: 16px; /* Reduce padding for smaller screens */
  }

  .titlesaved {
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .subtitle-budget {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .savedbudget-title,
  .budgetsaved-subtitle {
    gap: 12px; /* Adjust gap for smaller screens */
  }

  .budgetsavedcards-container {
    flex-direction: column; /* Stack cards vertically on mobile */
    gap: 16px; /* Add gap between stacked cards */
  }
}
