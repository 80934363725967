.doughnut-container {
  width: 100%;
  height: 70%;
  max-height: 400px;
  text-align: center;
}

.happy-warning {
  position: relative;
  background-color: red;
  color: white;
  padding: 5px;
  font-weight: bold;
  /* top: 2%; */
  /* right: 15%; */
  /* width: 50%; */
  align-self: center;
  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

.info-happy-level {
  margin: 0px;
  padding: 0px;
  font-size: 1.5em;
  font-weight: bold;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
