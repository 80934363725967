/* @font-face {
  font-family: Montserrat;
} */

.quiz-edit-question {
  /* margin-bottom: 5px; */
  font-size: 1em;
}

.quizedit__answer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.quiz-question-title {
  width: 90px;
  font-size: 1em;
  font-family: Montserrat;
}

.quiz-option {
  width: 90px;
  font-family: Montserrat;
  font-size: 1em;
}

.correct-answer-title {
  font-size: 1em;
  font-family: Montserrat;
  margin-right: 5px;
}

.textarea__quizinput {
  width: 100%;
}

.quizselected__option {
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 0.5px solid red;
  border-width: 6px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  color: black;
  margin-bottom: 0px;
  color: red;
}

.quiznotselected__option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 0.5px solid red;
  margin-bottom: 0px;
}

.quizselected__option:checked {
  background-color: #ffa500;
}

.quizselected__option > input:checked {
  background-color: red;
}

.quiznotselected__option {
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 0.5px solid red;
  margin-bottom: 0px;
}
