.congratulations-you-win {
  flex: 1;
  position: relative;
  line-height: 22px;
  font-weight: 600;
}
.youwin-title {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.image-105-icon {
  position: relative;
  width: 300px;
  height: 299px;
  object-fit: cover;
}
.youwin-image {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.youve-successfully-won {
  align-self: stretch;
  position: relative;
  line-height: 22px;
  color: #161616;
}
.buttonsecondaryinitial-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  border-radius: 40px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.button4 {
  position: absolute;
  width: 90.4%;
  top: 25.77%;
  left: 4.8%;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonsecondaryinitial1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
}
.button3 {
  position: relative;
  background-color: #fff;
  width: 200px;
  height: 52px;
}
.button6 {
  position: absolute;
  height: 42.88%;
  width: 90.4%;
  top: 27.12%;
  left: 4.8%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Poppins;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button5 {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 200px;
  height: 52px;
}
.youwin-options {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  color: #d94645;
}
.budgetingwinmodal {
  position: relative;
  border-radius: 16px;
  background-color: #fff;
  width: 512px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-family: Poppins;
}

@media screen and (max-width: 720px) {
  .budgetingwinmodal {
    width: 100%;
  }
}
