.notification__container {
  width: 338px;
  height: 300px;

  margin: 0 auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 20px 20px 30px #00000008;
  border-radius: 10px;
  display: flex;

  align-items: center;
  flex-direction: column;
  animation-name: openNotification;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.notificationcontent__container {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.viewcertificates__button {
  background: #d94645;
  border-radius: 40px;
  color: white;
  /* margin: 30px; */
  width: 150px;
  /* height: 50px; */
  padding: 5px;
  text-align: center;
  margin-top: 20px;
}

.viewcertificates__button:hover {
  background: #d94645;
  color: white;
}

@keyframes openNotification {
  0% {
    margin-bottom: -30px;
    opacity: 0;
  }
  100% {
    margin-bottom: 0px;
    opacity: 1;
  }
}

.modal__icon {
  padding-bottom: 24px;
}

.modal__message {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 16px;
  color: #222224;
  max-width: 200px;
  text-align: center;
}

.modal__text {
  padding-top: 16px;
  font-size: 10px;
  opacity: 0.6;
  max-width: 200px;
  text-align: center;
}

.closebutton__modal {
  position: relative;
  width: 100%;
  text-align: right;
  padding-right: 18px;
  padding-top: 15px;
}

.closebutton__modal:hover {
  cursor: pointer;
}
