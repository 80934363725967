.loading {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:#f4f7fc;
    padding-right: 100px;
  }

  .load-wrapp { 
    padding: 20px 20px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 100px;
  }

  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #6AFC91;
    margin: 2px;
  }
  /* =Animate the stuff
  ------------------------ */
  .load-1 .line:nth-last-child(1) {animation: loadingA 1.5s 1s infinite;}
  .load-1 .line:nth-last-child(2) {animation: loadingA 1.5s .5s infinite;}
  .load-1 .line:nth-last-child(3) {animation: loadingA 1.5s 0s infinite;}
  .l-1 {animation-delay: .48s;}
  
  @keyframes loadingA {
    0 {height: 15px;}
    50% {height: 35px;}
    100% {height: 15px;}
  }