.event__manager__container {
  max-width: 1100px;
  padding: 20px;
  padding-top: 60px;
  margin: auto;
  align-items: center;
  font-family: Poppins;
}

.event__manager__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}

.event__details__summary {
  margin: 2rem 0px;
  padding: 1.5rem;
  border: 1px solid #d94645;
  border-radius: 12px;
  width: 100%;
}

.event__date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
}

.event__name {
  font-size: 18px;
}

.event__name__title {
  font-weight: bold;
}

.event__manager__edit__icon {
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.event__day {
  font-weight: bold;
  margin-right: 20px;
  color: black;
  font-size: 18px;
}

.event__hour {
  color: black;
}

.event__summary {
  color: gray;
}

.event__labels {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
}

.blue__ball {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #0089ff;
  display: inline-block;
  margin-left: 2px;
}

.blue__ball__legend {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #0089ff;
  display: inline-block;
  margin-left: 8px;
}

.yellow__ball {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #e2f10b;
  display: inline-block;
  margin-left: 2px;
}

.yellow__ball__legend {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #e2f10b;
  display: inline-block;
  margin-left: 8px;
}

.red__ball {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
  margin-left: 2px;
}

.red__ball__legend {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
  margin-left: 8px;
}

.orange__ball {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #fb7c23;
  display: inline-block;
  margin-left: 2px;
}

.orange__ball__legend {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #fb7c23;
  display: inline-block;
  margin-left: 8px;
}

.attendance__page__attendance__mode {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}

.event__react__table {
  width: 100%;
}

.react-table-headers {
  width: 15%;
}
.react-table-headers:first-child {
  width: 35%;
}

.arrows_container_span {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
  margin-left: 5px;
}

.arrows_container_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event__manager__container th {
  font-weight: 500 !important;
  font-size: 12px;
  color: #bb4141;
}
.event__manager__container tr {
  font-weight: bold;
}

.event__manager__container tr:nth-child(odd) {
  background-color: #fff8f8;
}

.evaluation_icons_glosary {
  height: 18px;
  margin-bottom: 2px;
  margin-left: 8px;
}

.mail_icon {
  height: 16px;
  margin-bottom: 2px;
  margin-left: 8px;
}

.action-button {
  padding: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: white;
  border: 1px solid #d94645;
  color: #d94645;
  border: none;
  border-radius: 5px;
}

.reminderModal__displayLink-button {
  padding: 5px;
  margin-left: 15px;
  margin-bottom: 5px;
  background-color: #d94645;
  color: white;
  border: none;
  font-family: Poppins;
  font-weight: 500;
}

.confirmedEvent__container {
  /* modal */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  font-family: Poppins;
}
.confirmedEvent__content {
  width: 600px;
  z-index: 10;
  background-color: white;
  max-height: 800px;
  padding: 1.5rem;
  border-radius: 16px;
}

.confirmedEvent__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.confirmedEvent__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirmedEvent__message {
  margin: 1.5rem 0px;
  text-align: center;
}

.confirmedEvent__message__date {
  font-weight: bold;
}

.eventReminder__title {
  font-size: 18px;
  font-weight: bold;
}

.eventReminder__title__userName {
  color: #d94645;
}

.eventReminder__closeIcon {
  cursor: pointer;
}

.eventReminder__actionButtonContainer {
  text-align: center;
}

.eventReminder__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.eventReminder__container {
  width: 600px;
  z-index: 10;
  background-color: white;
  padding: 1.5rem;
  border-radius: 16px;
  border: 0.5px solid black;
  font-family: Poppins;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 20%;
}

.eventReminder__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.eventReminder__textArea {
  width: 100%;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 10px;
  border-color: #00000040;
}

.eventReminder__input {
  width: 100%;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #00000040;
}

.checkbox__red__checked {
  height: 24px;
  width: 24px;
  background-color: #d94645;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.checkbox__red__unchecked {
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid #d94645;
  border-radius: 5px;
  cursor: pointer;
}

.reminderModal__checkbox__container {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  align-items: center;
}

.reminderModal__actions__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.delete-confirm-input {
  width: 100%;
  padding: 8px 12px;
  margin: 12px 0;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.delete-confirm-input:focus {
  border-color: #40a9ff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.delete-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.delete-button {
  background-color: #ff4d4f;
  color: white;
}

.delete-button:disabled {
  background-color: #ffcccb;
  cursor: not-allowed;
}
