.debt-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.game {
  height: 50vh;
  width: 50vw;
  max-width: 50vw;
  padding: 15px;
}

.debt-chart {
  width: 40vw;
  max-width: 40vw;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.debt-chart > canvas {
  max-width: 50vw;
}

.sheet {
  height: 50vh;
}

.pre-alpha {
  font-weight: bold;
  color: red;
}

.info {
  display: flex;
}

.game-lost-modal {
  position: fixed;
  background-color: white;
  /* left: 40%; */
  height: 100vh;
  width: 100vw;
  /* top: 10%; */
  top: 0px;
  left: 0px;
  /* box-shadow: 0px 0px 6px 3px rgb(75 75 75 / 20%); */
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
