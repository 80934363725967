.missingFields-notifications-container {
  max-height: 50vh;
  overflow: auto;
}

.reload-button {
  margin-top: 10px;
  color: black;
  background-color: red;
  font-family: Montserrat;
  padding: 20px 10px;
  background-color: white;
}
