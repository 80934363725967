.chatmessages__container {
  position: fixed;
  bottom: 20px;
  right: 120px;
  width: 316px;
  height: 436px;
  background-color: white;
  /* padding: 16px; */
  border: 1px solid #d3d3d3;
  animation-name: appear;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  font-family: Poppins;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
}

.chatmessage__lastmessage {
  color: rgb(169, 166, 166);
}

.chatsearch__container {
  margin-right: 16px;
  margin-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid #ffebeb;
}

.chatsearch__container > input {
  border: none;
  margin-left: 12px;
}

.chatmessages__tile {
  margin-left: 16px;
  margin-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: bold;
  font-size: 18px;
  border-bottom: 1px solid #ffebeb;
}

.chatallmessages__container {
  flex: 1;
}

.chatmessagepreview__container {
  height: 70px;
  padding-left: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 16px;
}

.chatallmessages__container {
  overflow-y: auto;
}

.chatmessagepreview__container:hover {
  background-color: #ffebeb;
  cursor: pointer;
}

.chatmessage__name {
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  max-width: 60%;
}
.chatmessage__date {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.chatmessage__message {
  color: #a5a5a5;
  align-self: flex-end;
}

.chatmessage__message {
  margin-top: 4px;
  align-self: flex-end;
}

.chatmessage__top {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@keyframes appear {
  0% {
    right: 20px;
    opacity: 0;
  }
  100% {
    right: 120px;
    opacity: 1;
  }
}

.chatclosed__container {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.chatclosed__container:hover {
  cursor: pointer;
}

@media only screen and (max-width: 786px) {
  .chatmessages__container {
    bottom: 120px;
    width: 90vw;
    left: 20px;
    right: 0px;
  }
}
