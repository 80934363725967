.firebase__tool {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
  font-weight: bold;
  padding-left: 10px;
  background-color: white;
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 20px;
  /* max-width: 500px; */
  gap: 10px;
  position: fixed;
  bottom: 10px;
  left: 6%;
  max-width: 70vw;
  height: 55px;
}

.firebase__tool:hover {
  height: auto;
}

.admintools {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.leaveclass__button {
  padding: 8px;
  background-color: lightcoral;
  color: white;
  width: 150px;
  border-radius: 10px;
  text-align: center;
  margin: 5px;
  align-self: flex-end;
  margin-bottom: 10px;
}

.leaveclass__button:hover {
  cursor: pointer;
}
