.stats-boxes {
  width: 1052px;
  height: 170px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 60px 99px #00000007;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.stats-box-container {
  height: 70px;
}
.stats-box-container-students {
  width: 100%;
  height: 70px;
}

.stats-box {
  height: 100%;
  width: 30%;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 30px;
  align-items: center;
}

.graduates-text {
  opacity: 0.7;
}

.stats-box > p {
  opacity: 0.7;
}

.per-year-text {
  text-align: right;
  margin-bottom: 0px;
  font-size: 10px;
}

.stats__info {
  font-family: Poppins;
  font-weight: 300;
  font-size: 14px;
  opacity: 0.65;
}

.stat-highlighted {
  font-weight: bold;
  font-family: Montserrat;
  font-weight: 700;
  color: #d94645;
  font-size: 32px;
  width: 100%;
}

.title-data {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.stat__unhighlighted {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 20px;
  color: #222224;
  padding-left: 3px;
}

.stats-landing-box-container {
  /* background-color: lightgrey; */
  max-height: 600px;
  /* width: 80vw; */
  max-width: 90vw;
  margin: 30px;
  min-height: 400px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-vs-normal-rate {
  text-align: center;
}

.stat-student-count {
  text-align: center;
}

@media only screen and (max-width: 787px) {
  .stats-landing-box-container {
    max-height: none;
    height: auto;
    margin-top: 100px;
    margin-bottom: 130px;
  }

  .stats-boxes {
    flex-direction: column;
    max-height: none;
    height: auto;
    box-shadow: none;
    background-color: transparent;
  }

  .stats-box {
    margin:30px;
    padding-top: 0px;
    width: 100%;
    /* height: auto; */
  }
}
