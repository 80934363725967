.slider-wrapper {
  position: relative;
  width: 100%;
  padding: 20px;
  padding-bottom: 55px; /* space for the budgeted amount */
  border-radius: 15px;
}

.budget-line-label {
  position: absolute;
  margin-top: -12px;
  font-size: 12px;
  width: 100px;
  margin-left: 6px;
  font-weight: bold;
}

@keyframes transitionBar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slider-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 30px;
  background-color: #e5e5e5;
  border-radius: 15px;
}

.title-budget-month {
  margin-bottom: 20px;
  font-weight: 600;
}

.spent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  white-space: nowrap;
  z-index: 1; /* to ensure the spent part is above the budgeted line */
  transition: width 0.3s ease-in-out; /* Add this line for width animation */
}

.budgeted-line {
  position: absolute;
  top: -5px;
  bottom: -10px; /* length of overflow below the slider */
  width: 2px;
  background-color: black; /* adjust color as needed */
  z-index: 10;
}

.budgeted-amount {
  position: absolute;
  top: 140%; /* position it right below the slider */
  transform: translateX(-50%);
  padding: 2px 5px;
  font-size: 12px;
}
