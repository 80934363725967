.continuemodule__container {
  width: 236px;
  height: 344px;

  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-right: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: white;
  background: transparent linear-gradient(37deg, #d94645 0%, #ff6565 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 10px;
  padding-top: 60px;
}

.continuemodule__info {
  padding-left: 16px;
  padding-right: 16px;
}

.continuemodule__title {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 16px;
  color: white;
  margin-bottom: 10px;
}
.continuemodule__description {
  font-family: Poppins;
  font-weight: 300;
  font-size: 10px;
  color: white;
  opacity: 0.6;
}
.continuemodule__button {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  font-size: 10px;
  color: #d94645;
  width: 203px;
  height: 31px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1290px) {
  .continuemodule__container {
    margin-right: 15px;
  }
}
