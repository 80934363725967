.creditsim__container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 30px;
}

.debttable__row__animated {
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.debttable__header {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 8px;
  padding-right: 24px;
  background-color: rgba(249, 250, 250, 1);
  border: 1px solid rgba(236, 236, 236, 1);
  font-weight: 500;
  color: rgba(125, 125, 125, 1);
}

.debttable__row__animated {
}

.debttable__cell {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 8px;
  padding-right: 24px;
  background-color: rgba(249, 250, 250, 1);
  border: 1px solid rgba(236, 236, 236, 1);
  font-weight: 500;
  color: rgba(125, 125, 125, 1);
}

.debttable__cell__input {
  border: none;
  width: 100%;
  height: 55px;
  padding-left: 10px;
  border: 2px solid rgba(0, 137, 255, 1);
}

.debttable__cell__input__unselected {
  border: none;
  width: 100%;
  height: 55px;
  padding-left: 10px;
  /* border: 2px solid rgba(0, 137, 255, 1); */
}

.debttable__cell__input__declined {
  border: none;
  width: 100%;
  height: 55px;
  border: 1px solid red;
  background: rgba(255, 235, 235, 1);
  padding-left: 10px;
}

.debttable__cell__input__container {
  background-color: rgba(249, 250, 250, 1);
  border: 1px solid rgba(236, 236, 236, 1);
  font-weight: 500;
  color: rgba(125, 125, 125, 1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
