.upload_documents {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  max-width: 770px;
  margin-left: 20px;
  margin-bottom: 32px;
  position: relative;
}
.upload__title {
  font-size: 18px;
  margin-bottom: 15px;
  color: #333;
  font-weight: bold;
  margin-left: 20px;
}

.file__input {
  background-color: red;
  display: none;
}

.upload_documents h3 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #333;
}

.file_input_wrapper {
  margin-bottom: 10px;
}

.file__preview {
  width: 100%;
  height: auto;
}

.remove_file {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(217, 70, 69, 1);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.upload_button {
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  font-weight: bold;
  border: 2px solid rgba(217, 70, 69, 1);
  background-color: white;
}

.upload-button:hover {
  background-color: #c9302c;
}

.message {
  margin-top: 10px;
  color: green;
}

.hint-text {
  margin-top: 15px;
  font-size: 14px;
  color: rgba(34, 34, 36, 0.66);
}
