#white-space {
  background-color: white;
  border: solid 1.5px black;
  padding: 3px;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.budget-box {
  display: flex;
  width: 100%;
  height: 35vh;
}

.start-instructions-span {
  padding: 10px;
  background-color: #e91e63;
  color: white;
}

.button-start-absolute {
  position: absolute;
  right: 40%;
  bottom: 20%;
  z-index: 10;
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  color: white;
  background-color: #e91e63;
  border: none;
}

.budget-tips {
  background: white;
  padding: 30px;
  /* box-shadow: inset 0px -13px 9px 8px lightgrey; */
  width: 90%;
  padding: 10px 30px 0 10px;
  text-align: left;
  font-family: Ubuntu;
  height: auto;
  overflow: scroll;
  padding-left: 30px;
  padding-top: 30px;
  /* background-color: #e91e63; */
}

.budgeting-instructions {
  /* font-family: Ubuntu; */
}
.instructions__title {
  font-weight: 600;
}

.budgeting-instructions > p {
  /* font-size: 1.2em; */
  font-size: 13px;
  padding: 10px;

  /* background: antiquewhite; */
  font-weight: 500;
}

.fixed-expenses-selected {
  display: flex;
  justify-items: center;
  margin-bottom: 1em;
  max-width: 300px;
  flex-wrap: wrap;
}

.budget-tips-fixed-amount {
  padding: 10px;
  background-color: #3e3e3e;
  color: white;
  margin: 5px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
