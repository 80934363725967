.landing-box-container-platform {
  /* background-color: lightgrey; */
  max-height: 600px;
  width: 80vw;
  max-width: 90vw;
  margin: 30px;
  min-height: 400px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  max-width: 95vw;
}

.landing-box-right-platform-image {
  /* border: 1px solid black; */
  width: 50%;
  /* background-color: blue; */
  padding: 0px;

  display: flex;
  align-items: center;
}

.screenshot-image-description {
  box-shadow: -5px 10px 50px #0000000d;
  width: 47vw;
  /* height: 370px; */
  /* height: auto; */
  /* background-color: blue; */
}

@media only screen and (max-width: 787px) {
  .landing-box-container-platform {
    flex-direction: column;
  }

  .landing-box-right-platform-image {
    width: 100%;
  }

  .screenshot-image-description {
    width: 100%;
  }
}
