.subtitle-job-options {
  background: #3e3e3e;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 13px;
}

.next-back-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  margin-top: 10px;
}
