.reviews {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 130%;
  font-weight: 700;
}
.see-what-others-container {
  align-self: stretch;
  position: relative;
  font-size: 21px;
  line-height: 130%;
  font-family: Mulish;
}

.lesson__name {
  text-align: center;
  width: 100%;
  font-family: Poppins;
  font-size: 16px;
  /* font-weight: bold; */
  font-weight: 500;
}

.headline-subhead2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  font-size: 38px;
  color: #2d2d2d;
}
.client-info-child {
  position: relative;
  border-radius: 100px;
  width: 56px;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.john-doe1 {
  align-self: stretch;
  position: relative;
  line-height: 130%;
  font-weight: 600;
}
.vice-president1 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 160%;
  font-family: Mulish;
  color: #374151;
  display: none;
}
.person-details1 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}
.client-info {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.the-red-pretrial {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 150%;
  font-family: Mulish;
  color: #2d2d2d;
}
.testimonial-info {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
.star-icon {
  position: relative;
  width: 25.99px;
  height: 25.1px;
}
.star-rating {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}
.testimonial-card {
  flex: 1;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 34.854148864746094px 29.626028060913086px 48.34px
    rgba(51, 102, 255, 0.05);
  border: 1px solid #e5f4f2;
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  font-size: 18px;
}
.image-6-icon {
  position: relative;
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.jane-doe-program {
  align-self: stretch;
  position: relative;
  line-height: 130%;
  font-weight: 600;
}
.i-was-initially {
  margin: 0;
}
.testimonial-card1 {
  flex: 1;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 34.854148864746094px 29.626028060913086px 48.34px
    rgba(51, 102, 255, 0.05);
  border: 1px solid #e5f4f2;
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
.image-6-icon1 {
  position: relative;
  border-radius: 100px;
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.client-info-item {
  position: relative;
  border-radius: 100px;
  background-color: #fff;
  width: 56px;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
}
.vice-president4 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 160%;
  font-family: Mulish;
  color: #374151;
}
.write-short-specific {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.text3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color: #2d2d2d;
  font-family: Mulish;
}
.star-icon13 {
  position: relative;
  width: 24px;
  height: 23.08px;
}
.testimonial-card3 {
  align-self: stretch;
  flex: 1;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 34.854148864746094px 29.626028060913086px 48.34px
    rgba(51, 102, 255, 0.05);
  border: 1px solid #e5f4f2;
  display: none;
  flex-direction: column;
  padding: 40px 30px;
  align-items: center;
  justify-content: space-between;
}
.cards-row1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
}
.testimonials {
  align-self: stretch;
  background-color: #f2bfaf;
  display: flex;
  flex-direction: column;
  padding: 120px 55px;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  text-align: center;
  font-size: 21px;
  color: #111827;
  font-family: Poppins;
  border-radius: 20px;
  /* margin-bottom: 30px; */
}

@media screen and (max-width: 960px) {
  .testimonial-card {
    height: auto;
    flex: unset;
    align-self: stretch;
  }

  .testimonial-card1 {
    height: auto;
    flex: unset;
    align-self: stretch;
  }

  .testimonial-card3 {
    flex: unset;
    align-self: stretch;
  }

  .cards-row1 {
    flex-direction: column;
  }

  .testimonials {
    padding: 60px 32px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .reviews {
    font-size: 36px;
  }

  .see-what-others-container {
    font-size: 18px;
  }

  .testimonial-card {
    height: auto;
    align-items: center;
    justify-content: center;
    padding: 32px 24px;
    box-sizing: border-box;
  }

  .jane-doe-program {
    font-size: 18px;
  }

  .testimonial-card1 {
    height: auto;
    padding: 32px 24px;
    box-sizing: border-box;
  }

  .testimonials {
    padding: 60px 24px;
    box-sizing: border-box;
  }
}
