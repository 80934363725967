.preAdjudicationButton__container {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.Toastify__toast-container {
  z-index: 9999 !important;
}

.preAdjudicationButton__container > button {
  padding: 10px 14px;
  width: 200px;
  height: 52px;
  font-family: Poppins;
  font-weight: 400;
  border-radius: 40px;
  background-color: #d94645;
  color: white;
  font-size: 16px;
  border-color: white;
  border: 2px solid white;
}

.preAdjudication__modal {
  /* modal with grey background  */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
}

.preAdjudication__modal__container {
  width: 600px;
  min-height: 400px;
  background-color: white;
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  border-radius: 15px;
  padding: 16px;
}
.preAdjudication__modal__header {
  margin-bottom: 16px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
}

.please-pay-in {
  position: relative;
  line-height: 28px;
}
.button4,
.pay,
.pay-100 {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pay-100 {
  position: relative;
  font-size: var(--desktop-link-medium-size);
  line-height: 24px;
  font-weight: 500;
  font-family: var(--desktop-text-extra-small);
  color: var(--color-white);
  text-align: center;
  width: 100px;
}
.button4,
.pay {
  flex-direction: row;
}
.button4 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-13xl);
  background-color: var(--primary-color);
  border-radius: var(--br-21xl);
  width: 144px;
  height: 44px;
  box-sizing: border-box;
}
.pay {
  height: 60px;
  gap: var(--gap-13xl);
}
.vector-icon,
.vector-icon1 {
  position: absolute;
  height: 83.33%;
  width: 83.33%;
  top: 8.33%;
  right: 8.33%;
  bottom: 8.33%;
  left: 8.33%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon1 {
  height: 41.67%;
  width: 8.33%;
  top: 29.17%;
  right: 45.83%;
  bottom: 29.17%;
  left: 45.83%;
}
.arrow-position-icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 12px;
  flex-shrink: 0;
}
.tooltip-message-will {
  align-self: stretch;
  position: relative;
  line-height: 22px;
}
.body,
.tooltip {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.body {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--dark);
  display: flex;
  padding: var(--padding-xs) var(--padding-base);
  opacity: 0.8;
}
.tooltip {
  position: absolute;
  top: 29px;
  left: -14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  width: 294px;
  display: none;
}
.info-outline {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.info-outline:hover .tooltip {
  display: flex;
}
.apply {
  text-decoration: underline;
  color: var(--primary-color);
}
.cant-afford-it-container {
  position: relative;
  font-size: var(--desktop-link-small-size);
  line-height: 28px;
  color: var(--text-color);
}
.afford {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  font-size: var(--desktop-text-extra-small-size);
  color: var(--light);
}
.content-paywall,
.paymentcardcontainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-paywall {
  flex: 1;
  border-radius: var(--br-5xl);
  background-color: var(--error-light);
  padding: var(--padding-5xl);
  justify-content: flex-start;
  gap: var(--gap-13xl);
  margin-top: 20px;
}
.paymentcardcontainer {
  /* height: 168px; */
  flex-shrink: 0;
  /* padding: 0 var(--padding-13xl); */
  width: 70%;
  box-sizing: border-box;
  justify-content: center;
  text-align: left;
  font-size: var(--desktop-link-medium-size);
  color: var(--text-color);
  font-family: var(--desktop-text-extra-small);
  margin: 30px auto;
}

.modulesLockedBannerContainer {
  background-color: #efefef;
  width: 90%;
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  min-height: 200px;
  margin-left: 16px;
}

@media screen and (max-width: 1200px) {
  .paymentcardcontainer {
    padding-left: var(--padding-5xl);
    padding-right: var(--padding-5xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 428px) {
  .please-pay-in {
    font-size: var(--font-size-mini);
    line-height: 24px;
  }
  .pay-100 {
    font-size: var(--desktop-link-small-size);
  }
  .button4 {
    flex: 1;
    padding: var(--padding-5xs) var(--padding-81xl);
    box-sizing: border-box;
  }
  .pay {
    flex-direction: column;
    gap: var(--gap-5xs);
  }
  .cant-afford-it-container {
    font-size: var(--desktop-text-extra-small-size);
    line-height: 20px;
  }
  .afford,
  .content-paywall {
    align-items: center;
    justify-content: center;
  }
  .content-paywall {
    gap: var(--gap-31xl);
  }
  .content-paywall,
  .paymentcardcontainer {
    align-self: stretch;
    width: auto;
    flex: 1;
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 350px) {
  .please-pay-in {
    text-align: center;
    flex: 1;
    align-self: stretch;
    height: auto;
  }
  .pay-100 {
    font-size: var(--desktop-link-small-size);
    line-height: 20px;
  }
  .button4,
  .pay {
    align-self: stretch;
    flex: 1;
  }
  .button4 {
    height: auto;
    gap: var(--gap-25xl);
    padding-left: var(--padding-101xl);
    padding-right: var(--padding-101xl);
    box-sizing: border-box;
  }
  .pay {
    width: auto;
    flex-direction: column;
    gap: var(--gap-base);
    align-items: center;
    justify-content: center;
  }
  .content-paywall,
  .paymentcardcontainer {
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
    box-sizing: border-box;
  }
  .content-paywall {
    width: 300px;
    gap: var(--gap-5xl);
  }
  .paymentcardcontainer {
    align-self: stretch;
    width: auto;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 720px) {
  .please-pay-in {
    font-size: var(--font-size-mini);
    line-height: 24px;
  }
  .cant-afford-it-container {
    font-size: var(--desktop-text-extra-small-size);
    line-height: 20px;
  }
}
