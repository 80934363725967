.chatGame-container {
  padding: 20px;
  text-align: left;
  font-family: Poppins;
}

.edit-situation-container {
  padding: 15px;
}

.edit-situation-text {
  width: 95%;
}

.edit-danger {
  width: 50px;
}

.action-response-danger-container {
}

.action-response-danger {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
