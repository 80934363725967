.landing-page-container {
  /* padding-top: 80px; */
  font-family: Poppins;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  background-color: #fcfcfd;
  align-items: center;
}

.landing-page-content {
  max-width: 1300px;
  z-index: 2;
  width: 100%;
  font-family: Poppins;
}

.landing-box-container {
  max-height: 600px;
  width: 80vw;
  max-width: 90vw;
  margin: 30px;
  min-height: 400px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  margin-bottom: 150px;
}

.landing-box-left {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: column;
  padding: 50px;
  /* border: 1px solid black; */
  width: 50%;
  padding-bottom: 0px;
  /* padding-left: 0px; */
  max-width: 600px;
}

.landing-box-left-video {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 50px;
  padding-left: 50px;
  /* border: 1px solid black; */
  width: 50%;
  max-width: 600px;
  /* padding-left: 0px; */
}
.landing-box-right {
  display: flex;
  max-width: 600px;
  justify-content: space-between;
  /* align-items:center; */
  flex-direction: column;
  padding: 50px;
  padding-bottom: 0px;
  /* border: 1px solid black; */
  width: 50%;
  /* padding-left: 0
  px; */
}

.landing-box-right > h3 {
  font-weight: bold;
  font-size: 1.5em;
}

/* Action Page */
.title-word {
  font-size: 6.5em;
  font-family: Poppins;
  font-weight: 700;
  margin-right: 10px;
  color: #d94645;
  line-height: 1em;
}

.landing-top-intro-text {
  display: flex;
  font-family: Poppins;
  font-weight: 300;
  margin-bottom: 0px;
}

.text-prev-line {
  width: 30px;
  height: 10px;
  margin-right: 10px;
  border-bottom: 1px solid #d94645;
  font-weight: 100;
  font-kerning: 0.7px;
}

.landing-subtitle-text {
  /* color: lightgrey; */
  font-family: Montserrat;
  opacity: 0.6;
  font-size: 0.9em;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 500px;
}

.action-landing-image-container > img {
  width: 100%;
  height: auto;
  max-height: 300px;
  margin-top: 0px;
  border-radius: 15px;
  margin-bottom: 30px;
}
/* ------------ end of Action Page */

@media only screen and (max-width: 787px) {
  .landing-box-container {
    margin: 0px;
    flex-direction: column;
    max-height: none;
  }
  .landing-box-right {
    width: 100%;
    height: 50%;
  }
  .landing-box-left {
    width: 100%;
    height: 50%;
  }

  .landing-box-left-video {
    height: 50vh;
    width: 100%;
  }
}

@media only screen and (max-width: 1367px) {
  .landing-box-container {
    /* padding-top: 40px; */
  }

  .landing-box-left {
    padding-right: 0;
    /* padding-left: 20px; */
  }

  .landing-box-right {
    padding-right: 0px;
    padding-left: 20px;
  }

  .landing-box-left-video {
    padding-right: 0;
  }
}
