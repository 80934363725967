.debt-title {
  flex: 1;
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.debtmodal-title {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.chartcontainer {
  align-self: stretch;
  position: relative;
  border-radius: 12px;
  background-color: #f9fafa;
  border: 1px solid #ececec;
  box-sizing: border-box;
  height: 400px;
  overflow: hidden;
  flex-shrink: 0;
}
.debtmodalchart {
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  width: 347px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: 16px;
  color: #222224;
  font-family: Poppins;
}
