.curriculum {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 130%;
  font-weight: 700;
}
.nemo-enim-ipsam {
  align-self: stretch;
  position: relative;
  font-size: 21px;
  line-height: 150%;
  font-family: Poppins;
}
.headline-subhead1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.graduation-hat-01-icon {
  position: relative;
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.lsi {
  position: relative;
  line-height: 130%;
  font-weight: 600;
  display: inline-block;
  width: 300px;
  height: 26px;
  flex-shrink: 0;
}
.stepsloco {
  border-radius: 20px 20px 0px 0px;
  background-color: #fff;
  box-shadow: 30px 30px 48px rgba(51, 102, 255, 0.05);
  border-bottom: 4px solid #f2bfaf;
  display: flex;
  flex-direction: column;
  padding: 24px 23px;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.emotional-intelligence {
  flex: 1;
  position: relative;
  line-height: 130%;
  font-weight: 600;
  display: inline-block;
  width: 300px;
}
.bridge-the-gap-wrapper {
  align-self: stretch;
  height: 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.substance-abuse {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 130%;
  font-weight: 600;
}
.substance-abuse-wrapper {
  width: 300px;
  height: 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.cardsloco {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 40px;
  font-size: 20px;
}
.arrowright-icon9 {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label13 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Poppins;
  color: #fff;
  text-align: center;
}
.button5 {
  border-radius: 20px;
  background-color: #ff6250;
  height: 60px;
  display: flex;
  flex-direction: row;
  padding: 16px 50px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}
.navigation-menu {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.features3 {
  align-self: stretch;
  background-color: #f8f9ff;
  display: flex;
  flex-direction: column;
  padding: 120px 55px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  font-size: 38px;
  color: #2d2d2d;
  font-family: Poppins;
}

@media screen and (max-width: 960px) {
  .curriculum {
    font-size: 36px;
  }

  .nemo-enim-ipsam {
    font-size: 18px;
  }

  .features3 {
    padding: 60px 32px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .lsi {
    font-size: 20px;
  }

  .emotional-intelligence {
    font-size: 20px;
  }

  .substance-abuse {
    font-size: 20px;
  }

  .features3 {
    padding: 60px 24px;
    box-sizing: border-box;
  }
}
