.usersettings__container {
  font-family: Poppins;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 24px;
}

.userprofileinfo__container {
  display: flex;
  flex-direction: column;
  /* max-width: 500px; */
  width: 100%;
  gap: 10px;
  align-self: center;
  padding-bottom: 8px;
}

.settingsdefault__container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.usersettings__container {
  font-family: Poppins;
  padding: 24px;
}

.save__button {
  width: 200px;
  border: 2px solid #d94645;
  border-radius: 25px;
  color: white;
  background-color: #d94645;
  font-weight: bold;
  padding: 13px;
  text-align: center;
}

.cancel__button {
  width: 200px;
  border: 2px solid #d94645;
  border-radius: 25px;
  font-weight: bold;
  padding: 13px;
  text-align: center;
}

.savecancel__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding-top: 30px;
}

.profileimage__container {
  display: flex;
  gap: 20px;
}

.profile__image {
  border-radius: 50%;
}

.profilepic__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile__recommended {
  color: rgba(34, 34, 36, 0.8);
}

.profilepic__settings {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  /* flex-direction: column; */
}

.button__outline {
  font-size: 16px;
  border: 3px solid black;
  border-radius: 30px;
  width: 130px;
  padding: 13px 13px;
  text-align: center;
}

.profilepic__title {
  font-size: 18px;
  color: rgba(34, 34, 36, 1);
}

.settings__title {
  font-size: 16px;
  font-weight: 500;
  /* font-weight: bold; */
  /* font-weight: bold; */
}

.userprofile__title {
  font-size: 32px;
  font-weight: bold;
}

.leaveclass {
  position: absolute;
  right: 0px;
  top: 12px;
  right: 15px;
  font-weight: bold;
  color: #d94645;
}

.leaveclass:hover {
  cursor: pointer;
}

.settings__value__edit {
  font-size: 24px;
  font-weight: 500;
  font-size: 16px;
  padding: 8px 24px;
  border-radius: 15px;
  width: 100%;
}

.edit__button {
  padding-top: 12px;
  font-weight: bold;
  padding-bottom: 12px;
}

.edit__button:hover {
  cursor: pointer;
}

.settings__value {
  font-size: 24px;
  font-weight: 500;
  font-size: 16px;
  /* padding: 8px 24px; */
  border-radius: 15px;
  width: 100%;
  opacity: 0.5;
  padding-top: 4px;
}

.settings__classroom {
  display: flex;
  justify-content: space-between;
  position: relative;
}
