.continue {
  position: relative;
  animation-name: continue-anim;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  width: 50%;
}

.success-intro-container {
  font-weight: bold;
  background-color: aliceblue;
  padding: 20px;
  margin-bottom: 50px;
  animation-name: slide-bottom;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes slide-bottom {
  0% {
    margin-bottom: 50px;
  }
  100% {
    margin-bottom: 0px;
  }
}

@keyframes continue-anim {
  0% {
    left: 200px;
    top: 0px;
  }
  100% {
    left: 0px;
    top: 0px;
    width: 100%;
  }
}
