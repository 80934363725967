.editcontent__title {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 700;
  margin-bottom: 10px;
}

.movedeletecontent__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.deletebutton__button {
  background-color: red;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Poppins;
  font-weight: 700;
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
  margin-top: 5px;
  border-radius: 30px;
}

.deletebutton__button:hover {
  cursor: pointer;
}

/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value="courier-new"]::before {
  font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value="extra-small"]::before {
  font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value="small"]::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value="medium"]::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value="large"]::before {
  font-size: 20px !important;
}

.ql-editor h4,
h5,
h6 {
  font-family: Poppins;
  font-weight: 400;
  /* line-height: 28px; */
  color: black;
}

.ql-editor > h4 {
  font-weight: bold;
  font-size: 32px !important;
}
.ql-editor > h5 {
  font-weight: 600;
  font-size: 24px !important;
}
.ql-editor > h6 {
  font-weight: 500;
  font-size: 18px !important;
}
