.enroll-now {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 130%;
  font-weight: 700;
}
.red-ptd-is1 {
  align-self: stretch;
  position: relative;
  font-size: 21px;
  line-height: 150%;
  font-family: Poppins;
}
.headline-subhead3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.steps-row {
  flex: 1;
  height: 285px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
}
.div3 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 130%;
  font-weight: 700;
}
.attend-compliance-hearing {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 130%;
  font-weight: 600;
}
.attend-compliance-hearing-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: 24px;
  color: #2d2d2d;
}
.steps8 {
  flex: 1;
  border-radius: 20px 20px 0px 0px;
  background-color: #fff;
  box-shadow: 30px 30px 48px rgba(51, 102, 255, 0.05);
  border-bottom: 4px solid #f2bfaf;
  box-sizing: border-box;
  height: 229px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  justify-content: flex-start;
}
.case-dismissed {
  margin: 0;
  font-weight: 600;
}
.cards1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
  text-align: left;
  font-size: 67px;
  color: #f2bfaf;
}
.arrowright-icon10 {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label14 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Poppins;
  color: #fff;
  text-align: center;
}
.joinstepsbutton {
  border-radius: 20px;
  background-color: #ff6250;
  height: 60px;
  display: flex;
  flex-direction: row;
  padding: 16px 50px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}
.navigation-menu1 {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.features4 {
  align-self: stretch;
  background-color: #f8f9ff;
  display: flex;
  flex-direction: column;
  padding: 120px 55px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  font-size: 38px;
  color: #2d2d2d;
  font-family: Poppins;
}

@media screen and (max-width: 1200px) {
  .div1 {
    flex: unset;
    align-self: stretch;
  }

  .cards1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 960px) {
  .enroll-now {
    font-size: 36px;
  }

  .red-ptd-is1 {
    font-size: 18px;
  }

  .div1 {
    flex: unset;
    align-self: stretch;
  }

  .cards1 {
    flex-direction: column;
  }

  .features4 {
    padding: 60px 32px;
    box-sizing: border-box;
  }

  .steps-row {
    flex-direction: column;
  }
}
@media screen and (max-width: 420px) {
  .div1 {
    flex: unset;
    align-self: stretch;
  }

  .div3 {
    font-size: 40px;
  }

  .attend-compliance-hearing {
    font-size: 20px;
  }

  .cards1 {
    flex-direction: column;
  }

  .features4 {
    padding: 60px 24px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 576px) {
  .div1 {
    height: auto;
    flex-direction: column;
  }

  .steps8 {
    flex: unset;
    align-self: stretch;
  }
}
