.eqprompt-statement {
  align-self: stretch;
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.icon-left {
  cursor: pointer;
  border-radius: 2px;
  background-color: #f5f9fc;
  border: 2px solid #e1e9f0;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.prompt-option {
  flex: 1;
  position: relative;
  line-height: 22px;
}
.prompt-option-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}
.eqprompt-options-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  font-size: 14px;
}
.eqprompt {
  position: relative;
  /* width: 1366px;
  height: 258px; */
  display: flex;
  flex-direction: column;
  padding: 0px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  text-align: left;
  font-size: 16px;
  color: #222224;
  font-family: Poppins;
  margin-bottom: 32px;
  /* padding-left: 120px; */
  max-width: 700px;
}

@media screen and (max-width: 960px) {
  .prompt-option {
    font-size: 14px;
  }

  .eqprompt-options-container {
    flex-direction: column;
    gap: 16px;
  }

  .eqprompt{
    padding-left: 40px;
  }
}
@media screen and (max-width: 420px) {
  .eqprompt {
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 760px) {
  .eqprompt {
    display: flex;
  }
}
