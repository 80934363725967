.modalwrapper__container {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #1c1c1c1f;
  /* opacity: 0.5; */
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
