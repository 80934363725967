.landing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 60px;
  /* box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4); */
  margin-top: 100px;
  animation-name: slideBottom;
  opacity: 0;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  background-color: #f5f5f5;
}

.landing-main-container {
  padding-bottom: 30px;
}

@keyframes slideBottom {
  0% {
    opacity: 0;
  }
  100% {
    margin-top: 130px;
    opacity: 1;
  }
}

.landing-left {
  flex: 1;
  justify-content: flex-start;
  padding: 50px;
}

.landing-left > h5 {
  font-weight: bold;
  font-family: Montserrat;
}

.landing-title {
  font-family: Montserrat;
  font-weight: bold;
  /* margin-bottom: 30px; */
  margin: 0px;
}

.red-initials {
  color: #a42639;
  font-weight: bold;
  margin-right: 3px;
}

.landing-right {
  flex: 1;
}

.mb-0 {
  color: grey;
  font-family: "Montserrat";
}

.title-landing {
  font-weight: bold;
  font-size: 4em;
  font-family: "Montserrat";
  margin-top: 20px;
}

.landing-container > h1 {
  color: #a42639;
  font-family: "Montserrat";
}

.button-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin-top: 30px; */
  /* margin-bottom: 30px; */
  margin-left: 0px;
  padding-left: 0px;
}

.landing-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-container > button {
  background-color: #a42639;
  font-family: "Montserrat";
}

.start-button {
  margin-top: 30px;
}

@media only screen and (max-width: 600px) {
  .image-landing {
    width: 60%;
    height: auto;
  }
  .landing-right {
    display: none;
  }
}
