.snowball-edit-container {
  margin: 10px;
  display: flex;
  flex-direction: row;
  max-width: 600px;
  max-height: 600px;
  border-radius: 15px;
  padding: 10px;
}

.snowball-lesson-edit {
  max-height: 600px;
  overflow: scroll;
  min-width: 500px;
  padding-bottom: 40px;
}

.snowball-edit-values-container {
  display: flex;
  flex-direction: column;
  margin: 5px;
  max-width: 500px;
  border: 1px solid lightgrey;
  padding: 15px;
  margin-bottom: 20px;
}

.snowball-formats-table-data-row > td > input {
  width: 100px;
}

.snowball-edit-values-container > textarea,
select {
  margin: 5px;
  margin-top: 0px;
}

.snowball-edit-values-container > label {
  margin: 0px;
  padding: 0px;
  text-align: left;
  padding-left: 5px;
  font-weight: bold;
}
.snowball-edit-values-container > input {
  background-color: #f5f5f5;
}
