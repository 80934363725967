.lsi__input > ::placeholder {
  color: lightgray;
}

.inputfield__label {
  margin-bottom: 0;
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 5px;
}
