/* .sheets {
  display: flex;
  justify-content: space-between;
  background-color: #f4f7fc;
  padding: 15px;
  height: 90vh;
  margin-top: -180px;
  width: 100vw;
  font-family: "Montserrat", serif;
  z-index: 50;
  margin-left: 90px;
} */
.sheets {
  display: flex;
  justify-content: space-between;
  background-color: #f4f7fc;
  padding: 15px;
  height: 90vh;
  margin-top: -180px;
  width: 90vw;
  font-family: "Montserrat", serif;
  z-index: 50;
  /* margin-left: 90px; */
}

.start-screen {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f7fc;
}

.header {
  background-color: white;
}

.interactive {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 60%;
}

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 38%;
}

.events-container {
  height: 47%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  overflow: auto;
}

.emergency-fund {
  height: 32%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 3%;
}

.google-sheet {
  width: 100%;
  height: 50%;
  position: relative;
  overflow: hidden;
  border-radius: 3%;
}

.savings-chart {
  width: 100%;
  height: 65%;
  background-color: white;
  border-radius: 3%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  color: #525252;
  font-family: Ubuntu;
}

.account-label {
  margin: 0;
}

.chart-container {
  width: 95%;
  height: 75%;
}

.situation {
  width: 300px;
}

.intro {
  margin: 25px;
}

.spreadsheet-title {
  color: #535458;
}

.surprise {
  background-color: red;
  display: flex;
  max-width: 400px;
  border: none;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 3px 3px 0px #4a4a4a;
  padding: 15px;
  /* transform: scale(0.7); */
  cursor: pointer;
  margin: 0;
  /* animation-name: pulse; */
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

.surprise:hover {
  box-shadow: 0 8px 20px lightgrey;
  transform: scale(1.1);
  transition: all 0.2s;
}

.surprise-text {
  color: white;
  width: 70%;
}

.surprise-image {
  width: 30%;
  /* height: 100%; */
}

@media only screen and (max-width: 787px) {
  .sheets {
    height: auto;
    flex-direction: column;
  }

  .interactive {
  }

  .dashboard {
  }

  .google-sheet {
    height: 500px;
    width: 100vw;
    margin: 10px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.7);
  }
}
