.endgame-modal{
    position: absolute;
    left: 30%;
    display: flex;
    justify-content: center;
    margin-top: 10vh;
    width: 400px;
    height: 500px;
    background-color: white;
    z-index: 10;
    box-shadow: 1px 1px 15px lightgrey;
    align-items: center;
    /* animation-name: introModal;
    animation-duration: 1s; */
}

.modal-contents{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    margin: 10px;
}

.results-compare{
    text-align: right;
}

.lost{
    background-color: red;
    color: white;
}

.win-text{
    background-color: #7be413;
    color: white;
}