.chat-game-container {
  font-family: Poppins;
  width: 50%;
  /* text-align: center; */
}

.animate-left {
  animation-name: slide-left;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.game-won {
  text-align: center;
}

.game-lost {
  text-align: center;
}

.restart-button {
  padding: 10px;
  background-color: #a42639;
  color: white;
  border: none;
  border-radius: 30px;
  font-weight: bold;
  box-shadow: inset;
}

@media only screen and (max-width: 1290px) {
  .chat-game-container {
    width: 100%;
    /* text-align: center; */
  }
}

@keyframes slide-left {
  0% {
    margin-left: -40px;
  }
  100% {
    margin-left: 0px;
  }
}
