.features-landing-box-right {
  /* border: 1px solid black; */
  width: 65%;
  /* padding-left: 0
  
  px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.features-landing-box-left {
  /* border: 1px solid black; */
  width: 35%;
  padding: 50px;
  /* padding-left: 0
  px; */
}

@media only screen and (max-width: 787px) {
  .features-landing-box-left {
    width: 100%;
  }
  .features-landing-box-right {
    width: 100%;
    padding: 50px;
    padding-right: 0px;
  }
}
