.certificatecard__container {
  font-family: Poppins;
  width: 293px;
  height: 198px;
  padding: 16px;
  /* background-color: #fff5f5; */
  background-color: #fff5f5;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid rgba(236, 163, 162, 0.5);
}

.certificatecard__containerincomplete {
  font-family: Poppins;
  width: 293px;
  height: 198px;
  padding: 16px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid rgba(236, 163, 162, 0.5);
}

.certificatecard__title {
  display: flex;
  justify-content: space-between;
}

.card__title {
  font-weight: 600;
  font-size: 16px;
}

.card__badge {
}

.certificatecard__download {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.certificatecard__button {
  border: 2px solid #d94645;
  padding: 5px;
  border-radius: 40px;
  padding: 10px 20px;
  font-weight: bold;
  color: #d94645;
}

.certificatecard__button:hover {
  cursor: pointer;
}

.certificatecard__buttonincomplete {
  border: 2px solid #b9b9b9;
  padding: 5px;
  border-radius: 40px;
  padding: 10px 20px;
  font-weight: bold;
  color: #b9b9b9;
}

.certificatecard__buttonincomplete:hover {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .certificatecard__container {
    width: 100%;
    margin-right: 12px;
  }
}
