.learnmore__container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
  width: 100%;
  padding: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  opacity: 1;
  max-width: 866px;
  margin: 0 auto;
  margin-top: 150px;
  margin-bottom: -81px;
}
.learnmore__left {
  width:50%;
}

.learnmore__left > h3 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 10px;
  max-width: 300px;
}

.learnmore__right {
}

.learnmore__title{
  font-family: Montserrat;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  line-height: 34px;
  font-size: 32px;
}

.learnmore__subtitle{
  font-family: Poppins;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  margin-top: 10px;
}

.learnmore__button {
  font-family: Montserrat;
  font-weight: 700;
  width: 204px;
  height: 47px;
  background: #d94645 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  fill: white;
  border: none;
}

.learnmore__button:hover {
  opacity: 0.6;
}

@media only screen and (max-width: 787px) {
  .learnmore__container {
    flex-direction: column;
    margin: 20px;
  }
  .learnmore__button {
    margin-top: 20px;
  }
  .learnmore__left {
    width:80%;
  }
}
