.advisor-toggle {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 10;
}

.advisor {
  width: 400px;
  height: 500px;
  bottom: 15px;
  right: 15px;
  overflow: auto;
  background-color: white;
  padding: 15px;
  position: absolute;
  z-index: 1;
  box-shadow: -2px 2px 6px grey;
  display: flex;
  flex-direction: column;
}

.answers {
  height: 70%;
  font-weight: bold;
}

.answers > p {
  background-color: aliceblue;
  margin: 3px;
  padding: 3px;
}

.questions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: white;
}

.questions > a {
  background: #3e3e3e;
  color: white;
  padding: 6px;
  width: fit-content;
  font-weight: bold;
  margin: 3px;
  font-size: 0.8em;
  padding: 4px;
}

.questions > a:hover {
  cursor: pointer;
  background-color: #2db39e;
  color: black;
}

.answer-slide-in {
  animation-name: slider-answer;
  animation-duration: 0.3s;
}

@keyframes slider-answer {
  0% {
    left: 200px;
  }
  100% {
    top: 0px;
  }
}
