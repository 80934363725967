.intro-modal-budgeting {
  position: absolute;
  justify-content: center;
  margin-top: -100;
  padding: 20px;
  width: 400px;
  box-shadow: 1px 1px 15px lightgrey;
  animation-name: intro-modal-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  /* animation-delay: 1s; */
  opacity: 0;
}

@keyframes intro-modal-animation {
  0% {
    margin-top: -300px;
  }
  100% {
    margin-top: 0px;
    opacity: 1;
  }
}

.coming-up-next {
  padding: 20px;
  background: bisque;
  font-weight: bold;
  margin-top: 15px;
  /* animation-name: slideTop; */
  /* animation-duration: 0.5s; */
  /* animation-fill-mode: forwards; */
  /* opacity: 0; */
  /* animation-delay: 2s; */
}

.intro-modal-background {
  position: fixed;
  background-color: white;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  /* margin-left: 80px; */
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.intro-modal-contents {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 30px;
}

.modal-contents > p {
  font-weight: bold;
}

.warning-message {
  color: red;
  font-weight: bold;
}

.intro-learning-objectives {
  margin-top: 10px;
}

.intro-learning-objectives > ul {
  font-size: 1.2em;
  /* padding-bottom: 40px; */
}

.intro-button-start {
  background-color: #3e3e3e;
  border: none;
  box-shadow: 1px 1px 15px lightgrey;
}

.intro-button-start:hover {
  background-color: black;
}
