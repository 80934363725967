.hint-container {
  background-color: bisque;
  padding: 10px;
  margin: 10px;
  font-weight: bold;
  margin-left: 0px;
  animation-name: slide-bottom;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes slide-bottom {
  0% {
    margin-top: 50px;
  }
  100% {
    margin-top: 10px;
  }
}
