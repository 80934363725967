@keyframes pulse-finance {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pulsating-button-finance {
  animation: pulse-finance 1s infinite;
  margin-left: 10px;
}
