.quiz-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  max-width: 400px;
  font-family: Poppins;
}

.quizoption__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
  font-size: 16px;
}

.quizoption__container > p {
  margin-bottom: 0;
}

.quizoption__container:hover {
  background-color: rgb(241, 238, 238);
  cursor: pointer;
}

.option__icon {
  font-style: 18px;
  padding-right: 10px;
}

.quizoptionicon__container {
  margin-right: 8px;
}

.quiz-question {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  width: 100%;
  font-size: 18px;
  margin-bottom: 16px;
  color: #222224;
}

.quiz-answer-correct {
  background-color: #26a485;
  padding: 10px;
  color: white;
  font-weight: bold;
  animation: shake-correct 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  box-shadow: 2px 2px 4px #888383;
  width: 100%;
}

.quiz-answer-correct:disabled {
  width: 100%;
  border-style: solid;
  border-radius: 30px;
  background-color: #26a485;
  padding: 10px;
  color: white;
  font-weight: bold;
  animation: shake-correct 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.quiz-answer-incorrect:disabled {
  width: 100%;
  border-style: solid;
  border-radius: 30px;
  background-color: #ff6868;
  padding: 10px;
  color: white;
  font-weight: bold;
}

.quiz-answer-incorrect {
  width: 100%;
  background-color: #a42639;
  padding: 10px;
  color: white;
  font-weight: bold;
  box-shadow: 2px 2px 4px #a42639;
}

.quiz-answer-default {
  width: 100%;
  padding: 10px;
  color: #a42639;
  font-weight: bold;
  cursor: pointer;
  /* box-shadow: 2px 2px 4px #888383; */
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1290px;
  background-color: #ff6868;
  color: white;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake-correct {
  10%,
  90% {
    transform: translate3d(0, -1px, 0);
    scale: 1.2;
  }

  20%,
  80% {
    transform: translate3d(0, 2px, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(0, -4px, 0);
  }

  40%,
  60% {
    transform: translate3d(0, 4px, 0);
  }
}

@media only screen and (max-width: 1290px) {
  .quiz-container {
    width: 90%;
  }
}
