.option-1-heading1 {
  position: relative;
  line-height: 24px;
  text-align: center;
}

.lifestyleoption:hover {
  cursor: pointer;
  background-color: rgba(217, 70, 69, 0.2);
  border: 1.5px solid rgba(217, 70, 69, 0.8);
  outline: 2px solid rgba(217, 70, 69, 0.8); /* Outline color on hover */
}

.option-1-currency1 {
  position: relative;
  line-height: 36px;
  font-weight: 600;
}
.option-1-price2 {
  position: relative;
  font-size: 24px;
  font-weight: 600;
}
.option-1-price-value-container1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.option-1-price3 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
.option-1-price-container1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 16px;
}
.image-2-icon1 {
  position: relative;
  object-fit: cover;
}
.x31 {
  position: relative;
  font-weight: 500;
  opacity: 0.5;
}
.happy-points-container {
  width: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 16px;
  color: #141414;
}
.lifestyleoption {
  border-radius: 15px;
  background-color: #fff;
  border: 1.5px solid #eca3a2;
  box-sizing: border-box;
  height: 152px;
  min-width: 178px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-align: left;
  font-size: 14px;
  color: #222224;
  font-family: Poppins;
}

.lifestyleoption-selected {
  background-color: rgba(217, 70, 69, 0.2);
  border: 1.5px solid rgba(217, 70, 69, 0.8);
  border-radius: 15px;
  border: 1.5px solid #eca3a2;
  box-sizing: border-box;
  height: 152px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-width: 178px;
  text-align: left;
  font-size: 14px;
  color: #222224;
  font-family: Poppins;
}

.kickedout {
  height: 152px;
  min-width: 178px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(243, 243, 243, 1);
  padding: 20px;
  max-width: 178px;
  font-weight: bold;
  text-align: center;
  border-radius: 15px;
  border: 1.5px solid rgba(217, 70, 69, 0.8);
}

.lifestyleoption-locked {
  background-color: rgba(243, 243, 243, 1);
  border: 1.5px solid rgba(217, 70, 69, 0.8);
  border-radius: 15px;
  border: 1.5px solid #eca3a2;
  box-sizing: border-box;
  height: 152px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-width: 178px;
  text-align: left;
  font-size: 14px;
  color: #222224;
  font-family: Poppins;
}

@media only screen and (max-width: 436px) {
  .lifestyleoption,
  .lifestyleoption-locked,
  .lifestyleoption-selected {
    width: 100%;
  }
}
