.error-modal{
    animation-name: introModal;
    animation-duration: 0.5s;

}

@keyframes introModal {
    0%   { top:70vh;}
    100%  { top:50vh;}
  }
