.red-icon1 {
  position: relative;
  width: 77.5px;
  height: 30px;
  object-fit: cover;
}
.footerinfo {
  position: relative;
  line-height: 24px;
}

.reded__footertitle {
  position: relative;
  /* width: 77.5px; */
  height: 30px;
  object-fit: cover;
  font-weight: bold;
  font-size: 32px;
}

.trademark__symbol {
  position: absolute;
  right: -15px;
  top: 0px;
}

.linkedin-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.footer-link {
  text-decoration: none;
  position: relative;
  width: 44px;
  height: 44px;
}
.footersocialiconscontainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}
.footercontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
}
.landingfooter {
  position: relative;
  margin-top: 64px;
  border-radius: 24px 24px 0px 0px;
  background-color: #d94645;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 68px 0px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 154px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: Poppins;
}

@media screen and (max-width: 1200px) {
  .landingfooter {
    padding-left: 100px;
    padding-right: 100px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .landingfooter {
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 528px) {
  .footerinfo {
    text-align: center;
  }
}
