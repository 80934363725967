.news-modal {
  position: fixed;
  /* top: 8vh;
  left: 40%; */
  /* margin: 0 auto; */
  /* right: 5vw; */
  background: white;
  box-shadow: 1px 1px 4px 0px #4c4242;
  width: 25%;
  height: auto;
  padding: 20px;
  margin-top: 20px;
  animation-name: newsPop;
  animation-duration: 0.5s;
  z-index: 11;
  background-color: #3e3e3e;
  color: white;
  font-weight: bold;

  /* filter: blur(5px) grayscale(50%); */
}

.news-modal {
  color: white;
  font-weight: bold;
}

.news-image {
  width: 200px;
  margin-top: 10px;
}

@keyframes newsPop {
  0% {
    top: 15vh;
  }
  100% {
    top: 8vh;
  }
}

.close-fa {
  float: right;
  right: 6vw;
  background-color: #3e3e3e;
  color: white;
}
