.congratulations-you-win {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.close-icon {
  position: relative;
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.title {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.youve-successfully-paid {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 22px;
}
.button-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  border-radius: 40px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.button1 {
  position: absolute;
  height: 42.88%;
  width: 90.38%;
  top: 27.12%;
  left: 4.79%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Poppins;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  align-self: stretch;
  position: relative;
  height: 52px;
}
.button3 {
  position: absolute;
  width: 90.38%;
  top: 25.77%;
  left: 4.79%;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonsecondaryinitial {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
}
.button2 {
  align-self: stretch;
  position: relative;
  background-color: #fff;
  height: 52px;
}
.button-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  text-align: center;
  color: #d94645;
}
.creditgamewinmodal {
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  width: 347px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: 16px;
  color: #222224;
  font-family: Poppins;
}
