.label20 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  width: 250px;
  flex-shrink: 0;
}
.carouselbutton1 {
  border-radius: 20px;
  /* border: 2px solid #2d2d2d; */
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 14px 9px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: #FF6250;
  background-color: #FFE0DC;
  font-family: Poppins;
}
.carouselbuttonactive {
  border-radius: 20px;
  background-color: #FF6250;
  border: 2px solid #FF6250;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 14px 9px;
  align-items: center;
  justify-content: center;
}

.carouselbutton1:hover {
  cursor: pointer;
}

@media only screen and (max-width: 763px) {
  .carouselbutton1 {
    font-size: 12px;
    padding: 10px 6px;
  }

  .carouselbuttonactive {
    font-size: 12px;
    padding: 10px 6px;
  }
}
