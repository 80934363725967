.dashboard__container {
  width: 100%;
  /* padding-top: 44px; */
  display: inline-block;
  /* font-family: Poppins; font-weight: 400; */
  background-color: #fcfcfd;
  /* padding: 20px; */
}

.dashboard__name {
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
}

.dashboard__topcontainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  justify-content: space-between;
}

.dashboard__contentcontainer {
  display: flex;
  padding-bottom: 80px;
}

.dashboard__topcontainerleft {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-direction: row;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 0;
  /* Add this line */
}

@media only screen and (max-width: 1290px) {
  .dashboard__container {
    margin-left: 0px;
    /* padding-left: 20px; */
  }

  .dashboard__topcontainer {
    justify-content: flex-start;
    flex-direction: column;
  }

  .dashboard__topcontainerleft {
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    /* or overflow: scroll; */
  }
}

@media only screen and (max-width: 1299px) {
  .dashboard__contentcontainer {
    flex-direction: column;
  }
}

@media only screen and (max-width: 800px) {
  .dashboard__name {
    font-size: 18px;
  }
  .dashboard__container {
    padding-top: 10px;
  }

  .dashboard__contentcontainer {
    flex-direction: column;
  }
}
