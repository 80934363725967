.calendarpermissions__container {
  font-family: Poppins;
  background-color: #f4f4f4;
  padding-left: 32px;
  padding-right: 32px;
  margin-right: 20px;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10px;
}

.calendarpermissions__title {
  font-family: Poppins;
  text-align: left;
  font-weight: bold;
}

.calendarpermissions__subtitle {
  font-family: Poppins;
}
