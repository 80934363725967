@import "~antd/dist/antd.css";
.likert-component {
  display: flex;
  flex-direction: column;
  /* min-width: 400px; */
  max-width: 600px;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  padding-top: 0px;
  padding: 20px;
  /* background-color: lightgreen;
  border-radius: 15px; */
}

.likert-text {
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
}

.likert-placeholder {
  color: lightgrey;
  font-family: Poppins;
  font-weight: 700;
}

.likert-selected {
  font-family: Poppins;
  font-weight: 700;
  color: #a42639;
}

.quest-option-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.quest-option-container > p {
  margin-bottom: 5px;
  margin-left: 5px;
  font-size: 16px;
}

.quest-likert-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid red;
  border-width: 6px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  color: black;
  margin-bottom: 0px;
}

.quest-likert-not-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 0.5px solid red;
  margin-bottom: 0px;
}

.likertoption__text {
  font-size: 16px;
  margin-left: 5px;
  margin-right: 30px;
}

.likert-emergency {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-weight: 600;
}
