.landing-page-diversion {
  position: relative;
  background-color: #f8f9ff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .landing-page-diversion {
    width: 100%;
  }
}
