.carouseltitle {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 130%;
  font-weight: 700;
  font-family: "Poppins";
}
.carouseldescription {
  align-self: stretch;
  position: relative;
  font-size: 21px;
  line-height: 150%;
  font-family: Poppins;
  max-width: 70vw;
  margin: 0 auto;
}
.carouselheader {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.label1 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  width: 250px;
  flex-shrink: 0;
}
.buttonactive {
  border-radius: 20px;
  background-color: #FF6250;
  border: 2px solid #2d2d2d;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 14px 9px;
  align-items: center;
  justify-content: center;
}
.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
  max-width: 1024px;
  font-size: 16px;
  color: #fff;
}
.carouselcontentcontainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 44px;
}
.landingfeaturescarousel {
  position: relative;
  /* background-color: #f2bfaf; */
  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 120px 55px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: 38px;
  color: #2d2d2d;
  font-family: Poppins;
}

@media screen and (max-width: 1200px) {
  .landingfeaturescarousel {
    height: 100%;
  }
}
@media screen and (max-width: 960px) {
  .carouseltitle {
    font-size: 36px;
  }

  .carouseldescription {
    font-size: 18px;
  }

  .landingfeaturescarousel {
    height: 100%;
    padding: 60px 32px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .landingfeaturescarousel {
    height: 100%;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }
}
