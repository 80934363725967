.image-1-icon {
  position: relative;
  width: 56.39px;
  height: 56.89px;
  object-fit: cover;
}
.simple-sign-up-with {
  flex: 1;
  position: relative;
  line-height: 150%;
  font-size: 16px;
}
.card-info3 {
  align-self: stretch;
  flex: 1;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #e5f4f2;
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.two-classrooms-drug {
  flex: 1;
  position: relative;
  line-height: 150%;
  font-size: 16px;
}
.features1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  text-align: left;
  font-size: 20px;
  color: #2d2d2d;
  font-family: Poppins;
}

@media screen and (max-width: 960px) {
  .simple-sign-up-with {
    text-align: center;
    align-self: stretch;
    width: auto;
    flex: unset;
  }

  .card-info3 {
    flex-direction: column;
  }

  .two-classrooms-drug {
    text-align: center;
    flex: unset;
    align-self: stretch;
  }
}
@media screen and (max-width: 420px) {
  .simple-sign-up-with {
    font-size: 16px;
    flex: unset;
    align-self: stretch;
  }

  .card-info3 {
    flex-direction: column;
  }

  .two-classrooms-drug {
    font-size: 16px;
    flex: unset;
    align-self: stretch;
  }
}
