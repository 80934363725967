
.current-section-container {
  font-family: "Montserrat", serif;
  /* padding: 20px; */
  opacity: 1;
  /* margin-left: 80px; */
}

.current-section-container > h3 {
  font-family: "Montserrat";
  font-weight: bold;
}

.section-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 100%;
  margin-top: 30px;
}

.section-title {
  margin-bottom: 15px;
  margin-top: 40px;
  color: #222224;
  font-weight: bold;
  width: 50%;
  max-width: 400px;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.text-container > h4,
h5,
h6 {
  font-family: Poppins;
  font-weight: 400;
  line-height: 28px;
  color: black;
}

.text-container > h4 {
  font-weight: bold;
  font-size: 32px;
}
.text-container > h5 {
  font-weight: 600;
  font-size: 24px;
}
.text-container > h6 {
  font-weight: 500;
  font-size: 18px;
}

.text-container > p {
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 2;
  font-size: 16px;
}

.text-container > ul {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
}
.text-container > ol {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
}

.text-container > p > img {
  width: 90vw;
  max-width: 600px;
}

.text-container > h3 {
  text-align: left;
  /* width: 50%; */
  font-family: "Montserrat", serif;
  font-weight: 500;
  /* color: rgba(87, 87, 87, 0.85); */
  font-size: 22px;
  line-height: 1.5;
}

.quiz-class {
  padding: 10px;
  margin: 10px;
}

.text-container {
  margin: 0px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 40px;
  max-width: 400px;
}

.buttons-container {
  margin-top: 40px;
  animation-name: buttons;
  animation-duration: 1s;
  z-index: 20;
  /* width: 40%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  flex-direction: row;
  justify-content: space-between;
  max-width: 600px;
  padding-bottom: 60px;
}

.back__button {
  width: 200px;
  height: 52px;
  /* background-color: rgba(217, 70, 69, 1); */
  border-radius: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  border: 2px solid #d94645;
  color: #d94645;
}

.back__button:hover {
  cursor: pointer;
}

.carousel-root {
  max-width: 700px;
}
@keyframes buttons {
  0% {
    bottom: -10px;
  }
  25% {
    bottom: -7px;
  }
  50% {
    bottom: -5px;
  }
  75% {
    bottom: -3px;
  }
  100% {
    bottom: 0;
  }
}

@keyframes wrongAnswerAnim {
  0% {
    left: 0;
  }
  1% {
    left: -3px;
  }
  2% {
    left: 5px;
  }
  3% {
    left: -8px;
  }
  4% {
    left: 8px;
  }
  5% {
    left: -5px;
  }
  6% {
    left: 3px;
  }
  7% {
    left: 0;
  }
}

@media only screen and (max-width: 1290px) {
  .section-title {
    width: 90%;
    font-family: Montserrat;
    font-weight: 700;

    /* padding-left: 10px; */
  }

  .text-container {
    align-items: flex-start;
  }
}

.contentedit__button {
  position: fixed;
  top: 80px;
  right: 10px;
}
