/* .carousel-slide-container {
  width: 500px;
  height: 500px;
  box-shadow: lightgrey;
  background-color: white;
  box-shadow: 0 0 2px 0px #676666;
} */
.carousel-slide-container > p > img {
  max-width: 300px;
  height: auto;
}

.slide {
  background-color: white;
}

.carousel .slide {
  background-color: white;
  text-emphasis: center;
  padding: 20px;
  height: auto;
  width: 500px;
  border: 1px solid lightgray;
  border-radius: 15px;
}

.slide .selected {
  height: 300px;
}

.carousel > button::before {
  background-color: blue;
  border: 1px solid black;
  width: 10px;
  /* height: 40px; */
  /* box-shadow: 5px 0px 3px #5a5959; */
}

@media only screen and (max-width: 1290px) {
  .carousel-root {
    width: 100vw;
  }
}
