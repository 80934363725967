.image-2-icon1 {
  position: relative;

  height: auto;
  object-fit: cover;
}
.x31 {
  position: relative;
  font-weight: 500;
  opacity: 0.5;
}
.happy-points-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  font-size: 16px;
}
.div21 {
  position: relative;
  line-height: 42px;
  font-weight: 600;
}
.div22 {
  position: relative;
  font-size: 32px;
  font-weight: 600;
}
.parent {
  width: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.sounds-amazing-lets {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.expensechoice {
  flex: 1;
  border-radius: 15px;
  background-color: #fff;
  border: 1.5px solid #eca3a2;
  box-sizing: border-box;
  height: 152px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-align: left;
  font-size: 10px;
  color: #141414;
  font-family: Poppins;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.expensechoice:hover {
  background-color: rgba(217, 70, 69, 0.2);
  outline: 2px solid #d94645;
  box-sizing: border-box;
  cursor: pointer;
}

.expensechoice.disabled {
  animation: pulse 1s infinite; /* Add pulse animation */
  pointer-events: none; /* Disable interactions */
  opacity: 0.7; /* Optional: make it semi-transparent */
}

@media screen and (max-width: 420px) {
  .expensechoice {
    flex: unset;
    align-self: stretch;
  }
}
