.selection-modal-container {
  position: absolute;
  background-color: white;
  left: 30%;
  top: 25%;
  -webkit-box-shadow: 0px 0px 9px 0px lightgrey;
  box-shadow: 0px 0px 9px 0px lightgrey;
  width: 40%;
  height: auto;
  padding: 20px;
}

.debts-to-pay-container {
  padding: 30px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.debt-info > p {
  margin-bottom: 0px;
}

.warning-debt-months {
  padding: 20px;
  background: crimson;
  color: white;
  font-weight: bold;
  margin: 20px;
}

.debt-select {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 9px 0px lightgrey;
  margin: 20px;
  padding: 10px;
}


