.a-message-from {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 130%;
  font-weight: 700;
}
.learn-more-about1 {
  align-self: stretch;
  position: relative;
  font-size: 21px;
  line-height: 150%;
  font-family: Poppins;
}
.header3 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.video {
  align-self: stretch;
  position: relative;
  border-radius: 20px;
  height: 511px;
}
.video-section {
  align-self: stretch;
  background-color: #f2bfaf;
  height: 881px;
  display: flex;
  flex-direction: column;
  padding: 120px 55px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  text-align: center;
  font-size: 38px;
  color: #2d2d2d;
  font-family: Poppins;
}

@media screen and (max-width: 1200px) {
  .video-section {
    flex: 1;
  }
}
@media screen and (max-width: 960px) {
  .a-message-from {
    font-size: 36px;
  }

  .learn-more-about1 {
    font-size: 18px;
  }

  .video-section {
    flex: 1;
    padding: 60px 32px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .video-section {
    flex: 1;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }
}
