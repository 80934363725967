.welcome-to-the {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.close-icon {
  position: relative;
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.creditwelcomemodal-title {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.credit-info {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 22px;
}
.youve-been-given {
  flex: 1;
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.creditdebt-title {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.debtselectiontitle {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.amount {
  margin: 0;
}
.p {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}
.debtselectionamount {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.credit-monthstopay {
  position: relative;
  line-height: 24px;
}
.credit-montlyinterest {
  position: relative;
  line-height: 24px;
  display: inline-block;
  width: 120px;
  flex-shrink: 0;
}
.debtselectioninfo {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
}
.debtselectioncontent {
  align-self: stretch;
  border-radius: 15px;
  border: 1.5px solid #eca3a2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
}
.button-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  border-radius: 40px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.button1 {
  position: absolute;
  height: 42.88%;
  width: 90.38%;
  top: 27.12%;
  left: 4.79%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Poppins;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  align-self: stretch;
  position: relative;
  height: 52px;
}
.creditwelcomemodal {
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  width: 347px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  max-width: 347px;
  margin: 0 auto;
  max-height: 100%;
  text-align: left;
  font-size: 16px;
  color: #222224;
  font-family: Poppins;
}
