.calendar-container {
  padding-top: 60px;
  padding: 60px;
  font-family: Poppins;
  font-weight: 400;
}

.calendar-admin-container {
  padding: 20px;
  background-color: #e7e7e7;
  margin-bottom: 20px;
  font-family: Montserrat;
}

.calendar-container-main {
  height: 80vh;
}

@media only screen and (max-width: 1290px) {
  .calendar-container {
    padding: 15px;
    margin-left: 0;
  }
}
