.classroomchats {
  display: flex;
  flex-direction: column;
  width: 320px;
  min-width: 300px;
  max-height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  overflow-x: none;
  overflow: scroll;
}

.classroomchat__preview {
  padding: 16px 17px;
}

.classroomchat__preview:hover {
  background-color: #fbebeb;
  cursor: pointer;
}

.classroomchat__preview__selected {
  background-color: #fbebeb;
  padding: 16px 17px;
  font-weight: bold;
}

.classroomchat__preview__selected,
.classroomchat__preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52.8px;
}

.chatroomsyncloading {
  display: flex;
  justify-content: center;
  align-items: center;
}
