.setting-budget-for {
  position: relative;
  line-height: 26px;
  font-weight: 600;
  font-size: 32px;
}
.finished-budgeting-month {
  background-color: rgba(51, 215, 67, 0.15);
  font-weight: bold;
  padding: 10px;
  width: 100%;
}

.title {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 16px;
}
.interface-essentialcredit-car-icon {
  position: relative;
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.adjust-income {
  position: relative;
  line-height: 22px;
  font-weight: 500;
}
.title1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.money1 {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #00ba88;
  width: 221px;
  height: 52px;
  overflow: hidden;
}
.div {
  position: relative;
  line-height: 36px;
  font-weight: 600;
}
.div1 {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.container-budget-progress {
  position: absolute;
  top: 8px;
  left: calc(50% - 38px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.expensetitle-on-budget {
  display: flex;
  width: 100%;
}

.money {
  align-self: stretch;
  position: relative;
  border-radius: 15px;
  background-color: rgba(0, 186, 136, 0.25);
  height: 52px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: 16px;
  color: #fff;
}
.incomebar {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 14px;
  color: #222224;
  font-family: Poppins;
}
.devicesjoystick-icon {
  position: relative;
  width: 22px;
  height: 22px;
  object-fit: cover;
}
.title2 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.titlemoney {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.spacer1 {
  align-self: stretch;
  position: relative;
  width: 0px;
  transform: rotate(180deg);
  transform-origin: 0 0;
}
.value1 {
  position: absolute;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  border-radius: 4px;
  background-color: #00ba88;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 67px;
}
.slider-value1 {
  align-self: stretch;
  position: relative;
  border-radius: 4px;
  background-color: rgba(0, 186, 136, 0.3);
  height: 8px;
}
.categorybudget {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}
.interface-essentialhome-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.bus-icon {
  position: relative;
  width: 22px;
  height: 22px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.buttonsecondaryinitial-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  border-radius: 40px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.button3 {
  position: absolute;
  width: 90.4%;
  top: 25.77%;
  left: 4.8%;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonsecondaryinitial1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
}
.button2 {
  align-self: stretch;
  position: relative;
  background-color: #fff;
  height: 52px;
  font-size: 16px;
  color: #d94645;
}

.adjust-income-amount {
  font-weight: bold;
  min-width: 50px;
}

.settingbudget {
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 40px 120px 0px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  text-align: center;
  font-size: 14px;
  color: #222224;
  font-family: Poppins;
  margin: 0 auto;
  max-width: 900px;
}

@media screen and (max-width: 720px) {
  .settingbudget {
    padding: 10px;
  }
}
