.vs {
  margin: 0;
  color: #ff6250;
  font-weight: bold;
}
.traditional-adjudication {
  font-weight: bold;
  margin: 0;
}
.red-ptd-vs-container {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 130%;
  font-weight: 700;
}
.see-how-we {
  align-self: stretch;
  position: relative;
  font-size: 21px;
  line-height: 130%;
  font-family: Mulish;
}
.red-ptd-vs-traditional-adjudic-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
}
.number {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 130%;
  font-weight: 700;
}
.label7 {
  align-self: center;
  position: relative;
  font-size: 16px;
  line-height: 130%;
  max-width: 200px;
}
.number-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.label8 {
  position: relative;
  font-size: 36px;
  line-height: 130%;
  /* font-weight: 700; */
  color: #f2bfaf;
  font-weight: 600;
}
.metric-item {
  align-self: stretch;
  flex: 1;
  border-radius: 20px 20px 0px 0px;
  background-color: #fff;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
  border-bottom: 4px solid #f2bfaf;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 48px 24px;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.metrics1 {
  align-self: stretch;
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}
.metrics {
  align-self: stretch;
  background-color: #f8f9ff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 100px 55px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  font-size: 38px;
  color: #2d2d2d;
  font-family: Poppins;
}

@media screen and (max-width: 960px) {
  .metric-item {
    flex: unset;
    align-self: stretch;
  }

  .metrics1 {
    flex: 1;
    flex-direction: column;
  }

  .metrics {
    height: auto;
    padding: 60px 32px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .red-ptd-vs-container {
    font-size: 36px;
  }

  .see-how-we {
    font-size: 18px;
  }

  .number {
    font-size: 24px;
  }

  .label7 {
    font-size: 14px;
  }

  .label8 {
    font-size: 14px;
  }

  .metrics {
    padding: 60px 24px;
    box-sizing: border-box;
  }
}
