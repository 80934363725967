.income-container {
  width: 100%;
  /* border-radius: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.income-container > p {
  font-weight: 500;
}

.income-bar {
  background: #e0e0e0;
  width: 100%;
  height: 50px;
  /* border-radius: 30px; */
}

.budget {
  /* border-radius: 30px; */
  /* background: #00ba88; */
  height: 100%;
}
