@import "~antd/dist/antd.css";

.app__container {
  /* padding: 20px; */
  font-family: Poppins;
}

.startloading__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.StripeElement {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.ql-editor {
  white-space: normal !important;
}

.StripeElement--focus {
  outline: none;
  box-shadow: 0 0 0 2px blue;
}

.mainAppLandingContainer {
  display: initial;
  width: 100%;
}

.mainAppAuthContainer {
  display: flex;
  width: 100%;
}

@media only screen and (max-width: 1290px) {
  .mainAppAuthContainer {
    display: inline-block;
    width: 100%;
  }
}

@media only screen and (max-width: 720px) {
  .app__container {
    padding: 8px;
  }
}
