.nav-bar {
  /* max-height: 10%; */
  /* position: fixed;
  width: 100%;
  z-index: 3;
  margin-bottom: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  top: 0px;
  background-color: white; */
}

.navtitle {
  color: #d94645;
  font-weight: bold;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 700;
}

.navbarmain__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.navbar__container {
  display: flex;
  max-width: 1300px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-self: center;
  position: relative;
}

.navigation__signuplink {
  color: #222224 !important;
  font-family: Poppins;
  font-weight: 500;
  padding-right: 40px !important;
  cursor: pointer;
}

.navigation__signin-container {
  cursor: pointer;
  padding: 0 !important;
}

.navigation__signin {
  border: 2px solid #d94645;
  border-radius: 10px;
  width: 100px;
  display: flex;
  justify-content: center;
  padding: 6px 12px;
  align-items: center;
  color: #d94645 !important;
}

.navigation__signin:hover {
  background-color: #d94645;
  color: white !important;
}

.navigation__signin > a:hover {
  color: white !important;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-item {
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .collapse.navbar-collapse {
    position: absolute;
    top: -10px;
    right: 60px;
    width: auto;
    background-color: white;
    margin-top: 0;
    padding: 15px 20px;
    z-index: 999;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .navbar-toggler {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
  }

  .navbar-nav {
    flex-direction: column;
    width: fit-content;
    margin: 0;
    padding: 5px 0;
    align-items: center;
  }

  .nav-item {
    margin: 5px 0;
    width: fit-content;
    text-align: center;
  }

  .navigation__signin {
    margin: 0 auto;
  }
}
