.situations-edit-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.responses {
  padding: 5px;
  margin: 10px;
  margin-left: 0px;
}

.child-variables {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
}

.children-situations-container {
  margin-bottom: 30px;
}

.consequence {
  float: right;
  border: none;
  background-color: #3e3e3e;
  color: white;
  font-style: 0.8em;
}

.child-variables > p > input {
  border: none;
  background-color: #efecec;
  max-width: 80px;
}

.child-situation-text {
  font-size: 1em;
  font-weight: bold;
  opacity: 0.6;
}

.child-situation > textarea {
  border: none;
  margin-left: 0px;
  padding-left: 0px;
}

.child-situation {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid black;
  box-shadow: 0px 3px 7px lightgrey;
}

.situation-prompt {
  padding: 5px;
  margin: 10px;
  margin-left: 0px;
  margin-top: 20px;
  background: white;
  box-shadow: 0px 3px 7px lightgrey;
  border: 1px solid black;
}

.situation-prompt > p {
  font-weight: bold;
  padding: 5px;
  padding-top: 0px;
  font-size: 1.3em;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.responses {
  animation-name: slide-left;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes slide-left {
  0% {
    margin-left: -30px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

.responses > p {
  font-size: 1.3em;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 5px;
}

.textarea-chatgame {
  border: none;
  padding-left: 5px;
  margin-left: 0px;
}

.single-situation {
  min-width: 400px;
  margin-right: 10px;
  max-width: 400px;
  font-family: Montserrat;
}

.game-goal-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  font-family: Montserrat;
  padding-left: 10px;
}

.copy-branch {
  background-color: #38ad65;
  color: white;
  font-weight: bold;
  border: none;
}

.game-goal-text {
  margin-bottom: 0px;
  margin-right: 20px;
  font-size: 1.2em;
  opacity: 0.8;
}

.game-goal-input {
  max-width: 300px;
  background-color: #efecec;
  border: none;
}
