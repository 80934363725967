.snowball-modal {
  width: 600px;
  height: 600px;
  max-height: auto;
  position: fixed;
  /* border-radius: 5%; */
  max-height: 80vh;
  box-shadow: 0 0 1pt 100vw rgb(0 0 0 / 80%);
  z-index: 100;
  background-color: white;
  margin: auto auto;
  padding: 30px 50px;
  /* margin-left: 30vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* max-height: 800px;
}



.modal-top {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* padding: 35px; */
  /* padding-top: 30px; */
}

.due {
  background: #ff5858;
  text-align: center;
  color: white;
  font-weight: bold;
}

.modal-top > h4 {
  font-size: 2em;
  margin-bottom: 0px;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}
.modal-top > p {
  font-size: 13px;
  margin-bottom: 0px;
}

.modal-mid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: flex-start;

  padding-bottom: 0px;
}

.modal-mid > h3 {
  font-size: 2.5em;
  padding-top: 50px;
}

.modal-mid > ul {
  font-size: 1.5em;
}

.challenge-warning > h4 {
  margin-left: -20px;
  margin-right: -20px;
  background-color: #e56f6f;
  padding: 20px;
  color: white;
  font-weight: bold;
}

.modal-bottom {
  width: 100%;
  height: 10%;
  text-align: left;
  padding-left: 30px;
  /* margin-top: -30px; */
}

.modal-options {
  width: 100%;
  height: 80%;
}

.month-label {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.month-modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 50px 30px 0 30px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.insurance-text {
  margin: 40px;
  margin-bottom: 0px;
}

.job-text {
  margin: 40px;
  margin-bottom: 0px;
}

.radio {
  width: 180px;
  /* height: 250px; */
  box-shadow: 3px 4px 20px 2px rgb(223, 220, 220);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
}

.radio-parents {
  width: 180px;
  height: 250px;
  box-shadow: 3px 4px 20px 2px rgb(223, 220, 220);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
}

.radio-parents > label {
  background: #ff5858;
  color: white;
  font-weight: bold;
  font-size: 1.5em;
}

.radio:hover {
  transform: scale(1.1);
  z-index: 2;
  cursor: pointer;
}

.option {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.option-text {
  margin: 0.2em;
}

.job-image {
  width: auto;
  height: 75px;
}

.job-options {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  align-items: center;
  overflow-y: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 20px;

  /* spacing as needed */

  /* let it scroll */
  overflow-y: auto;
}

.budget-alert {
  font-family: Poppins;
  color: red;
  font-weight: bold;
  padding: 20px;
  width: 300px;
  bottom: 0px;
  left: 45%;
  position: fixed;
  border-radius: 10px;
  box-shadow: 0 0 1pt 1500pt rgba(0, 0, 0, 0.8);
  z-index: 1;
  background-color: white;
  margin: 100px 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: -100px;
  animation-name: slide-left-alert;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
  font-weight: 400;
  z-index: 10;
  /* font-family: Ubuntu;
  z-index: 10;
  font-weight: bold;
  font-size: 1.2em; */
}

.select-options-modal {
  position: fixed;
  width: 100vw;
  z-index: 2;
}

@keyframes slide-left-alert {
  0% {
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

.alert-close {
  align-self: flex-end;
  width: 40px;
  height: 40px;
  border: none;
  margin: 0;
  background-color: red;
  color: white;
  font-weight: bold;
}

.add-info-insurance {
  margin: 5px;
  font-size: 12px;
  text-align: center;
}

.insurance-options {
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 80%; */

  /* spacing as needed */
  padding: 20px 20px 20px 20px;
  margin-top: 10px;
  margin-bottom: 30px;
}

label {
  /* padding: 10px; */
  text-align: center;
  font-size: 15px;
}

label:hover {
  cursor: pointer;
  /* background-color:#daeeff;
  border-radius: 5px; */
}

.start-form {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.winning-bar,
.losing-bar {
  width: 100%;
  height: 60px;
  color: white;
  text-align: center;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 10px 0 10px 0; */
  animation-name: slide-bottom;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.winning-bar {
  background-color: #3e3e3e;
  padding-top: 10px;
}

.winning-bar > h5 {
  color: white;
  margin: 0px;
}

.losing-bar > h5 {
  background-color: red;
  color: white;
  font-weight: bold;
  padding: 20px;
  width: 100%;
}

@keyframes slide-bottom {
  0% {
    margin-bottom: -50px;
  }
  100% {
    margin-bottom: 0px;
  }
}

.recommendation {
  padding: 10px;
  color: black;
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 0px;
  background: antiquewhite;
  animation-name: slideTop;
  animation-delay: 1s;
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes slideTop {
  0% {
    margin-top: -30px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    margin-bottom: 0px;
    opacity: 1;
    z-index: 1;
  }
}

.loading {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4f7fc;
  padding-right: 100px;
}

.load-wrapp {
  padding: 20px 20px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 100px;
}
.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #4b9cdb;
  margin: 2px;
}
/* =Animate the stuff
------------------------ */
.load-1 .line:nth-last-child(1) {
  animation: loadingA 1.5s 1s infinite;
}
.load-1 .line:nth-last-child(2) {
  animation: loadingA 1.5s 0.5s infinite;
}
.load-1 .line:nth-last-child(3) {
  animation: loadingA 1.5s 0s infinite;
}
.l-1 {
  animation-delay: 0.48s;
}

@keyframes loadingA {
  0 {
    height: 15px;
  }
  50% {
    height: 35px;
  }
  100% {
    height: 15px;
  }
}

@media only screen and (max-width: 1290px) {
  .modal {
    width: 80%;
    padding: 20px;
  }

  .modal-top {
    /* padding: 15px; */
    margin: 15px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
