.landingfooter__container {
  background-color: #d94645;
  border-radius: 20px;
  width: 95%;
  height: 542px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding: 60px;
  padding-top: 188px;
  /* padding-top: 188px; */
}

.landingfooter__logo {
  font-size: 40px;
  font-family: Poppins;
  font-weight: 800;
  color: white;
  margin-bottom: 20px;
}

.landingfooter__info {
  font-size: 16px;
  padding-bottom: 30px;
  color: white;
}

.landingfooter__sociallinks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%;
  margin: auto;
}

.social__link > img {
  width: 44px;
  height: 44px;
  padding: 5px;
  border-radius: 20px;
}

.landingfooter__menutitle {
  font-family: Poppins;
  font-weight: 700;
  font-size: 24px;
  color: white;
  padding-bottom: 24px;
}

.landingfooter__menutext {
  color: white;
  padding-bottom: 24px;
}

.landingfooter__subscribe {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 15px 17px;
  border-radius: 5px;
  min-width: 400px;
  max-height: 50px;
}

.subscribe__input {
  border: none;
  font-size: 12px;
  margin-left: 16px;
  opacity: 0.8;
  outline: none;
}

.landingfooter__social {
  text-align: center;
}

.subscribe__now {
  width: 124px;
  height: 37px;
  background: #ff6565 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: none;
  color: white;
  outline: none;
}

.subscribe__now:active {
  outline: none;
}

.landingfooter__title {
  font-family: Poppins;
  font-weight: 700;
  font-size: 24px;
  color: white;
  padding-bottom: 20px;
}

.landingfooter__subtitle {
  font-size: 14px;
  color: white;
  padding-bottom: 20px;
}

@media only screen and (max-width: 787px) {
  .landingfooter__container {
    flex-direction: column;
    height: auto;
    align-self: center;
  }

  .landingfooter__social {
    margin-bottom: 40px;
  }

  .landingfooter__navigation {
    margin-bottom: 40px;
  }
}
