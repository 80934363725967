.my-table {
  position: absolute;
  top: 35vh;
  right: 12%;
  padding: 20px;
  z-index: 1;
  background-color: white;
  min-width: 281px;
  /* font-size: 12px; */
  z-index: 150;
}

@media only screen and (max-width: 1355px) {
  .my-table {
    display: none;
  }
}
